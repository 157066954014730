import { OrderTotalCards } from "types/cart";
import { priceFormatter } from "helpers/pricing";
interface Props {
  children: React.ReactNode;
  orderTotals: OrderTotalCards;
}

export const OrderTotalsCard = ({ children, orderTotals }: Props) => {
  const total = orderTotals.SubTotal + orderTotals.Tax;
  return (
    <>
      <div className="ta-flex ta-flex-col ta-mt-3.5 ta-justify-end ta-items-end ta-text-base print-totalCard">
        <table className="ta-w-full sm:ta-w-[50%] xl:ta-w-full">
          <tbody className="ta-flex ta-flex-col">
            <tr className="ta-flex ta-justify-between ta-mx-2.5 print-totalContainer">
              <th className="ta-font-normal ta-pb-1 ta-text-left ta-w-[50%] print-left">
                Subtotal
              </th>
              <td className="ta-pl-8 ta-pb-1 ta-text-right ta-w-[50%] print-right">
                ${priceFormatter(orderTotals.SubTotal)}
              </td>
            </tr>
            {orderTotals.Discounts === 0 ? (
              ""
            ) : (
              <tr className="ta-flex ta-justify-between ta-mx-2.5">
                <th className="ta-font-normal ta-pb-1 ta-text-left ta-w-[50%] print-left">
                  Discounts
                </th>
                <td className="ta-pl-8 ta-pb-1 ta-text-right ta-w-[50%] print-right">
                  -${priceFormatter(orderTotals.Discounts)}
                </td>
              </tr>
            )}
            <tr className="ta-flex ta-justify-between ta-mx-2.5">
              <th className="ta-font-normal ta-pb-1 ta-text-left ta-w-[50%] print-left">
                Taxes
              </th>
              <td className="ta-pl-8 ta-pb-1 ta-text-right ta-w-[50%] print-right">
                ${priceFormatter(orderTotals.Tax)}
              </td>
            </tr>
            <tr className="ta-flex ta-justify-between ta-mx-2.5 ta-mt-6">
              <th className="ta-text-header ta-text-2xl ta-font-bold ta-text-left ta-w-[50%] print-left">
                Total
              </th>
              <td className="ta-text-header ta-text-2xl ta-font-bold ta-text-right ta-w-[50%] print-right">
                ${priceFormatter(total)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {children}
    </>
  );
};
