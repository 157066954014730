import { OrderTotalsCard } from "components/orderTotalsCard/orderTotalsCard";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import {
  mapCartTotalsWithTotalsCards,
  filterCartItemsToTiresOnly,
} from "helpers/mapCartTotalsWithTotalsCards";
import { Cart, CartItem } from "types/cart";

interface Props {
  inActive: boolean;
  onClick: (e: any) => void;
  buttonText: string;
  showButton: boolean;
  withoutBorder?: boolean;
  cart: Cart;
}
export const OrderTotalWithImageCard = ({
  inActive,
  onClick,
  buttonText,
  showButton,
  withoutBorder,
  cart,
}: Props) => {
  const mutatedCart = filterCartItemsToTiresOnly(cart);
  return (
    <div
      className={`ta-border ta-border-[#bbb9b9] ${
        !withoutBorder
          ? "ta-shadow-[0_1px_3px_rgba(0,0,0,.30)]"
          : "ta-border-none"
      } ta-text-center ta-text-base ta-flex ta-flex-col ta-relative print-imgContainer`}
    >
      {mutatedCart &&
        mutatedCart.CartItems &&
        mutatedCart.CartItems.map((item: CartItem) => (
          <div
            id="img-container"
            key={item.Tire.TireId}
            className="ta-flex ta-justify-center ta-text-sm md:ta-border-b md:ta-border-grey ta-w-[95%] ta-self-center"
          >
            <div className="ta-w-[30%] ta-max-w-[120px]">
              <img
                alt=""
                src={item.Tire.ImageUrl}
                className="ta-p-5 ta-pl-0 ta-m-0 lg:ta-mx-auto ta-h-[145px] ta-w-auto"
              />
            </div>
            <div className="ta-block ta-flex-1 lg:ta-w-full ta-text-left ta-pt-5 ta-pb-5 ta-mr-[20px] ta-ml-[20px] md:ta-table-cell ">
              <div className="ta-font-bold ta-text-black">
                {item.Tire.ManufacturerName}
              </div>
              <div className="ta-font-bold ta-text-black">
                {item.Tire.ProductName}
              </div>
              <div>{item.Tire.DisplayTireSize}</div>
              <div>QTY: {item.Quantity}</div>
            </div>
          </div>
        ))}

      <div>
        <OrderTotalsCard orderTotals={mapCartTotalsWithTotalsCards(cart)}>
          {showButton ? (
            <CustomIconButton
              text={<span className="ta-ml-2.5">{buttonText}</span>}
              classes={
                "ta-flex ta-items-center ta-ml-3 ta-w-[94%] ta-mb-[18px] ta-justify-center ta-border ta-border-button ta-bg-white ta-text-button hover:ta-bg-button hover:ta-text-white ta-w-full ta-font-bold ta-text-2xl ta-rounded-[3px] ta-p-4 ta-mt-12 xl:ta-mt-16"
              }
              onClick={onClick}
              inActive={inActive}
            />
          ) : null}
        </OrderTotalsCard>
      </div>
    </div>
  );
};
