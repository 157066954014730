import { Wishlist, WishListSummary } from "types/wishlist";
import { priceFormatter } from "helpers/pricing";
import { useEcommerceContext } from "context/ecommerce";
import { useNavigate } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { back } from "constants/index";

interface Props {
  wishlistSummary: WishListSummary;
}
export const UserWishlistListCard = ({ wishlistSummary }: Props) => {
  const { getWishlistDetails, removeFromWishlist } = useEcommerceContext();
  const navigate = useNavigate();
  const onWishlistDetails = async (e: any) => {
    // Get wishlist id, call get wishlist details api and navigate to wishlist page
    const wishlistId = parseInt(e.target.attributes["data-id"].value);
    await getWishlistDetails(wishlistId);
    // Set router state for wishlist page back button to come back to this page
    navigate(TiresAnytimeRoutes.Wishlist, {
      state: {
        backToText: back,
        backToRoute: TiresAnytimeRoutes.UserWishlist,
      },
    });
  };
  const onWishlistDelete = async (e: any) => {
    // Get wishlist id & delete wishlist from user wishlists
    const wishlistId = parseInt(e.currentTarget.attributes["data-id"].value);
    const dealerId = parseInt(
      e.currentTarget.attributes["data-dealerid"].value
    );
    await removeFromWishlist(dealerId, wishlistId);
  };

  return (
    <div className="ta-text-base ta-shadow-md ta-border">
      <table className="ta-block ta-w-full lg:ta-table">
        <thead className="ta-block ta-w-full lg:ta-table-row-group">
          <tr className="ta-block ta-p-4 ta-w-full ta-bg-header ta-text-white lg:ta-table-row lg:ta-p-0">
            <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto">
              Tires to be installed at
            </th>
            <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto lg:ta-text-center">
              Expire Date
            </th>
            <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto">
              Total
            </th>
            <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto">
              <span className="ta-sr-only">Action</span>
            </th>
          </tr>
        </thead>
        <tbody className="ta-block ta-w-full lg:ta-table-row-group">
          {wishlistSummary.Wishlists &&
            wishlistSummary.Wishlists.map((wishlist: Wishlist) => (
              <tr
                key={wishlist.Id}
                className="ta-border-b ta-block ta-w-full lg:ta-table-row"
              >
                <td className="ta-p-2 ta-block ta-w-full lg:ta-table-cell lg:ta-w-auto">
                  <button
                    type="button"
                    onClick={onWishlistDetails}
                    data-id={wishlist.Id}
                    className="ta-text-secondary hover:ta-underline"
                    aria-label={`View ${wishlist.DealerName} wishlist`}
                  >
                    {wishlist.DealerName}
                  </button>
                </td>
                <td className="ta-p-2 ta-block ta-w-full lg:ta-table-cell lg:ta-w-auto lg:ta-text-center">
                  {wishlist.ExpirationDate}
                </td>
                <td className="ta-p-2 ta-block ta-w-full lg:ta-table-cell lg:ta-w-auto ta-font-bold">
                  ${priceFormatter(wishlist.Total)}
                </td>
                <td className="ta-p-2 ta-block ta-w-full lg:ta-table-cell lg:ta-w-auto">
                  <button
                    type="button"
                    onClick={onWishlistDelete}
                    data-id={wishlist.Id}
                    data-dealerid={wishlist.DealerId}
                    aria-label={`Remove ${wishlist.DealerName}`}
                    className="ta-flex ta-items-center ta-text-secondary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.5"
                      height="12"
                      viewBox="0 0 10.5 12"
                      className="ta-text-button ta-mr-2"
                    >
                      <path
                        id="Icon_awesome-trash"
                        data-name="Icon awesome-trash"
                        d="M10.125.75H7.312L7.092.312A.563.563,0,0,0,6.588,0H3.909a.556.556,0,0,0-.5.312L3.187.75H.375A.375.375,0,0,0,0,1.125v.75a.375.375,0,0,0,.375.375h9.75a.375.375,0,0,0,.375-.375v-.75A.375.375,0,0,0,10.125.75Zm-8.878,10.2A1.125,1.125,0,0,0,2.37,12H8.13a1.125,1.125,0,0,0,1.123-1.055L9.75,3h-9Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span className="ta-sr-only lg:ta-not-sr-only">Remove</span>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
