import { useSearchContext } from "context/search";
import { BackButton } from "components/backButton/backButton";
import { backToFinder } from "constants/index";
import { TiresAnytimeRoutes } from "routes";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "components/input/input";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { ChangeEvent, useEffect } from "react";
import { patterns } from "constants/index";
import { CustomerContact, Form } from "types/customerContact";
import { useState } from "react";
import { useDealerContext } from "context/dealer";
import { useAuth } from "context/auth";
import { post } from "services/apiServices";
import { HttpStatus } from "helpers/httpStatus";
import { formValidator } from "helpers/formValidator";

const defaultState: Form = {
  name: "",
  email: "",
  address: "",
  phone: "",
  contactTime: "",
};
const error: Form = {
  ...defaultState,
};
const labels: Form = {
  name: "Name",
  email: "Email",
  address: "Address",
  phone: "Phone",
  contactTime: "Best time to contact me",
};
export const SearchResultEmptyPage = () => {
  const { searchParamsData } = useSearchContext();
  const { selectedDealerLocation } = useDealerContext();
  const { token } = useAuth();
  const { state } = useLocation();
  const [form, setForm] = useState<Form>(defaultState);
  const [errors, setError] = useState<Form>(error);
  const [inActive, setInActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const { isTireBySize, isByVehicle } = state;

  useEffect(() => {
    if (formValidator<Form>(form, errors, ["contactTime"])) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [form]);

  const onChange = (
    e: ChangeEvent<HTMLInputElement> | InputMaskChangeEvent
  ) => {
    const name = e.target.name as keyof Form;
    const value = e.target.value;
    const pattern = e.target.pattern;
    let isError = false;

    if (!value && value === "") {
      setError((prevState: Form) => ({
        ...prevState,
        [name]: `${labels[name]} is Required`,
      }));
      isError = true;
    } else if (pattern) {
      const regex = new RegExp(pattern);
      if (value && !regex.test(value)) {
        setError((prevState: Form) => ({
          ...prevState,
          [name]: `Invalid ${labels[name]}`,
        }));
        isError = true;
      }
    }
    if (errors[name] !== "" && !isError) {
      setError((prevState: Form) => ({
        ...prevState,
        [name]: "",
      }));
    }
    setForm((prevState: Form) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onClose = () => {
    navigate(TiresAnytimeRoutes.Home);
  };

  const onSave = async () => {
    const payload: CustomerContact = {
      BestTimeToContact: form.contactTime,
      CustomerAddress: form.address,
      CustomerEmail: form.email,
      CustomerName: form.name,
      CustomerPhone: form.phone,
      DealerId:
        selectedDealerLocation && selectedDealerLocation.DealerId
          ? selectedDealerLocation.DealerId
          : 0,
      SearchInformation: {
        LicensePlate: "",
        RearTireSize:
          isTireBySize && searchParamsData?.Width2
            ? `${searchParamsData?.Width2}/${searchParamsData?.AspRatio2}R${searchParamsData?.Rim2}`
            : ``,
        VIN: "",
        TireSize: isTireBySize
          ? `${searchParamsData?.Width}/${searchParamsData?.AspRatio}R${searchParamsData?.Rim}`
          : ``,
        Vehicle: isByVehicle
          ? `${searchParamsData?.Year} ${searchParamsData?.Make} ${searchParamsData?.Model} ${searchParamsData?.Option}`
          : ``,
      },
    };
    const resp = await post(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/SendNoResultsEmail`,
      payload,
      { Authorization: `Bearer ${token?.access_token}` }
    );

    if (
      resp.status === HttpStatus.NO_CONTENT ||
      resp.status === HttpStatus.OK
    ) {
      navigate(TiresAnytimeRoutes.ContactInfoConfirmation);
    }
  };

  return (
    <div className="ta-w-[45rem] ta-mx-auto ta-text-sm ta-max-w-full">
      <BackButton text={backToFinder} navigation={TiresAnytimeRoutes.Home} />
      <p className="ta-mb-4">
        Sorry, the tire selected is currently not available in our local
        warehouse. Not to worry though, in most cases we can get it within a few
        days. Please leave us your contact information so we can quickly help
        you
      </p>
      <div>
        <form>
          <Input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            label="Name"
            autocomplete="given-name"
            errorText={errors.name}
            value={form.name}
            required
            classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
          />
          <Input
            id="address"
            name="address"
            type="text"
            onChange={onChange}
            label="Address"
            autocomplete="address"
            value={form.address}
            errorText={errors.address}
            required
            classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
          />
          <div className="ta-grid ta-grid-cols-wishListInputGrid ta-gap-4 ta-mb-4">
            <label
              htmlFor="phone"
              className="ta-text-sm ta-font-bold ta-text-black"
            >
              Phone Number
              <span className="ta-text-red">*</span>
            </label>
            <InputMask
              id="phone"
              onChange={onChange}
              autoComplete="tel"
              mask={"(999)999-9999"}
              value={form.phone}
              name={"phone"}
              required={true}
              className="ta-border ta-border-[#8f8f8f] ta-rounded-[3px] ta-h-[33px] ta-px-3"
            />
            {errors.phone !== "" ? (
              <div
                className="ta-col-start-2 ta-text-left ta-text-sm"
                style={{ color: "darkred" }}
                aria-live="polite"
              >
                {errors.phone}
              </div>
            ) : (
              ""
            )}
          </div>
          {form.phone && form.phone.replace(/\D+/g, "").length === 10 ? (
            <Input
              id="contactTime"
              name="contactTime"
              type="text"
              onChange={onChange}
              label="Best time to contact me"
              value={form.contactTime}
              errorText=""
              classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
            />
          ) : (
            ""
          )}
          <Input
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            onChange={onChange}
            label={"Email Address"}
            value={form.email}
            errorText={errors.email}
            pattern={patterns.email}
            required
            classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
          />
        </form>
      </div>
      <div className="ta-flex ta-flex-col ta-items-center ta-justify-center ta-gap-2 md:ta-flex-row">
        <button
          type="button"
          disabled={inActive}
          onClick={onSave}
          className="ta-w-full ta-border-2 ta-border-button ta-rounded-[3px] ta-text-lg ta-text-button ta-font-bold ta-mt-5 ta-p-3.5 cursor-pointer enabled:ta-text-white enabled:ta-bg-button"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onClose}
          className="ta-w-full ta-border-2 ta-border-button ta-rounded-[3px] ta-text-lg ta-text-button ta-font-bold ta-mt-5 ta-p-3.5 cursor-pointer enabled:ta-text-white enabled:ta-bg-button"
        >
          Close
        </button>
      </div>
    </div>
  );
};
