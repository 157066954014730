import ReactFocusLock from "react-focus-lock";

interface Props {
  title: string;
  content: React.ReactNode;
  open: boolean;
  onClose: () => void;
  classes?: string;
}
// Dialog component with state managed by parent component, for open & close dialog
export const Dialog = ({ title, content, open, onClose, classes }: Props) => {
  // Only renders in dom if open prop is true to open dialog
  return (
    <>
      {open && (
        <ReactFocusLock>
          <div
            className="ta-fixed ta-top-0 ta-left-0 ta-z-[9999] ta-flex ta-flex-col ta-items-center ta-w-full ta-h-full ta-p-4 ta-bg-quaternary/90 ta-text-left ta-overflow-y-auto"
            tabIndex={-1}
            role="dialog"
            title={title}
          >
            <div
              className={`ta-relative ta-py-12 ta-px-6 ta-m-auto ta-max-w-full ta-w-full ta-rounded-[5px] ta-shadow-md ta-bg-white md:ta-p-12 lg:ta-w-[1400px] ${classes}`}
              role="document"
            >
              <div className="modal-content">
                {title && (
                  <h5 className="ta-text-2xl ta-font-bold ta-mb-4">{title}</h5>
                )}
                <div className="modal-body">{content}</div>
                <div className="modal-footer" />
                <button
                  type="button"
                  className="ta-absolute ta-top-3 ta-right-3 ta-text-sm ta-text-black"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <span className="ta-mr-1">close</span>
                  <span
                    aria-hidden="true"
                    className="ta-inline-block ta-align-middle ta-w-[14px] ta-h-[14px] ta-rounded-[3px] ta-py-[7px] ta-text-base ta-bg-black ta-font-bold ta-text-white ta-leading-[0]"
                  >
                    &times;
                  </span>
                </button>
              </div>
            </div>
          </div>
        </ReactFocusLock>
      )}
    </>
  );
};
