import debounce from "lodash.debounce";
import { useCallback } from "react";

export const useDebounce = (
  callBack: (...args: any) => any,
  value: any,
  delay: number
) => {
  // Wrap lodash debounce in react hook
  return useCallback(debounce(callBack, delay), value);
};
