import { ChangeEvent, useState } from "react";
import { priceFormatter } from "helpers/pricing";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { StaggeredFitmentInfo } from "./staggeredFitmentInfo";
import { SearchResultsGridItemInfo } from "./searchResultsGridItemInfo";
import { SearchResultsPromotionCard } from "components/searchResultsPromotionCard/searchResultsPromotionCard";
import { SearchOptions } from "types/search";
import { Tire } from "types/tire";
import { TiresAnytimeRoutes } from "routes";
import { useNavigate } from "react-router-dom";
import { uuid } from "helpers/uuid";
import { useSearchContext } from "context/search";
import { useDealerContext } from "context/dealer";

interface Props {
  tire: Tire;
  isStrategyTire: boolean;
  addToCart: (tire: Tire, searchOptions: SearchOptions) => Promise<void>;
  classes?: string;
}

const defaultTiresQuantity = 4;

export const SearchResultsGridCard = ({
  tire,
  isStrategyTire,
  addToCart,
  classes,
}: Props) => {
  // State to manage quantity for add to cart option in search results
  const [tireQuantity, setTireQuantity] =
    useState<number>(defaultTiresQuantity);
  const { comparedTires, compareTires, removeComparedTires, searchedTires } =
    useSearchContext();
  const navigate = useNavigate();
  // State to manage if check box is checked or not
  const [checkbox, setCheckbox] = useState(false);

  const handleChangeCheckbox = (e: any) => {
    // If strategy tire is checked, check same tire from additional results
    const doc: any = document.getElementsByName(tire.TireId.toString());
    const length = doc.length;
    // If tire compare is checked & only 3 tires or less are checked
    // set checked property to true & add tire to comparedTires search state context
    if (e.target.checked && !(comparedTires.length >= 3)) {
      compareTires(parseInt(e.target.attributes["data-id"].value));
      // if length is greater than one, we know the checked tire is a strategy tire Best/Better/Good
      if (length > 1) doc[1].checked = true;
      setCheckbox(!checkbox);
    }
    // If tire compare is checked & already 3 tires are checked for compare
    // Set check to false to reset checkbox & alert user only max of 3 are allowed
    else if (e.target.checked && comparedTires.length >= 3) {
      e.target.checked = false;
      alert("You can only select a maximum of three items to compare.");
    }
    // Compare is unchecked, remove tire from comparedTire search state context
    // and set checked property to false
    else {
      removeComparedTires(parseInt(e.target.attributes["data-id"].value));
      // if length is greather than one, we know the user unchecked a strategy tire
      if (length > 1) {
        for (let i = 0; i < doc.length; i++) {
          doc[i].checked = false;
        }
      }
      setCheckbox(!checkbox);
    }
  };

  const addToCartClick = async () => {
    await addToCart(
      { ...tire, QuantityToAdd: tireQuantity },
      {} as SearchOptions
    );
    navigate(TiresAnytimeRoutes.Cart);
  };

  const navigateToProductDetailsPage = () => {
    navigate(TiresAnytimeRoutes.ProductDetails, { state: tire });
  };

  const onQuantityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // When tire quantity is changes update state with value
    setTireQuantity(parseInt(e.target.value));
  };

  const fitmentSearchPerformed = searchedTires?.IsSplitFitment;
  // if staggered fitment show even numbers
  const quantityList = fitmentSearchPerformed
    ? [4, 8]
    : [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div
      className={`ta-border ta-border-[#bbb9b9] ta-shadow-[0_1px_3px_rgba(0,0,0,.30)] ta-text-center ta-text-base ta-flex ta-flex-col ta-relative ${classes}`}
    >
      <div className="ta-flex ta-items-start ta-align-self-start ta-justify-self-start">
        {isStrategyTire ? (
          <span className="ta-bg-primary ta-text-white ta-rounded-br-[3px] ta-w-[134px] ta-max-w-full ta-text-sm ta-font-bold ta-uppercase ta-p-0.5">
            {tire.StrategyLevelDisplay}
          </span>
        ) : null}
        <div className="ta-ml-auto ta-pr-3">
          <label className="ta-text-xs">
            Compare
            <input
              type="checkbox"
              name={tire.TireId.toString()}
              defaultChecked={checkbox}
              onChange={handleChangeCheckbox}
              className="ta-ml-1"
              data-id={tire.TireId}
            />
          </label>
        </div>
      </div>

      <div className="ta-product-item-body ta-p-3 ta-pb-2.5 ta-flex ta-flex-col ta-text-sm">
        <img
          src={tire.BrandImageUrl}
          alt={tire.ManufacturerName}
          className="ta-w-auto ta-h-[30px] ta-mx-auto ta-mb-2"
        />

        <h3 className="ta-mb-2 ta-font-bold ta-text-base ta-capitalize">
          <button
            type="button"
            className="hover:ta-text-secondary hover:ta-underline"
            onClick={navigateToProductDetailsPage}
            aria-label={`View ${tire.ProductName} details`}
          >
            {tire.ProductName}
          </button>
        </h3>

        <div>
          <button
            type="button"
            onClick={navigateToProductDetailsPage}
            aria-label={`View ${tire.ProductName} details`}
          >
            <img
              src={tire.ImageUrl}
              alt={tire.ProductName}
              className="ta-mx-auto ta-h-[145px] ta-w-auto"
            />
          </button>
        </div>

        <div className="ta-mb-2">
          {tire.UserRatingImageUrl}
          <div
            id={`pr-cat-${tire.PageId}-${tire.ManufacturerPartNumber}-${
              isStrategyTire ? "ft" : ""
            }`}
          ></div>
        </div>

        {fitmentSearchPerformed && <StaggeredFitmentInfo tire={tire} />}

        {!fitmentSearchPerformed && <SearchResultsGridItemInfo tire={tire} />}

        <div>
          Availability:{" "}
          <span className="ta-font-bold">
            {tire.InventoryPrimaryWarehouse + tire.InventorySecondaryWarehouse >
            0
              ? "In Stock"
              : "Not In Stock"}
          </span>
        </div>

        <div className="ta-text-2xl ta-font-bold ta-my-2">
          {!fitmentSearchPerformed && `$${priceFormatter(tire._finalPrice)}`}
          {fitmentSearchPerformed && (
            <>
              F: ${priceFormatter(tire._finalPrice)}
              <br />
              R: ${priceFormatter(tire?._rearFinalPrice as number)}
            </>
          )}
        </div>

        <div className="ta-mb-3">
          {!fitmentSearchPerformed && (
            <>
              {tire.InstallationText
                ? tire.InstallationText
                : `Installed: $
            ${priceFormatter(tire.InstallPrice + tire._finalPrice)}`}
            </>
          )}
          {fitmentSearchPerformed && (
            <>
              Front Installed : $
              {priceFormatter(tire.InstallPrice + tire._finalPrice)}
              <br />
              {tire._rearFinalPrice && (
                <>
                  Rear Installed : $
                  {priceFormatter(tire.InstallPrice + tire._rearFinalPrice)}
                </>
              )}
            </>
          )}
        </div>

        <div className="ta-flex ta-gap-[8px]">
          {tire.CanAddToCart && (
            <>
              <div className="ta-w-[25%]">
                <select
                  value={tireQuantity}
                  onChange={onQuantityChange}
                  data-id={tire.TireId}
                  className="ta-border ta-border-[#909090] ta-h-[40px] ta-rounded-[2px] ta-w-full ta-text-center"
                >
                  {quantityList.map((quantity) => (
                    <option key={uuid()} value={quantity}>
                      {quantity}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ta-flex-grow">
                <CustomIconButton
                  text={"Add To Cart"}
                  classes="ta-rounded-[3px] ta-bg-button ta-text-white ta-font-bold ta-text-sm ta-p-2.5 ta-w-full"
                  onClick={addToCartClick}
                />
              </div>
            </>
          )}

          {!tire.CanAddToCart && tire.OutOfStockText !== "" && (
            <div className="ta-rounded-[3px] ta-font-bold ta-text-sm ta-p-2.5 ta-w-full ta-w-full">
              {tire.OutOfStockText}
            </div>
          )}
        </div>
      </div>
      <div className="ta-mt-auto ta-min-h-[21px]">
        <SearchResultsPromotionCard
          tire={tire}
          classes="ta-pb-2.5 ta-px-3 ta-align-self-end ta-justify-self-end"
        />
      </div>
    </div>
  );
};
