import axios, { AxiosError } from "axios";

export const errorHandler = (err: Error | AxiosError) => {
  const error = err as Error | AxiosError;
  if (!axios.isAxiosError(error)) {
    // do whatever you want with native error
    console.log(error);
  }
  // do what you want with your axios error
  console.log(error);
};
