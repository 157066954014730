import { PowerReviewsRenderConfig } from "types/powerreviews";
import { useCallback, useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { priceFormatter } from "helpers/pricing";
import { warrantyFormatter } from "helpers/warrantyFormatter";
import { addPrecision, removePrecision } from "helpers/precisionHelper";
import { BackButton } from "components/backButton/backButton";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { InputNumber } from "components/inputNumber/inputNumber";
import { SearchOptions, SearchParams } from "types/search";
import { Tire } from "types/tire";
import { TiresAnytimeRoutes } from "routes";
import { backToSearchResultsText } from "constants/index";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { usePowerReviews } from "hooks/usePowerReviews";
import { uuid } from "helpers/uuid";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { useSearchContext } from "context/search";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { StaggeredFitmentInfo } from "components/productDetails/staggeredFitmentInfo";
import { mapToSearchParamsToSearchOption } from "helpers/mapToSearchParamsToSearchOption";

interface Props {
  tire: Tire;
  addToCart: (tire: Tire, searchOptions: SearchOptions) => Promise<void>;
}

const defaultTiresQuantity = 4;

export const ProductDetails = ({ tire, addToCart }: Props) => {
  const { ready } = usePowerReviews();
  const { searchParamsData, searchedTires } = useSearchContext();
  const [tireQuantity, setTireQuantity] =
    useState<number>(defaultTiresQuantity);
  const { loading, load } = useBusyIndicator();
  const navigate = useNavigate();

  const addToCartClick = async () => {
    // show busy indicator, add product to cart & navigate to cart page
    load();
    await addToCart(
      { ...tire, QuantityToAdd: tireQuantity },
      mapToSearchParamsToSearchOption(searchParamsData as SearchParams)
    );
    navigate(TiresAnytimeRoutes.Cart);
  };

  // Create tire feature list
  const featuresListItems = tire.TireFeaturesDisplay?.map((featureItem) => (
    <li key={uuid()}>{featureItem}</li>
  ));
  // Create tire benefits list
  const benefitsListItems = tire.TireBenefitsDisplay?.map((benefitItem) => (
    <li key={uuid()}>{benefitItem}</li>
  ));

  useEffect(() => {
    // after our tires are updated on UX... call power reviews render function
    if (ready && tire) {
      const config: PowerReviewsRenderConfig = {
        api_key: process.env.REACT_APP_POWERREVIEWS_APIKEY!,
        merchant_group_id: process.env.REACT_APP_POWERREVIEWS_MERCHANTGROUPID!,
        merchant_id: process.env.REACT_APP_POWERREVIEWS_MERCHANTID!,
      };
      render(tire, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tire, ready]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const render = useCallback(
    debounce((tire: Tire, config: PowerReviewsRenderConfig) => {
      const payload = {
        api_key: config.api_key,
        locale: `en_US`,
        merchant_group_id: config.merchant_group_id,
        merchant_id: config.merchant_id,
        page_id: tire.PageId.toString(),
        components: {
          ReviewSnippet: "pr-pdp-snippet",
          ReviewDisplay: "pr-pdp-display",
        },
      };

      pwr("render", payload);
    }, 200),
    []
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fitmentSearchPerformed = searchedTires?.IsSplitFitment;

  return (
    <div
      id={`ta-${tire.TireId}`}
      className="ta-product-detail ta-text-base xl:ta-pl-2.5 xl:ta-pr-4"
    >
      <div className="ta-flex ta-flex-col ta-items-center ta-justify-center ta-mb-5 sm:ta-flex-row sm:ta-mb-12">
        <BackButton
          text={backToSearchResultsText}
          navigation={TiresAnytimeRoutes.Search}
          classes="ta-rounded-[3px] ta-py-1.5 ta-px-2 ta-bg-button ta-text-white"
        />

        <CustomIconButton
          classes={
            "ta-flex ta-items-center ta-rounded-[3px] ta-py-1 ta-px-5 ta-text-sm ta-bg-button ta-font-bold ta-text-white sm:ta-ml-auto sm:ta-mt-0 hover:ta-underline"
          }
          text={"Print"}
          onClick={handlePrint}
          children={
            <svg
              className="ta-w-[12px] ta-h-[12px] ta-fill-current ta-mr-2"
              viewBox="0 0 26 28"
            >
              <path d="M6 24h14v-4h-14v4zM6 14h14v-6h-2.5c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-10v10zM24 15c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM26 15v6.5c0 0.266-0.234 0.5-0.5 0.5h-3.5v2.5c0 0.828-0.672 1.5-1.5 1.5h-15c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-3.5c-0.266 0-0.5-0.234-0.5-0.5v-6.5c0-1.641 1.359-3 3-3h1v-8.5c0-0.828 0.672-1.5 1.5-1.5h10.5c0.828 0 1.969 0.469 2.562 1.062l2.375 2.375c0.594 0.594 1.062 1.734 1.062 2.562v4h1c1.641 0 3 1.359 3 3z"></path>
            </svg>
          }
        />
      </div>

      <div
        className="ta-product-detail-body ta-print xl:ta-pl-7"
        ref={componentRef}
      >
        <div className="ta-product-detail-body__top sm:ta-grid sm:ta-grid-cols-[210px_1fr] sm:ta-gap-[30px] sm:ta-mb-12 lg:ta-mb-5 lg:ta-grid-cols-[316px_1fr] lg:ta-gap-[13px]">
          <img
            src={tire.ImageUrl}
            alt={tire.ProductName}
            className="ta-product-detail-body__img ta-w-auto ta-mx-auto lg:ta-h-[310px]"
          />

          <div className="ta-product-detail-body__grid lg:ta-grid lg:ta-grid-cols-[1fr_205px] lg:ta-gap-[50px] xl:ta-gap-[102px] lg:ta-items-center">
            <div>
              <img
                src={tire.BrandImageUrl}
                alt={tire.ManufacturerName}
                className="ta-w-auto ta-my-4 ta-mx-auto sm:ta-mx-0 sm:ta-mt-0"
              />

              <h1 className="ta-text-lg ta-font-bold ta-px-1.5 !ta-bg-transparent ta-text-center sm:ta-text-left ta-max-w-none">
                {tire.ProductName}
              </h1>

              <div className="ta-product-detail-body__reviews ta-mt-2 ta-px-1.5 ta-text-center sm:ta-text-left ta-flex ta-items-center ta-gap-2">
                <div>Ratings: {tire.UserRatingImageUrl}</div>
                <div id="pr-pdp-snippet" className="ta-flex-grow"></div>
              </div>

              <div className="ta-product-detail-body__info">
                {fitmentSearchPerformed && <StaggeredFitmentInfo tire={tire} />}
                {!fitmentSearchPerformed && (
                  <>
                    <div className="ta-pt-2 ta-pb-4 ta-px-1.5 ta-text-sm ta-text-center sm:ta-text-left sm:ta-py-2">
                      Item#: {tire.ManufacturerPartNumber}
                    </div>
                    <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm ta-bg-[#D3D3D3]">
                      <div>
                        Tire Size:{" "}
                        <span className="ta-font-bold">
                          {tire.DisplayTireSize}
                        </span>
                      </div>
                      <div>
                        Mileage Warranty:{" "}
                        <span className="ta-font-bold">
                          {warrantyFormatter(tire.Warranty)}
                        </span>
                      </div>
                    </div>
                    <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm">
                      <div>
                        Load Rating:{" "}
                        <span className="ta-font-bold">
                          {removePrecision(tire.LoadRating)}
                        </span>
                      </div>
                      <div>
                        Speed Rating:{" "}
                        <span className="ta-font-bold">{tire.SpeedRating}</span>
                      </div>
                    </div>
                    <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm ta-bg-[#D3D3D3]">
                      <div>
                        Load Ply:{" "}
                        <span className="ta-font-bold">{tire.PlyRating}</span>
                      </div>
                      <div>
                        Sidewall:{" "}
                        <span className="ta-font-bold">{tire.SideWall}</span>
                      </div>
                    </div>
                    <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm">
                      <div>
                        Load Capacity:{" "}
                        <span className="ta-font-bold">
                          {tire.LoadCapacity}
                        </span>
                      </div>
                      <div>
                        UTQG: <span className="ta-font-bold">{tire.UTQG}</span>
                      </div>
                    </div>
                    <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm ta-bg-[#D3D3D3]">
                      <div>
                        Maximum PSI:{" "}
                        <span className="ta-font-bold">
                          {tire.MaxTirePressure}
                        </span>
                      </div>
                      <div>
                        Tread Depth:{" "}
                        <span className="ta-font-bold">
                          {addPrecision(tire.TreadDepth)}
                        </span>
                      </div>
                    </div>
                    <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm">
                      <div>
                        Overall Diameter:{" "}
                        <span className="ta-font-bold">
                          {tire.OverallDiameter}"
                        </span>
                      </div>
                      <div>
                        Tire Weight:{" "}
                        <span className="ta-font-bold">{tire.Weight} lbs.</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="ta-product-detail-body__pricing ta-mt-7 sm:ta-grid sm:ta-grid-cols-2 sm:ta-gap-[30px] sm:ta-items-center sm:ta-mt-11 lg:ta-block lg:ta-mt-0 lg:ta-w-[204px] lg:ta-justify-self-end xl:ta-align-self-center">
              <div className="ta-text-center sm:ta-text-right sm:ta-mt-3 xl:ta-mt-0">
                <span className="ta-text-3xl ta-font-bold ta-product-detail-body__price">
                  ${`${priceFormatter(tire.CalculatedPrice)} ea.`}
                </span>

                <div className="ta-text-lg">
                  {tire.InstallationText
                    ? tire.InstallationText
                    : `Installed: $
            ${priceFormatter(tire.InstallPrice + tire._finalPrice)}`}
                </div>
              </div>
              <div>
                {tire.CanAddToCart && (
                  <>
                    <div>
                      <InputNumber
                        count={tireQuantity}
                        setCount={setTireQuantity}
                        classes="ta-product-detail-body__quantity ta-w-[205px] ta-mx-auto ta-border ta-border-[#909090] ta-font-bold ta-text-center ta-py-2 ta-p-2.5 ta-mt-4 sm:ta-w-full"
                        dualsForStaggeredFitment={fitmentSearchPerformed}
                      />
                    </div>
                    <CustomIconButton
                      text={"Add To Cart"}
                      classes="ta-print-none ta-rounded-[3px] ta-mt-6 ta-px-2 ta-py-4 ta-bg-button ta-text-base ta-text-white ta-font-bold ta-w-full sm:ta-mt-2 sm:ta-p-2 hover:ta-underline"
                      onClick={addToCartClick}
                    />
                  </>
                )}
                {!tire.CanAddToCart && tire.OutOfStockText !== "" && (
                  <div className="ta-print-none ta-rounded-[3px] ta-mt-6 ta-px-2 ta-py-4 ta-text-base ta-font-bold ta-w-full sm:ta-text-right sm:ta-mt-2 sm:ta-p-2 sm:ta-pr-0">
                    {tire.OutOfStockText}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="ta-mt-9 sm:ta-mt-14">
          <h2 className="!ta-bg-transparent ta-font-bold ta-text-lg ta-tracking-normal ta-mb-6">
            Features &amp; Benefits
          </h2>
          <ul>{featuresListItems}</ul>
          <ul>{benefitsListItems}</ul>
        </div>

        <div className="ta-product-detail-reviews ta-mt-16">
          <div id="pr-pdp-display"></div>
          <a
            href={`#ta-${tire.TireId}`}
            className="ta-text-sm !ta-bg-none hover:ta-underline ta-relative -ta-top-[28px] lg:-ta-top-[37px]"
          >
            Back to Top
          </a>
        </div>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};
