import { warrantyFormatter } from "helpers/warrantyFormatter";
import { Tire } from "types/tire";
import { removePrecision, addPrecision } from "helpers/precisionHelper";

interface Props {
  tire: Tire;
  navigateToProductDetailsPage: () => void;
}
export const StaggeredFitmentInfo = ({
  tire,
  navigateToProductDetailsPage,
}: Props) => {
  return (
    <>
      <div className="ta-bg-[#D3D3D3] ta-p-2">
        <div className="ta-flex ta-gap-1.5 ta-justify-center">
          <div>Item#:</div>
          <div>
            F:{" "}
            <button
              type="button"
              className="ta-font-normal ta-underline ta-text-tertiary"
              onClick={navigateToProductDetailsPage}
            >
              {tire.ManufacturerPartNumber}
            </button>
            <br />
            R:{" "}
            <button
              type="button"
              className="ta-font-normal ta-underline ta-text-tertiary"
              onClick={navigateToProductDetailsPage}
            >
              {tire.RearManufacturerPartNumber}
            </button>
          </div>
        </div>
      </div>

      <div className="ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Tire Size:</div>
        <div className="ta-font-bold">
          F: {tire.DisplayTireSize}
          <br />
          R: {tire.RearDisplayTireSize}
        </div>
      </div>

      <div className="ta-bg-[#D3D3D3] ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Load Rating:</div>
        <div className="ta-font-bold">
          <span className="ta-font-bold">
            F: {removePrecision(tire.LoadRating)}
            <br />
            {tire.RearLoadRating && (
              <>R: {removePrecision(tire.RearLoadRating)}</>
            )}
          </span>
        </div>
      </div>

      <div className="ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Speed Rating:</div>
        <div className="ta-font-bold">
          F: {tire.SpeedRating}
          <br />
          {tire.RearSpeedRating && <>R: {tire.RearSpeedRating}</>}
        </div>
      </div>

      <div className="ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>UTQG:</div>
        <div className="ta-font-bold">
          F: {tire.UTQG}
          <br />
          R: {tire.RearUTQG}
        </div>
      </div>

      <div className="ta-bg-[#D3D3D3] ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Mileage Warranty:</div>
        <div className="ta-font-bold">
          F: {warrantyFormatter(tire.Warranty)}
          <br />
          {tire.RearWarranty && <>R: {warrantyFormatter(tire.RearWarranty)}</>}
        </div>
      </div>

      <div className="ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Tread Depth:</div>
        <div className="ta-font-bold">
          F: {addPrecision(tire.TreadDepth)}
          <br />
          R:{" "}
          {tire.RearTreadDepth && tire.RearTreadDepth !== null
            ? addPrecision(tire.RearTreadDepth)
            : "N/A"}
        </div>
      </div>

      <div className="ta-bg-[#D3D3D3] ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Sidewall:</div>
        <div className="ta-font-bold">
          F: {tire.SideWall}
          <br />
          R: {tire.RearSideWall}
        </div>
      </div>

      <div className="ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Load Ply:</div>
        <div className="ta-font-bold">
          F: {tire.LoadCapacity === "SL" ? "None" : tire.LoadCapacity}
          <br />
          {tire.RearLoadCapacity && (
            <>
              R:{" "}
              {tire.RearLoadCapacity === "SL" ? "None" : tire.RearLoadCapacity}
            </>
          )}
        </div>
      </div>

      <div className="ta-bg-[#D3D3D3] ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        Tire Weight: <span className="ta-font-bold">{tire.Weight}</span>
      </div>

      <div className="ta-p-2 ta-flex ta-gap-1.5 ta-justify-center">
        <div>Max PSI: </div>
        <div className="ta-font-bold">
          F:{" "}
          {tire.MaxTirePressure && tire.MaxTirePressure !== ""
            ? tire.MaxTirePressure
            : "None"}
          <br />
          R:{" "}
          {tire.RearMaxTirePressure && tire.RearMaxTirePressure !== ""
            ? tire.RearMaxTirePressure
            : "None"}
        </div>
      </div>
    </>
  );
};
