import { Tire } from "types/tire";
import { warrantyFormatter } from "helpers/warrantyFormatter";
import { addPrecision, removePrecision } from "helpers/precisionHelper";

interface Props {
  tire: Tire;
  navigateToProductDetailsPage: () => void;
}
export const StaggeredFitmentInfo = ({
  tire,
  navigateToProductDetailsPage,
}: Props) => {
  return (
    <div className="md:ta-mt-4 ta-text-sm">
      <div className="md:ta-inline-grid md:ta-grid-cols-2 md:ta-gap-6">
        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Tire Size:</div>
          <div className="ta-font-bold">
            F: {tire.DisplayTireSize}
            <br />
            R: {tire.RearDisplayTireSize}
          </div>
        </div>

        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Item#:</div>
          <div className="ta-font-bold">
            F:{" "}
            <button
              type="button"
              className="ta-font-normal ta-underline ta-text-tertiary"
              onClick={navigateToProductDetailsPage}
            >
              {tire.ManufacturerPartNumber}
            </button>
            <br />
            R:{" "}
            <button
              type="button"
              className="ta-font-normal ta-underline ta-text-tertiary"
              onClick={navigateToProductDetailsPage}
            >
              {tire.RearManufacturerPartNumber}
            </button>
          </div>
        </div>
      </div>

      <div className="md:ta-mb-3 md:ta-mt-6 md:ta-grid md:ta-grid-cols-2 md:ta-gap-x-6 md:ta-gap-y-3 lg:ta-grid-cols-3 xl:ta-grid-cols-[max-content_max-content_max-content]">
        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Load Rating:</div>
          <div className="ta-font-bold">
            F: {removePrecision(tire.LoadRating)}
            {tire.RearLoadRating && (
              <>
                <br />
                R: {removePrecision(tire.RearLoadRating)}
              </>
            )}
          </div>
        </div>

        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Speed Rating:</div>
          <div className="ta-font-bold">
            F: {tire.SpeedRating}
            {tire.RearSpeedRating && (
              <>
                <br />
                R: {tire.RearSpeedRating}
              </>
            )}
          </div>
        </div>

        <div>
          <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
            <div className="ta-whitespace-nowrap">Mileage Warranty:</div>
            <div className="ta-font-bold">
              <span className="xl:ta-whitespace-nowrap">
                F: {warrantyFormatter(tire.Warranty)}
              </span>
              <br />
              <span className="xl:ta-whitespace-nowrap">
                R:{" "}
                {tire.RearWarranty
                  ? warrantyFormatter(tire.RearWarranty)
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>

        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>UTQ:</div>
          <div className="ta-font-bold">
            F:{" " + tire.UTQG}
            <br />
            R:{" " + tire.RearUTQG}
          </div>
        </div>

        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Sidewall:</div>
          <div className="ta-font-bold">
            F:{" " + tire.SideWall}
            <br />
            R:{" " + tire.RearSideWall}
          </div>
        </div>

        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Load Ply:</div>
          <div className="ta-font-bold">
            F:{" " + tire.PlyRating}
            <br />
            R:{" " + tire.RearPlyRating}
          </div>
        </div>
        <div className="ta-py-1.5 lg:ta-py-0 lg:ta-flex ta-gap-2">
          <div>Tread Depth:</div>
          <div className="ta-font-bold">
            F:{" " + addPrecision(tire.TreadDepth)}
            <br />
            {tire.RearTreadDepth
              ? `R: ${addPrecision(tire.RearTreadDepth)}`
              : "R: N/A"}
          </div>
        </div>
      </div>
    </div>
  );
};
