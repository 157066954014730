import { Tire } from "types/tire";
import { Dialog } from "components/dialog/dialog";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { Rebates } from "components/rebate/rebate";
import { useEffect, useState } from "react";

interface Props {
  tire: Tire;
  classes?: string;
}

export const SearchResultsPromotionCard = ({ tire, classes }: Props) => {
  // State to manage whether rebates dialog should be opened
  const [open, setOpen] = useState<boolean>(false);
  // State to manage focus on rebate button after dialog closed
  const [focusRebate, setFocusRebate] = useState<boolean>(false);
  const hasRebate = tire.Rebates && tire.Rebates.length > 0 ? true : false;
  const hasOffer = tire.HasDiscount && tire.CalculatedPrice ? true : false;

  // state to manage offer dialog showing on hover
  const [offer, setOffer] = useState<string>("ta-exclusive-offer");
  const onEscape = () => {
    setOffer("");
  };

  const onCloseDialog = () => {
    setOpen(false);
    setFocusRebate(true);
  };
  useEffect(() => {
    // on hover, users must be able to dismiss the dialog with keyboard
    if (offer === "") {
      setTimeout(() => {
        setOffer("ta-exclusive-offer");
      }, 250);
    }
  }, [offer]);

  useEffect(() => {
    if (focusRebate) {
      setFocusRebate(false);
    }
  }, [focusRebate]);

  useEffect(() => {
    document.addEventListener("keydown", onEscape);
    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  }, []);
  return (
    <div
      className={`${
        hasRebate || hasOffer ? `ta-flex ta-gap-x-2 ${classes}` : ""
      }`}
    >
      {tire.Rebates && tire.Rebates.length > 0 ? (
        <div>
          <CustomIconButton
            text=""
            children={
              <>
                <span className="ta-sr-only">{tire.Rebates[0].Name}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="123"
                  height="30"
                  viewBox="0 0 123 30"
                >
                  <g id="icon-rebate" transform="translate(-343 -398)">
                    <rect
                      id="Rectangle_360"
                      data-name="Rectangle 360"
                      width="123"
                      height="30"
                      rx="3"
                      transform="translate(343 398)"
                      fill="#0078ce"
                    />
                    <text
                      id="Mail-In_Rebate"
                      data-name="Mail-In Rebate"
                      transform="translate(363 417)"
                      fill="#fff"
                      fontSize="14"
                      fontFamily="Helvetica-Bold, Helvetica"
                      fontWeight="700"
                    >
                      <tspan x="0" y="0">
                        Mail-In Rebate
                      </tspan>
                    </text>
                    <path
                      id="Icon_awesome-dollar-sign"
                      data-name="Icon awesome-dollar-sign"
                      d="M4.9,5.47,2.374,4.73a.691.691,0,0,1,.195-1.355H4.122a1.431,1.431,0,0,1,.8.246.365.365,0,0,0,.457-.047l.816-.8A.38.38,0,0,0,6.154,2.2a3.314,3.314,0,0,0-2.027-.7V.375A.376.376,0,0,0,3.752,0H3a.376.376,0,0,0-.375.375V1.5H2.568A2.568,2.568,0,0,0,.013,4.3,2.683,2.683,0,0,0,1.977,6.567l2.4.7a.691.691,0,0,1-.195,1.355H2.631a1.431,1.431,0,0,1-.8-.246.365.365,0,0,0-.457.047l-.816.8A.38.38,0,0,0,.6,9.8a3.314,3.314,0,0,0,2.027.7v1.125A.376.376,0,0,0,3,12h.75a.376.376,0,0,0,.375-.375V10.5A2.647,2.647,0,0,0,6.6,8.791,2.574,2.574,0,0,0,4.9,5.47Z"
                      transform="translate(349.752 406)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </>
            }
            showFocus={focusRebate}
            onClick={() => setOpen(true)}
            classes="ta-block ta-mb-0"
          />
          <Dialog
            title=""
            content={<Rebates rebates={tire.Rebates[0]} />}
            open={open}
            onClose={onCloseDialog}
            classes="lg:ta-w-[1200px]"
          />
        </div>
      ) : null}

      {tire.HasDiscount && tire.CalculatedPrice && (
        <div className={`${offer} ta-relative`} tabIndex={0}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="140"
            height="30"
            viewBox="0 0 140 30"
          >
            <title>
              {tire.DiscountTitle ? tire.DiscountTitle : "Exclusive Offer"}
            </title>
            <g id="icon-dealer-sale" transform="translate(-456 -457)">
              <rect
                id="Rectangle_364"
                data-name="Rectangle 364"
                width="140"
                height="30"
                rx="4"
                transform="translate(456 457)"
                fill="#28a745"
              />
              <path
                id="Icon_awesome-tag"
                data-name="Icon awesome-tag"
                d="M0,5.909V1.125A1.125,1.125,0,0,1,1.125,0H5.909a1.125,1.125,0,0,1,.8.33L11.67,5.3a1.125,1.125,0,0,1,0,1.591L6.886,11.67a1.125,1.125,0,0,1-1.591,0L.33,6.7a1.125,1.125,0,0,1-.33-.8ZM2.625,1.5A1.125,1.125,0,1,0,3.75,2.625,1.125,1.125,0,0,0,2.625,1.5Z"
                transform="translate(464 466)"
                fill="#fff"
              />
              <text
                id="Exclusive_Offer"
                data-name="Exclusive Offer"
                transform="translate(484 476)"
                fill="#fff"
                fontSize="14"
                fontFamily="Helvetica-Bold, Helvetica"
                fontWeight="700"
              >
                <tspan x="0" y="0">
                  Exclusive Offer
                </tspan>
              </text>
            </g>
          </svg>
          <div className="ta-hidden ta-absolute -ta-top-[30px] ta-min-w-full ta-bg-black ta-p-1 ta-text-white ta-rounded-[3px] ta-text-xs ta-font-bold ta-whitespace-nowrap before:ta-content-[''] before:ta-border-transparent before:ta-border-t-black before:ta-border-4 before:ta-absolute before:-ta-bottom-[8px] before:ta-left-[50%] before:ta-translate-x-[-50%]">
            {tire.DiscountTitle}
          </div>
        </div>
      )}
    </div>
  );
};
