import { priceFormatter } from "helpers/pricing";
import { uuid } from "helpers/uuid";
import { Warranty } from "types/cart";
interface Props {
  warranty: Warranty;
  onRemoveWarrantyClick: (e: any) => void;
  canRemove?: boolean;
}
export const CartWarranty = ({
  warranty,
  onRemoveWarrantyClick,
  canRemove = true,
}: Props) => {
  const removeText = "Remove";
  return (
    <tr
      className="ta-block ta-w-full ta-border-b ta-border-grey sm:ta-table-row sm:ta-w-auto sm:ta-border-b-0"
      key={uuid()}
    >
      <td className="ta-block ta-absolute ta-p-4 ta-w-[100px] sm:ta-w-[120px] sm:ta-static sm:ta-min-h-0 sm:ta-table-cell sm:ta-border-b sm:ta-border-grey">
        <img alt="" src={warranty.WarrantyImageUrl} className="ta-max-w-full" />
      </td>

      <td className="ta-block ta-w-full ta-p-4 ta-pl-[100px] xs:ta-pl-[120px] sm:ta-table-cell sm:ta-pl-1 sm:ta-w-auto sm:ta-border-b sm:ta-border-grey">
        <div>{warranty.Description}</div>
      </td>

      <td className="ta-block ta-float-left ta-pt-2 ta-pl-[100px] xs:ta-pl-[120px]  ta-pr-8 xs:ta-w-3/5 sm:ta-px-2 sm:ta-py-0 sm:ta-float-none sm:ta-border-b sm:ta-border-grey sm:ta-pl-7 sm:ta-w-auto sm:ta-table-cell sm:ta-text-center">
        ${priceFormatter(warranty.Price)} <span className="md:hidden">ea.</span>
      </td>

      <td className="ta-block ta-w-full ta-pt-2 ta-pr-7 sm:ta-table-cell sm:ta-py-0 sm:ta-border-b sm:ta-border-grey sm:ta-w-auto sm:ta-text-center">
        {warranty.Quantity}
      </td>

      <td className="ta-inline-block ta-clear-left ta-pl-[100px] xs:ta-pl-[120px]  ta-pr-3 ta-pb-4 ta-pt-3 xs:ta-w-1/2 sm:ta-w-auto sm:ta-pl-3 sm:ta-table-cell sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
        <div className="ta-font-bold">
          ${priceFormatter(warranty.Price * warranty.Quantity)}
        </div>
      </td>

      <td className="ta-inline-block ta-pt-3 ta-pb-4 ta-pr-3 xs:ta-w-1/2 sm:ta-w-auto sm:ta-py-0 sm:ta-px-4 sm:ta-table-cell sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
        {canRemove ? (
          <button
            data-id={warranty.TireId}
            type="button"
            onClick={onRemoveWarrantyClick}
            className="ta-flex ta-flex-nowrap ta-items-center sm:ta-m-auto"
          >
            <span className="sm:ta-sr-only lg:ta-not-sr-only ta-text-xs lg:ta-text-base">
              {removeText}
              <span className="ta-sr-only">{warranty.Brand}</span>
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.5"
              height="12"
              viewBox="0 0 10.5 12"
              className="ta-text-button ta-ml-2"
            >
              <path
                id="Icon_awesome-trash"
                data-name="Icon awesome-trash"
                d="M10.125.75H7.312L7.092.312A.563.563,0,0,0,6.588,0H3.909a.556.556,0,0,0-.5.312L3.187.75H.375A.375.375,0,0,0,0,1.125v.75a.375.375,0,0,0,.375.375h9.75a.375.375,0,0,0,.375-.375v-.75A.375.375,0,0,0,10.125.75Zm-8.878,10.2A1.125,1.125,0,0,0,2.37,12H8.13a1.125,1.125,0,0,0,1.123-1.055L9.75,3h-9Z"
                fill="currentColor"
              />
            </svg>
          </button>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};
