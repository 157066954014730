import { ChangeEvent, useState } from "react";
import { DropDown } from "components/dropDown/dropDown";
import { Tire } from "types/tire";
import { SelectOptions } from "types/search";
import { mapSortingOption } from "helpers/sortingOptions";
import { useSearchContext } from "context/search";
interface Props {
  sortBy: (key: keyof Tire, ascending: boolean) => void;
  sortingOptions: SelectOptions[];
  classes?: string;
  required?: boolean;
  id?: string;
}
export const SortingDropDown = ({
  sortBy,
  sortingOptions,
  classes,
  required,
  id,
}: Props) => {
  // State to manage user sorting selection
  const { sortDefault } = useSearchContext();
  const [value, setValue] = useState(sortDefault);
  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // If user selects a sorting option find it from sorting options prop
    const option = sortingOptions.find((o) => o.label === event.target.value);
    if (option) {
      // Get sorting field & direction (asc, desc)
      const { field, direction } = mapSortingOption(option.value);
      setValue(option.label);
      sortBy(field as keyof Tire, direction === "asc");
    }
  };

  return (
    <>
      <label htmlFor="sort" className="ta-sr-only">
        Sort
      </label>
      <DropDown
        id={id}
        placeholder={"Sort By"}
        options={sortingOptions}
        value={value}
        required={required}
        onChange={onChange}
        disabled={false}
        classes={`ta-text-sm ta-p-1 ta-rounded-[3px] ta-text-black ${classes}`}
      />
    </>
  );
};
