interface Props {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  classes?: string;
  dualsForStaggeredFitment?: boolean;
}
// Input with minus & plus buttons on each side. count state is managed by parent component
export const InputNumber = ({
  count,
  setCount,
  classes,
  dualsForStaggeredFitment = false,
}: Props) => {
  const increaseDecreaseAmount = dualsForStaggeredFitment ? 4 : 1;
  const lowerLimit = dualsForStaggeredFitment ? 4 : 1;

  const handlePlus = () => {
    // Only allow user to add up to 8 tires for quantity
    if (count >= 8) {
      return;
    }
    setCount(count + increaseDecreaseAmount);
  };
  const handleMinus = () => {
    // Only allow user to go down to 1 tire for quantity
    if (count <= lowerLimit) {
      return;
    }
    setCount(count - increaseDecreaseAmount);
  };
  return (
    <div className={`ta-flex ta-items-center ${classes}`}>
      <button
        type="button"
        onClick={handleMinus}
        data-testid="handleMinus"
        aria-label="Decrease Quantity"
      >
        -
      </button>
      <label className="ta-block ta-mx-auto">
        <span className="ta-sr-only ta-print-none" aria-live="polite">
          Quantity {count}
        </span>
        <input
          type="text"
          min={0}
          max={8}
          value={count}
          readOnly
          className="ta-text-center ta-w-[50px]"
        />
      </label>
      <button
        type="button"
        onClick={handlePlus}
        data-testid="handlePlus"
        aria-label="Increase Quantity"
      >
        +
      </button>
    </div>
  );
};
