import { DatePicker } from "components/datePicker/datePicker";
import TimePicker from "components/timePicker/timePicker";
import { ChangeEvent, forwardRef, useEffect } from "react";
import { Appointment } from "types/checkout";
import { useDealerContext } from "context/dealer";
import { isAfterStoreHours, isStoreClosed } from "helpers/storeAppointments";
import { ErrorText } from "components/error/errorText";

interface State extends Appointment {}
interface Props {
  appointment: State;
  isActive: boolean;
  setAppointment: React.Dispatch<React.SetStateAction<State>>;
  errors: Appointment;
  setError: React.Dispatch<React.SetStateAction<Appointment>>;
  nextStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const placeholders: Appointment = {
  firstChoiceDate: "First Choice Date",
  firstChoiceTime: "First Choice Time",
  secondChoiceDate: "Second Choice Date",
  secondChoiceTime: "Second Choice Time",
};

export const AppointmentInfoCard = forwardRef(
  (
    {
      appointment,
      setAppointment,
      errors,
      setError,
      isActive,
      nextStep,
    }: Props,
    ref
  ) => {
    const { selectedDealerLocation } = useDealerContext();

    // If user already selected time then changes date, re-evaluate the time for the newly date chosen
    const reEvaluateTimeChosen = (name: string, value: string) => {
      // Set time chosen for corresponding date that was changed
      const time = `${name.replace("Date", "")}Time` as keyof State;
      // If time for changed date is set & it is after store hours, set errors to show user
      if (
        appointment[time] &&
        isAfterStoreHours(selectedDealerLocation, appointment[time], value)
      ) {
        // If error is already set based on previous date selection, just return, otherwise set error
        if (errors[time] !== "") return;
        setError((prevState: State) => ({
          ...prevState,
          [time]: "Store is closed at this time, please choose another time",
        }));
      }
      // If time chosen during store hours for new date selection
      // but we had errors set based on previous date selection, the clear errors
      else if (errors[time] !== "") {
        setError((prevState: State) => ({
          ...prevState,
          [time]: "",
        }));
      }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Get name that corresponds to object key for Sate of this component
      const name = e.target.name as keyof State;
      const value = e.target.value;
      let isError = false;
      // If value for appointment is not set, show user error that field is required
      if (!value || value === "") {
        setError((prevState: State) => ({
          ...prevState,
          [name]: `${placeholders[name]} is Required`,
        }));
        isError = true;
      } else if (name.includes("Date")) {
        // If the selection is for date, check if store is open
        if (isStoreClosed(selectedDealerLocation, value)) {
          setError((prevState: State) => ({
            ...prevState,
            [name]: `Store is closed on this day, please choose another day`,
          }));
          isError = true;
        }
        // Check if time was chosen before date change. If so, re-evaluate time is during store hours
        reEvaluateTimeChosen(name, value);
      }
      if (name.includes("Time")) {
        // If the selection is for time, check if its during store hours
        const date = `${name.replace("Time", "")}Date` as keyof State;
        if (
          appointment[date] &&
          isAfterStoreHours(selectedDealerLocation, value, appointment[date])
        ) {
          setError((prevState: State) => ({
            ...prevState,
            [name]: `Store is closed at this time, please choose another time`,
          }));
          isError = true;
        }
      }
      // If error is set for field input & isError is false, clear error for that field
      if (errors[name] !== "" && !isError) {
        setError((prevState: State) => ({
          ...prevState,
          [name]: "",
        }));
      }
      // Finally set user input to appoitnment state
      setAppointment((prevState: State) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    };
    const isErrors = !!Object.values(errors).filter((e) => e !== "").length;
    const isFilledForm = !Object.values(appointment).includes("") && !isErrors;

    // Use useEffect to update the state based on conditions
    useEffect(() => {
      if (isFilledForm) {
        nextStep(true);
      } else {
        nextStep(false);
      }
    }, [isFilledForm, nextStep]);

    function hasErrorMessage(obj: { [key: string]: string }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "") {
          return <ErrorText text={obj[key]} />;
        }
      }
      return null;
    }

    return (
      <div>
        <div className="ta-flex ta-gap-x-2 ta-items-center ta-mb-2">
          <DatePicker
            dateRef={ref}
            name="firstChoiceDate"
            value={appointment.firstChoiceDate}
            errorText={errors.firstChoiceDate}
            onChange={onChange}
            placeholder={placeholders.firstChoiceDate}
            required={true}
            isActive={isActive}
            label="First Choice Date:"
            id="firstChoiceDate"
            ariaLabel="Calendar for first choice date"
          />

          <TimePicker
            isActive={isActive}
            name="firstChoiceTime"
            value={appointment.firstChoiceTime}
            placeholder={placeholders.firstChoiceTime}
            onChange={onChange}
            errorText={errors.firstChoiceTime}
            dealerLocation={selectedDealerLocation}
            date={appointment.firstChoiceDate}
            id="firstChoiceTime"
            label="First Choice Time:"
          />
        </div>

        <div className="ta-flex ta-items-center ta-gap-x-2">
          <DatePicker
            name="secondChoiceDate"
            value={appointment.secondChoiceDate}
            errorText={errors.secondChoiceDate}
            onChange={onChange}
            placeholder={placeholders.secondChoiceDate}
            required={true}
            isActive={isActive}
            label="Second Choice Date:"
            id="secondChoiceDate"
            ariaLabel="Calendar for second choice date"
          />
          <TimePicker
            isActive={isActive}
            name="secondChoiceTime"
            value={appointment.secondChoiceTime}
            placeholder={placeholders.secondChoiceTime}
            onChange={onChange}
            errorText={errors.secondChoiceTime}
            dealerLocation={selectedDealerLocation}
            date={appointment.secondChoiceDate}
            id="secondChoiceTime"
            label="Second Choice Time:"
          />
        </div>
        {hasErrorMessage(errors as any)}
      </div>
    );
  }
);
