import { CheckoutForm } from "components/checkoutForm/CheckoutForm";
import { BackButton } from "components/backButton/backButton";
import { TiresAnytimeRoutes } from "routes";
import { useState, useEffect } from "react";
import { Appointment, BillingForm, Payment } from "types/checkout";
import { useNavigate } from "react-router-dom";
import { OrderTotalWithImageCard } from "components/orderTotalWithImageCard/orderTotalWithImageCard";
import { useEcommerceContext } from "context/ecommerce";
import { useDealerContext } from "context/dealer";
import { placeOrderPayload } from "helpers/placeOrderPayload";
import { Cart } from "types/cart";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { formValidator } from "helpers/formValidator";
import { Toaster } from "components/toaster/toaster";

interface BillingState extends BillingForm {}
interface AppointmentState extends Appointment {}
interface PaymentState extends Payment {}

const defaultBillingState: BillingState = {
  firstName: "",
  lastName: "",
  email: "",
  zipCode: "",
  phone: "",
  address: "",
  aptSuite: "",
  city: "",
  state: "",
  confirmEmail: "",
};
const defaultAppointmentState: AppointmentState = {
  firstChoiceDate: "",
  firstChoiceTime: "",
  secondChoiceDate: "",
  secondChoiceTime: "",
};
const defaultPaymentState: PaymentState = {
  payInStore: false,
  payCreditCard: false,
  payPayPal: false,
  cardNumber: "",
  nameOnCard: "",
  expirationDate: "",
  cvv: "",
};

const billingError: BillingForm = {
  ...defaultBillingState,
};
const appointmentError: Appointment = {
  ...defaultAppointmentState,
};
const paymentError: Payment = {
  ...defaultPaymentState,
};

export const CheckoutPage = () => {
  // State to manage whether place order button should be active or not
  const [inActive, setInActive] = useState<boolean>(true);
  //#region State to manage billing, appointment, & payment entries
  const [billing, setBilling] = useState<BillingState>(defaultBillingState);
  const [appointment, setAppointment] = useState<AppointmentState>(
    defaultAppointmentState
  );
  const [payment, setPayment] = useState<PaymentState>(defaultPaymentState);
  //#endregion
  //#region State to manage form entry errors for billing, appointment, & payment entries
  const [billingErrors, setBillingError] = useState<BillingForm>(billingError);
  const [appointmentErrors, setAppointmentError] =
    useState<Appointment>(appointmentError);
  const [paymentErrors, setPaymentError] = useState<Payment>(paymentError);
  //#endregion
  const [showButton, setShowButton] = useState(true);
  const { placeOrder, cart, clearCart } = useEcommerceContext();
  const { selectedDealerLocation } = useDealerContext();
  const { loading, load, unload } = useBusyIndicator();

  const navigate = useNavigate();
  const onClickPlaceOrder = async (e: any) => {
    // In store payment option
    load();
    const placeOrderResp = await placeOrder(
      selectedDealerLocation?.DealerId as number,
      null,
      placeOrderPayload(billing, appointment, "InStore")
    );
    unload();
    // Set router state to data needed in confirmation page for display
    if (
      placeOrderResp &&
      placeOrderResp.OrderStatusDescription.toLocaleLowerCase() ===
        `order placed`
    ) {
      navigate(TiresAnytimeRoutes.Confirmation, {
        state: {
          name: billing.firstName,
          firstChoiceDate: appointment.firstChoiceDate,
          firstChoiceTime: appointment.firstChoiceTime,
          secondChoiceDate: appointment.secondChoiceDate,
          secondChoiceTime: appointment.secondChoiceTime,
          cart: cart,
        },
      });
      clearCart();
    } else if (placeOrderResp && placeOrderResp.Message) {
      Toaster(`${placeOrderResp.Message}`);
    }
  };

  useEffect(() => {
    // If billing, appointment & payment forms are complete with no errors
    // Enable place order button, otherwise keep it inactive
    if (
      isFormComplete(
        billing,
        appointment,
        payment,
        billingErrors,
        appointmentErrors
      )
    ) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [
    billing,
    appointment,
    payment,
    paymentErrors,
    billingErrors,
    appointmentErrors,
  ]);

  return (
    <div className="ta-text-base">
      <BackButton
        text={"Back to Cart"}
        navigation={TiresAnytimeRoutes.Cart}
        classes={"ta-bg-transparent"}
      />
      <div className="my-2">
        <span className="ta-text-red">*</span> indicates required field
      </div>
      <div className="ta-flex ta-gap-8 ta-flex-col lg:ta-flex-row">
        <div className="lg:ta-basis-2/3">
          <CheckoutForm
            billing={billing}
            setBilling={setBilling}
            billingError={billingErrors}
            setBillingError={setBillingError}
            appointment={appointment}
            setAppointment={setAppointment}
            appointmentError={appointmentErrors}
            setAppointmentError={setAppointmentError}
            payment={payment}
            setPayment={setPayment}
            paymentError={paymentErrors}
            setPaymentError={setPaymentError}
            setShowPlaceOrderBtn={setShowButton}
          />
        </div>
        <div className="lg:ta-basis-1/3">
          <OrderTotalWithImageCard
            inActive={inActive}
            onClick={onClickPlaceOrder}
            buttonText="Place Order"
            showButton={showButton}
            cart={cart as Cart}
          />
        </div>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};

// Check required billing inputs are complete with no errors
export const isBillingComplete = (
  billing: BillingState,
  billingErrors: BillingForm
): boolean => {
  return formValidator<BillingForm>(billing, billingErrors, ["aptSuite"]);
};

// Check appointment inputs are complete with no errors
export const isAppointmentComplete = (
  appointment: AppointmentState,
  appointmentErrors: Appointment
): boolean => {
  return formValidator<Appointment>(appointment, appointmentErrors);
};

// Check payment inputs are complete with no errors
export const isPaymentComplete = (payment: PaymentState): boolean => {
  if (payment.payInStore) return true;
  return false;
};

// Delegate method to call above methods to form one boolean for all
const isFormComplete = (
  billing: BillingState,
  appointment: AppointmentState,
  payment: PaymentState,
  billingErrors: BillingForm,
  appointmentErrors: Appointment
): boolean => {
  return (
    isBillingComplete(billing, billingErrors) &&
    isAppointmentComplete(appointment, appointmentErrors) &&
    isPaymentComplete(payment)
  );
};
