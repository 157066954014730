import { ChangeEvent, useEffect, useState } from "react";
import { priceFormatter } from "helpers/pricing";
import { PriceRange, Filters } from "types/search";
import { Filter, IFilterByPrice } from "types/filters";
import { mapPriceRange } from "./helpers/mapWarrantyAndPriceRange";
import { shouldCheckShowAll } from "components/filters/helpers/filtersShowAll";
import { isFilterByPrice } from "helpers/filterTypes";
import { useSearchContext } from "context/search";

interface Props {
  filterName: string;
  filterData: IFilterByPrice[];
  setFilter: (priceRange: PriceRange) => void;
  removeFilter: (priceRange: PriceRange) => void;
  clearFilter: (filter: keyof Filters) => void;
  open?: boolean;
}
interface State {
  [key: string]: boolean;
}

export const FilterByPrice = ({
  filterName,
  filterData,
  setFilter,
  removeFilter,
  clearFilter,
  open = false,
}: Props) => {
  const [checked, setChecked] = useState<State>({});
  const [filterOpen, setOpen] = useState(open);
  const { filters } = useSearchContext();
  filterData = [{ [Filter.All]: Filter.All }, ...filterData];
  const initState = () => {
    let data: State = {};
    let shouldCheckAll = true;
    const filteredPrices = filters.priceRanges;
    for (let i = 1; i < filterData.length; i++) {
      const isFiltered = filteredPrices?.find(
        (item) =>
          `${item.MaxPrice}` === `${filterData[i].MaxPrice}` &&
          `${item.MinPrice}` === `${filterData[i].MinPrice}`
      );
      shouldCheckAll = shouldCheckAll && isFiltered === undefined;
      data = {
        ...data,
        [`${filterData[i].MinPrice} - ${filterData[i].MaxPrice}`]: isFiltered
          ? true
          : false,
      };
    }
    data[Filter.All] = shouldCheckAll;
    return data;
  };

  useEffect(() => {
    setChecked(initState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckByPrice = (event: ChangeEvent<HTMLInputElement>) => {
    const { clearFilters: shouldClearFilters, showAll } = shouldCheckShowAll(
      checked,
      event.target.value,
      event.target.checked
    );
    if (event.target.checked && !shouldClearFilters) {
      setChecked(() => ({
        ...checked,
        [event.target.value]: true,
        [Filter.All]: showAll,
      }));
      if (event.target.value !== Filter.All) {
        setFilter(mapPriceRange(event.target.value));
      }
    } else if (!shouldClearFilters) {
      setChecked(() => ({
        ...checked,
        [event.target.value]: false,
        [Filter.All]: showAll,
      }));
      if (event.target.value !== Filter.All) {
        removeFilter(mapPriceRange(event.target.value));
      }
    } else {
      setChecked(() => ({
        ...checked,
        [Filter.All]: showAll,
      }));
      clearFilter("priceRanges");
    }
  };

  return (
    <div className="ta-bg-white ta-mb-2 ta-p-2.5 xl:ta-border xl:ta-border-[#bbb9b9] xl:ta-shadow-[0_1px_3px_rgba(0,0,0,.30)] xl:ta-rounded-[3px]">
      <div className="ta-text-sm ta-text-black ta-font-bold ta-m-0">
        <h3>
          <button
            type="button"
            onClick={() => setOpen(!filterOpen)}
            aria-expanded={filterOpen}
            className="ta-flex ta-items-center ta-w-full"
          >
            {filterName}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.478"
              height="4"
              viewBox="0 0 6.478 4"
              className={`ta-ml-auto ${!filterOpen && "-ta-rotate-90"}`}
            >
              <path
                id="icon-arrow-down"
                d="M6.478.761,5.717,0,3.239,2.473.761,0,0,.761,3.239,4Z"
              />
            </svg>
          </button>
        </h3>
      </div>
      {filterOpen && (
        <div className="list-container ta-mt-3">
          {filterData?.map((filter, index) => {
            const isFilterPriceType = isFilterByPrice(filter);
            return (
              <div className="ta-relative ta-custom-checkbox" key={index}>
                <input
                  value={
                    isFilterPriceType
                      ? `${filter.MinPrice} - ${filter.MaxPrice}`
                      : Filter.All
                  }
                  type="checkbox"
                  onChange={handleCheckByPrice}
                  id={`price-filter-${index}`}
                  checked={
                    isFilterPriceType
                      ? checked[`${filter.MinPrice} - ${filter.MaxPrice}`]
                      : checked[Filter.All]
                  }
                  className="ta-absolute ta-left-0 ta-top-[5px] ta-w-[10px] ta-h-[10px] ta-opacity-0 focus:ta-outline focus:ta-outline-1 focus:ta-opacity-100 focus:ta-outline-black"
                />
                <label
                  htmlFor={`price-filter-${index}`}
                  className="ta-flex ta-items-center before:ta-block before:ta-w-[10px] before:ta-h-[10px] before:ta-border"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    className="ta-absolute ta-top-[5px] ta-left-0 ta-opacity-0 ta-text-input"
                    aria-hidden="true"
                  >
                    <path
                      id="icon-checkbox-filled"
                      d="M12.89,4H5.11A1.11,1.11,0,0,0,4,5.11v7.78A1.11,1.11,0,0,0,5.11,14h7.78A1.11,1.11,0,0,0,14,12.89V5.11A1.11,1.11,0,0,0,12.89,4Zm-5,7.778L5.11,9l.785-.783,2,2L12.1,6l.785.787Z"
                      transform="translate(-4 -4)"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="ta-text-sm ta-ml-2">
                    {isFilterPriceType
                      ? `$${priceFormatter(
                          filter.MinPrice
                        )} - $${priceFormatter(filter.MaxPrice)}`
                      : Filter.All}
                  </span>
                  <span className="ta-sr-only">
                    Page will update results on selection
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
