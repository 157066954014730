import useFocus from "hooks/useFocus";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  text: string;
  navigation: string;
  focusButton?: boolean;
  classes?: string;
  onClick?: () => void; // Some components pass their own click callback to perform update to context state
  // when back button is selected, for example, compare page clears user compare selections
  // when leaving compare page
}
// Component that is used as back button across the app
export const BackButton = ({
  text,
  navigation,
  focusButton,
  classes,
  onClick,
}: Props) => {
  const [backButtonRef, setFocus] = useFocus();
  const navigate = useNavigate();
  const navigateToPage = () => {
    navigate(navigation);
  };
  // when removing items from cart, re focus on the back button
  useEffect(() => {
    if (focusButton) {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusButton]);
  return (
    <button
      type="button"
      ref={backButtonRef}
      className={`ta-flex ta-items-center ta-mb-4 ta-font-bold ta-text-black ta-text-sm md:ta-mb-0 ${classes}`}
      onClick={onClick ? onClick : navigateToPage}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        className="ta-mr-2.5"
      >
        <path
          id="Icon_awesome-arrow-alt-circle-left"
          data-name="Icon awesome-arrow-alt-circle-left"
          d="M6.563,12.563a6,6,0,1,1,6-6A6,6,0,0,1,6.563,12.563ZM9.369,5.5H6.563V3.783a.291.291,0,0,0-.5-.206L3.3,6.357a.288.288,0,0,0,0,.409l2.765,2.78a.29.29,0,0,0,.5-.206V7.627H9.369a.291.291,0,0,0,.29-.29V5.788A.291.291,0,0,0,9.369,5.5Z"
          transform="translate(-0.563 -0.563)"
          fill="currentColor"
        />
      </svg>
      {text}
    </button>
  );
};
