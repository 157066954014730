import React from "react";
import { CustomDropDown } from "components/customDropDown/customDropDown";
import { useSearchContext } from "context/search";
import { SelectOptions, Manufacturer, Year, Model, Option } from "types/search";
import { State } from "../searchByVehicleTabContent/searchByVehicleTabContent";
import { mapObjecToSelectionOptions } from "pages/finder/finderPage";
import { useDealerContext } from "context/dealer";

interface Props {
  stateOpt: State;
  setOptions: React.Dispatch<React.SetStateAction<State>>;
  isResetSearch?: boolean | undefined;
  setIsResetSearch?: any;
}

export const SearchByVehicleCard = ({
  stateOpt,
  setOptions,
  isResetSearch,
  setIsResetSearch,
}: Props) => {
  const {
    manufacturer,
    getManufacturerByYear,
    years,
    models,
    getModels,
    getOptions,
    options,
  } = useSearchContext();
  const { dealerStyling } = useDealerContext();
  //#region Map vehicle data to options object
  const makeOptions = manufacturer
    ? mapObjecToSelectionOptions(manufacturer as Manufacturer[])
    : [];
  const yearOptions = years ? mapObjecToSelectionOptions(years as Year[]) : [];
  const modelOptions =
    models && !(models.length === 0)
      ? mapObjecToSelectionOptions(models as Model[])
      : [];
  const bodyOptions = options ? mapOptionsObjToSelectionOptions(options) : [];
  //#endregion
  const onVehicleYearSelection = (e: SelectOptions | null) => {
    // When year is selected get manufacturers & set year value to state
    const year = parseInt(e?.value as string);
    if (year) {
      getManufacturerByYear(year);
      // Set year & reset make, model, body. If user changes year while he already
      // selected other options, we want to reset other options since year drives
      // the rest of the selection
      setOptions((prevState: State) => ({
        ...prevState,
        year: year,
        make: undefined,
        model: undefined,
        body: undefined,
      }));
    }
  };
  const onVehicleMakeSelection = (e: SelectOptions) => {
    // When make is selected get models & set make value to state
    const make = e?.value;
    if (make) {
      getModels(make, stateOpt.year as number);
      // Set make to state & reset model & body selections
      setOptions((prevState: State) => ({
        ...prevState,
        make: make,
        model: undefined,
        body: undefined,
      }));
    }
  };
  const onVehicleModelSelection = (e: SelectOptions) => {
    // When model is selected get options & set model to state
    const model = e?.value;
    if (model) {
      getOptions(stateOpt.make as string, stateOpt.year as number, model);
      // Set options to state & reset body
      setOptions((prevState: State) => ({
        ...prevState,
        model: model,
        body: undefined,
      }));
    }
  };
  const onVehicleBodySelection = (e: SelectOptions) => {
    const bodySel = e?.value;
    const bodyId = e?.data_id as number;
    if (bodySel && bodyId) {
      // set body selection to state
      setOptions((prevState: State) => ({
        ...prevState,
        body: mapDisplayAutoOptionSelectedWithOptionObj(
          bodyId,
          bodySel,
          options as Option[]
        ),
      }));
    }
  };

  return (
    <div
      className={dealerStyling?.Shape === "square" ? "ta-flex ta-flex-col" : ""}
    >
      <CustomDropDown
        options={yearOptions}
        placeholder={`Select Vehicle Year`}
        header={`Select Vehicle Year`}
        value={stateOpt.year}
        onClick={onVehicleYearSelection}
        id="searchByYear"
        isMega={true}
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />

      <CustomDropDown
        options={makeOptions}
        placeholder={`Select Vehicle Make`}
        header={`Select Vehicle Make`}
        value={stateOpt.make}
        onClick={onVehicleMakeSelection}
        isMega={false}
        id="searchByVehicleMake"
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />

      <CustomDropDown
        options={modelOptions}
        placeholder={`Select Vehicle Model`}
        header={`Select Vehicle Model`}
        value={stateOpt.model}
        onClick={onVehicleModelSelection}
        isMega={false}
        id="searchByVehicleModel"
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />

      <CustomDropDown
        options={bodyOptions}
        placeholder={`Select Vehicle Body Style`}
        header={`Select Vehicle Body Style`}
        value={stateOpt.body?.DisplayAutoOption}
        onClick={onVehicleBodySelection}
        isMega={false}
        id="searchByVehicleBodyStyle"
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />
    </div>
  );
};

const mapOptionsObjToSelectionOptions = (option: Option[]) => {
  const options: SelectOptions[] = [];
  for (let i = 0; i < option.length; i++) {
    options.push({
      label: option[i].DisplayAutoOption,
      value: option[i].DisplayAutoOption,
      data_id: option[i].CarTireId,
    });
  }
  return options;
};

const mapDisplayAutoOptionSelectedWithOptionObj = (
  carTireId: number,
  displayAutoOption: string,
  options: Option[]
): Option => {
  if (!options) {
    return {} as Option;
  }
  for (let i = 0; i < options.length; i++) {
    if (
      options[i].DisplayAutoOption === displayAutoOption &&
      options[i].CarTireId === carTireId
    ) {
      return options[i];
    }
  }
  return {} as Option;
};
