import { Option, SearchParams } from "types/search";
import { uuid } from "helpers/uuid";
import { mapOptionsToSearchParams } from "helpers/mapOptionsToSearchParams";

interface State extends SearchParams {}
interface VehicleWithId {
  [key: string]: Option;
}
interface Props {
  vehicles: Option[];
  setSearchParams: React.Dispatch<React.SetStateAction<State | undefined>>;
}
export const SearchByLicensePlateResultsCard = ({
  vehicles,
  setSearchParams,
}: Props) => {
  let vehiclesWithUniqueId: VehicleWithId = {};
  const onChange = (e: any) => {
    if (e.target.checked) {
      const vehicle: Option = vehiclesWithUniqueId[e.target.value];
      setSearchParams(mapOptionsToSearchParams(vehicle));
    }
  };
  return (
    <div className="ta-mb-7">
      <p className="ta-font-bold ta-my-3.5 ta-text-black">
        Please verify your vehicle
      </p>
      <form>
        {vehicles &&
          vehicles.map((vehicle: Option, index: number) => {
            const uniqueId = uuid();
            vehiclesWithUniqueId[uniqueId] = vehicle;
            return (
              <div key={index}>
                <input
                  className="ta-mr-2 ta-appearance-none ta-w-3 ta-h-3 ta-border ta-border-tertiary checked:ta-bg-tertiary"
                  name={"vehicle"}
                  onClick={onChange}
                  type="radio"
                  value={uniqueId}
                />
                <label>
                  {vehicle.AutoYear} {vehicle.AutoMake} {vehicle.AutoModel}{" "}
                  {vehicle.DisplayAutoOption}
                </label>
              </div>
            );
          })}
      </form>
    </div>
  );
};
