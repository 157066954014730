import { useRef, useEffect, useState } from "react";
interface Props {
  formId: string;
  navigateToConfirmationPage: () => void;
}

export const CreditCardForm = ({
  formId,
  navigateToConfirmationPage,
}: Props) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [observer, setObserver] = useState<MutationObserver | null>(null);
  const [placeOrderSet, setPlaceOrder] = useState(false);
  const onLoad = () => {
    const observer = new MutationObserver(() => {
      // check that iframe body is attached to the dom
      if (
        ref.current &&
        ref.current.contentDocument &&
        ref.current.contentDocument.body
      ) {
        // change button text to place order
        if (!placeOrderSet) {
          const inputs =
            ref.current.contentDocument.body.getElementsByTagName("input");
          if (inputs.length > 0) {
            for (let i = 0; i < inputs.length; i++) {
              if (inputs[i].value.toLocaleLowerCase() === `submit`) {
                inputs[i].value = `Place Order`;
                setPlaceOrder(true);
              }
            }
          }
        }
        // check if legend tag exist
        const legend =
          ref.current.contentDocument.body.getElementsByTagName("legend")[0];
        // check credit card transaction is accepted and navigate user
        if (
          legend &&
          legend.textContent?.toLocaleLowerCase() === `transaction accepted`
        ) {
          navigateToConfirmationPage();
        }
      }
    });
    setObserver(observer);
  };

  useEffect(() => {
    // make sure observer is set & iframe is attached to dom
    if (
      ref.current &&
      ref.current.contentDocument &&
      ref.current.contentDocument.body &&
      observer
    ) {
      // observe iframe document for changes
      observer.observe(ref.current.contentDocument, {
        subtree: true,
        childList: true,
        attributes: true,
      });
    }

    return () => {
      // disconnect observer from iframe document
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer]);

  return (
    <>
      <iframe
        title="firstMileCreditCardForm"
        id="firstMileForm"
        ref={ref}
        src={`/checkoutForm.html?${formId}`}
        referrerPolicy="origin"
        onLoad={onLoad}
        className="ta-h-[234px]"
      ></iframe>
    </>
  );
};
