import { useSearchContext } from "context/search";
import React, { ChangeEvent, useEffect } from "react";
import {
  SearchByTireLabels,
  SearchByTireSizeKeys,
  SelectOptions,
  TireBySize,
  TireBySizeData,
  Width,
} from "types/search";
import { CustomDropDown } from "components/customDropDown/customDropDown";
import { useDebounce } from "hooks/useDebounce";
import { parseIntOrFloat } from "helpers/parseIntOrFloat";
import useFocus from "hooks/useFocus";
import { useBackendData } from "hooks/useBackendData";

interface State extends TireBySize {}
interface Props {
  focusInput?: boolean;
  tireSizeOpt: State;
  setTireSizeOpt: React.Dispatch<React.SetStateAction<State>>;
  labels: SearchByTireLabels;
  keys: SearchByTireSizeKeys;
  isResetSearch?: boolean | undefined;
  setIsResetSearch: any;
}

export const SearchByTireSizeCard = ({
  focusInput,
  tireSizeOpt,
  setTireSizeOpt,
  labels,
  keys,
  isResetSearch,
  setIsResetSearch,
}: Props) => {
  const { tireBySize, getRatios, getRims, resetRatioAndRims } =
    useSearchContext();
  const [tireWidthFieldRef, setFocus] = useFocus();
  //#region size object keys
  // Set fields keys, since this component can be rendered twice
  // One for front tire size & one for rear tire size, setting these fields
  // helps make this component state re-usable for front & rear sizes
  const width = keys.width as keyof State;
  const ratio = keys.ratio as keyof State;
  const rim = keys.rim as keyof State;
  const tireBySizeRim = keys.rim as keyof TireBySizeData;
  const tireBySizeRatio = keys.ratio as keyof TireBySizeData;
  //#endregion
  //#region tire size data
  // If data is already set map it to options object
  const tireRatiosOpt = tireBySize[tireBySizeRatio]
    ? mapRatioAndRimToSelectionObj(tireBySize[tireBySizeRatio] as string[])
    : ([] as SelectOptions[]);
  const tireRimOpt = tireBySize[tireBySizeRim]
    ? mapRatioAndRimToSelectionObj(tireBySize[tireBySizeRim] as string[])
    : ([] as SelectOptions[]);
  // Get tire widths
  const { data } = useBackendData<Width[]>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetTireWidths`
  );
  const tireWidthOpt = data
    ? mapWidthToSelectionObj(data)
    : ([] as SelectOptions[]);
  //#endregion
  const onWidth = (e: SelectOptions) => {
    // when width is selected, set value to state & get ratio
    const width = e?.value;
    if (width) {
      setTireSizeOpt((prevState: State) => ({
        ...prevState,
        [keys.width]: parseIntOrFloat(width),
        [keys.ratio]: "",
        [keys.rim]: "",
      }));
      // If width changed & tireBySize state in search context is set
      // reset to avoid component displaying old values
      resetRatioAndRims(tireBySizeRatio, tireBySizeRim);
      getRatios(width, tireBySizeRatio);
    }
  };

  const onAspectRatio = (e: SelectOptions) => {
    // When ratio is selected, set value to state & get rims
    const ratio = e?.value;
    if (ratio) {
      getRims(tireSizeOpt[width]?.toString(), tireBySizeRim, ratio);
      setTireSizeOpt((prevState: State) => ({
        ...prevState,
        [keys.ratio]: parseIntOrFloat(ratio),
        [keys.rim]: "",
      }));
    }
  };

  const onRimRatio = (e: SelectOptions) => {
    // When rim is selected, set value to state
    const ratio = e?.value;
    if (ratio) {
      setTireSizeOpt((prevState: State) => ({
        ...prevState,
        [keys.rim]: parseIntOrFloat(ratio),
      }));
    }
  };

  // Move focus to the first input on the rear card when opened
  useEffect(() => {
    if (labels.tireWidth === "Enter Rear Tire Width") {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When closing the rear tire card, move focus back to the first input
  useEffect(() => {
    if (focusInput) {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusInput]);
  return (
    <>
      <CustomDropDown
        options={tireWidthOpt}
        placeholder={`Select Width`}
        header={`Select Width`}
        value={tireSizeOpt[width]}
        onClick={onWidth}
        isMega={false}
        id="selectWidth"
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />

      <CustomDropDown
        options={tireRatiosOpt}
        placeholder={`Select Aspect Ratio`}
        header={"Select Aspect Ratio"}
        value={tireSizeOpt[ratio]}
        onClick={onAspectRatio}
        isMega={false}
        id="selectAspectRatio"
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />

      <CustomDropDown
        options={tireRimOpt}
        placeholder={`Select Rim Diameter`}
        header={`Select Rim Diameter`}
        value={tireSizeOpt[rim]}
        onClick={onRimRatio}
        isMega={false}
        id="selectRimDiameter"
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />
    </>
  );
};

// Map ratio & rim to { label: value, value: value } for drop down select
const mapRatioAndRimToSelectionObj = (obj: string[]) => {
  const options: SelectOptions[] = [];
  for (let i = 0; i < obj.length; i++) {
    options.push({ label: obj[i], value: obj[i] });
  }
  return options;
};

// Map width results to
const mapWidthToSelectionObj = (obj: Width[]) => {
  const options: SelectOptions[] = [];
  for (let i = 0; i < obj.length; i++) {
    const str = obj[i].Width.toString();
    options.push({ label: str, value: str });
  }
  return options;
};
