import { useEffect, useState } from "react";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { SearchByTireSizeCard } from "components/searchByTireSizeCard/searchByTireSizeCard";
import { SearchTabFooter } from "components/searchTabFooter/searchTabFooter";
import {
  SearchByTireLabels,
  SearchByTireSizeKeys,
  SearchParams,
  TireBySize,
} from "types/search";
import { TiresAnytimeRoutes } from "routes";
import tireSizeImage from "../../assets/read-tire-size.png";
import useFocus from "hooks/useFocus";
import { isSearchResultsEmpty } from "helpers/isSearchResultsEmpty";

interface State extends TireBySize {}

interface Props {
  load: () => void;
  unload: () => void;
}

const labels: SearchByTireLabels = {
  tireWidth: "Enter Tire Width",
  tireRatio: "Select Aspect Ratio",
  tireRim: "Select Rim Diameter",
};
const frontLabels: SearchByTireLabels = {
  tireWidth: "Enter Front Tire Width",
  tireRatio: "Select Front Tire Asepct Ratio",
  tireRim: "Select Front Rim Diameter",
};
const rearLabels: SearchByTireLabels = {
  tireWidth: "Enter Rear Tire Width",
  tireRatio: "Select Rear Tire Aspect Ratio",
  tireRim: "Select Rear Tire Rim Diameter",
};
const frontKeys: SearchByTireSizeKeys = {
  width: "frontWidth",
  ratio: "frontRatio",
  rim: "frontRim",
};

const rearKeys: SearchByTireSizeKeys = {
  width: "rearWidth",
  ratio: "rearRatio",
  rim: "rearRim",
};

export const SearchByTireSizeTabContent = ({ load, unload }: Props) => {
  const { searchTires, resetRatioAndRims } = useSearchContext();
  // State to manage tire size object
  const [tireSize, setTireSize] = useState<State>({} as State);
  // State to manage whether find tires active or inactive
  const [inActive, setInActive] = useState<boolean>(true);
  const [isDifferentSizes, setDifferentSizes] = useState<boolean>(false);
  //State to manage dialog for how do I find my tire size button
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  // State to manage focus when dialog is closed
  const [dialogClosed, setDialogClosed] = useState<boolean>(false);

  const [formFocus, setFormFocus] = useState<boolean>(false);

  const [isResetSearch, setIsResetSearch] = useState<boolean>(false);

  const navigate = useNavigate();
  const [findTiresToggleRef, setToggleFocus] = useFocus();
  const [findTiresHeadingRef, setHeadingFocus] = useFocus();

  useEffect(() => {
    // If only one or two tire sizes, make sure all required fields are filled
    if (
      (!isDifferentSizes && tireSize.frontWidth && tireSize.frontRim) ||
      (isDifferentSizes &&
        tireSize.rearWidth &&
        tireSize.rearRim &&
        tireSize.frontWidth &&
        tireSize.frontRim)
    ) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [tireSize, isDifferentSizes]);

  // focus notification for screen readers when dialog is toggled
  useEffect(() => {
    if (helpOpen && findTiresHeadingRef.current) {
      setHeadingFocus();
    }
    if (!helpOpen && dialogClosed) {
      setToggleFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpOpen, dialogClosed]);

  const findTires = async (dealerId?: number) => {
    // Find tires button call
    load();
    const searchResults = await searchTires(
      {
        Make: null,
        Model: null,
        Year: null,
        Option: null,
        Width: tireSize.frontWidth,
        AspRatio: tireSize.frontRatio,
        Rim: tireSize.frontRim,
        LoadRating: "",
        SpeedRating: "",
        Width2: tireSize.rearWidth,
        AspRatio2: tireSize.rearRatio,
        Rim2: tireSize.rearRim,
        LoadRating2: "",
        VIN: null,
        TireSize: null,
      } as SearchParams,
      dealerId
    );
    unload();
    if (isSearchResultsEmpty(searchResults)) {
      navigate(TiresAnytimeRoutes.EmptySearchResults, {
        state: {
          isTireBySize: true,
          isByLicensePlate: false,
          isByVehicle: false,
        },
      });
    } else {
      navigate(TiresAnytimeRoutes.Search);
    }
  };

  const backToSingleFitment = () => {
    // If back to single fitment is selected, update component state
    setDifferentSizes(false);
    setFormFocus(true);
    setTireSize((prevState: State) => ({
      ...prevState,
      rearWidth: "",
      rearRatio: "",
      rearRim: "",
    }));
  };

  const resetSearch = () => {
    // If user selects reset search, reset ratios & rims from context state
    // and reset tire size state
    setIsResetSearch(true);
    resetRatioAndRims();
    setTireSize({} as State);
  };

  const closeTireDialog = (flag: boolean) => {
    setHelpOpen(flag);
    setDialogClosed(true);
  };

  const showRearTireCard = () => {
    setDifferentSizes(true);
    setFormFocus(false);
  };

  // If how do I find my tire size? isn't selected show search by tire size
  // otherwise show help dialog image
  if (!helpOpen) {
    return (
      <div className="ta-px-4 ta-py-3.5 md:ta-px-8">
        <button
          type="button"
          className="ta-border ta-border-[#696969] ta-rounded-[5px] ta-w-full ta-mb-2 ta-pt-3 ta-pb-3"
          onClick={() => setHelpOpen(true)}
          ref={findTiresToggleRef}
        >
          How Do I Find My Tire Size?
        </button>

        <div className="ta-search--by-size md:ta-grid md:ta-grid-cols-2 md:ta-gap-[15px]">
          <div>
            <SearchByTireSizeCard
              focusInput={formFocus}
              tireSizeOpt={tireSize}
              setTireSizeOpt={setTireSize}
              labels={isDifferentSizes ? frontLabels : labels}
              keys={frontKeys}
              isResetSearch={isResetSearch}
              setIsResetSearch={setIsResetSearch}
            />
          </div>
          <div className="ta-self-center ta-pt-[1.2rem] sm:ta-pt-0">
            {isDifferentSizes ? (
              <div>
                <SearchByTireSizeCard
                  tireSizeOpt={tireSize}
                  setTireSizeOpt={setTireSize}
                  labels={rearLabels}
                  keys={rearKeys}
                  isResetSearch={isResetSearch}
                  setIsResetSearch={setIsResetSearch}
                />
                <div className="ta-text-center">
                  <button
                    type="button"
                    className="ta-underline hover:ta-no-underline"
                    onClick={backToSingleFitment}
                  >
                    Back to Single Fitment
                  </button>
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="ta-border ta-border-[#E4E2E2] ta-rounded-md ta-w-full ta-p-3.5 ta-bg-[#696969] ta-text-white ta-text-sm"
                onClick={() => showRearTireCard()}
              >
                Different Tire Sizes on Front & Rear?
              </button>
            )}
          </div>
        </div>

        <SearchTabFooter
          inActive={inActive}
          findTires={findTires}
          resetSearch={resetSearch}
        />
      </div>
    );
  } else {
    return (
      <div className="ta-px-4 ta-py-3.5 md:ta-px-8">
        <div className="ta-relative ta-shadow-md ta-rounded-[5px] ta-bg-white ta-pt-3">
          <div
            className="ta-text-sm ta-mx-auto ta-mb-2 ta-pl-1 ta-font-bold sm:ta-pl-0 sm:ta-text-center"
            role="heading"
            aria-level={undefined}
            tabIndex={-1}
            ref={findTiresHeadingRef}
          >
            How Do I Find My Tire Size?
          </div>
          <div className="ta-flex ta-justify-center">
            <div className="ta-text-center">
              <div className="ta-inline-block ta-rounded-[3px] ta-py-0.5 ta-px-2 ta-bg-secondary ta-text-3xl ta-font-bold ta-text-white">
                215
              </div>
              <div className="ta-text-xs">Tire Width</div>
            </div>

            <div className="ta-text-[#7D92A1] ta-font-bold ta-text-2xl ta-px-1 ta-py-1 md:ta-px-3.5 md:ta-text-3xl">
              /
            </div>

            <div className="ta-text-center">
              <div className="ta-inline-block ta-rounded-[3px] ta-py-0.5 ta-px-3.5 ta-bg-secondary ta-text-3xl ta-font-bold ta-text-white">
                65
              </div>
              <div className="ta-text-xs">Aspect Ratio</div>
            </div>

            <div className="ta-text-[#7D92A1] ta-font-bold ta-text-3xl ta-px-1 ta-py-1 md:ta-px-3.5">
              R
            </div>

            <div className="ta-text-center">
              <div className="ta-inline-block ta-rounded-[3px] ta-py-0.5 ta-px-3.5 ta-bg-secondary ta-text-3xl ta-font-bold ta-text-white">
                17
              </div>
              <div className="ta-text-xs">Rim Size</div>
            </div>
          </div>
          <img src={tireSizeImage} alt="How to Read Tire" />

          <button
            type="button"
            className="ta-ml-auto ta-absolute ta-right-3 ta-top-3"
            onClick={() => closeTireDialog(false)}
          >
            <span className="ta-underline ta-text-[14px] ta-text-black">
              close
            </span>
            <span className="ta-inline-block ta-ml-2 ta-rounded-[3px] ta-px-[4px] ta-bg-[#7D92A1] ta-leading-none ta-font-bold ta-text-[14px] ta-text-white">
              &times;
            </span>
          </button>
        </div>
      </div>
    );
  }
};
