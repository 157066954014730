import { MileageWarrantiesRange, PriceRange } from "types/search";
import { Tire } from "types/tire";

export const isPriceInRange = (priceRange: PriceRange[], tire: Tire) => {
  for (let i = 0; i < priceRange.length; i++) {
    if (
      priceRange[i]?.MaxPrice >= tire._finalPrice &&
      priceRange[i]?.MinPrice <= tire._finalPrice
    ) {
      return true;
    }
  }
  return false;
};

export const isWarrantyInRange = (
  mileageWarrantiesRange: MileageWarrantiesRange[],
  tire: Tire
) => {
  for (let i = 0; i < mileageWarrantiesRange.length; i++) {
    if (
      mileageWarrantiesRange[i]?.MaxMiles >= tire.WarrantySort &&
      mileageWarrantiesRange[i]?.MinMiles <= tire.WarrantySort
    ) {
      return true;
    }
  }
  return false;
};
