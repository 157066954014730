import { OrderDetails } from "types/cart";
export const filterCartAddOns = (item: OrderDetails) => {
  let addOn = true;
  addOn = item.IsQuoteAddOn;
  return addOn;
};

export const itemHasWarranty = (item: OrderDetails) => {
  return !item.IsQuoteAddOn && item.IsWarranty;
};
