export interface FilterByPrice {
  MinPrice: number;
  MaxPrice: number;
  TireCount: number;
}
export interface IFilterByPrice {
  [key: string]: IFilterByPrice | string;
}
export interface IFilterBySpeedRating {
  SortOrder: number;
  SpeedRatingCode: string;
  TireCount: number;
}

export interface IFilterByCategory {
  category: string;
  tireCount: number;
}

export interface IFilterByBrand {
  BrandCode: string;
  BrandId: number | string;
  BrandName: string;
  ShowMarkupPrice: boolean;
  TireCount: number;
}

export interface FilterByWarranty {
  MinMiles: number;
  MaxMiles: number;
  TireCount: number;
}

export interface IFilterByWarranty {
  [key: string]: FilterByWarranty | string;
}

export enum Filter {
  All = "Show All",
}
