import { useLocation } from "react-router-dom";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useDealerContext } from "context/dealer";
import { OrderTotalWithImageCard } from "components/orderTotalWithImageCard/orderTotalWithImageCard";
import { convertToAmPM } from "helpers/timeAndDateConverter";
import { convertDateToMMDDYYYY } from "helpers/timeAndDateConverter";
import { useEcommerceContext } from "context/ecommerce";
import { Cart } from "types/cart";
import { useBackendData } from "hooks/useBackendData";
import { PlaceOrder } from "types/checkout";
import { BackButton } from "components/backButton/backButton";
import { backToFinder } from "constants/index";
import { TiresAnytimeRoutes } from "routes";

export const ConfirmationPage = () => {
  const { selectedDealerLocation } = useDealerContext();
  const { state } = useLocation();
  const { storeOrderInfo } = useEcommerceContext();

  // Retrieve data set in router state in checkout page for this page use
  const {
    name,
    firstChoiceDate,
    firstChoiceTime,
    secondChoiceDate,
    secondChoiceTime,
    cart,
    paymentMethod,
  } = state;
  const { data } = useBackendData<PlaceOrder>(
    () =>
      paymentMethod &&
      paymentMethod.toLocaleLowerCase() === `credit card` &&
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetOrderByShoppingCartUniqueIndentifier?uniqueId=${cart.ShoppingCartUniqueIdentifier}`
  );
  const orderInfo =
    paymentMethod && paymentMethod.toLocaleLowerCase() === "credit card"
      ? data
      : storeOrderInfo;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const displayFirstDate = convertDateToMMDDYYYY(firstChoiceDate);
  const displaySecondDate = convertDateToMMDDYYYY(secondChoiceDate);

  return (
    <>
      <BackButton text={backToFinder} navigation={TiresAnytimeRoutes.Home} />
      <div ref={componentRef} className="ta-flex ta-flex-col ta-items-end">
        <CustomIconButton
          classes={
            "ta-hidden sm:ta-flex no-print ta-items-center ta-justify-center ta-bg-white ta-text-button hover:ta-bg-button hover:ta-text-white ta-font-bold ta-text-sm ta-border ta-border-button ta-rounded-[3px] ta-ml-2 ta-p-2 ta-pl-6 ta-pr-6"
          }
          text={"Print"}
          onClick={handlePrint}
        />
        <div className="lg:ta-flex print-flex">
          <div className="lg:ta-basis-2/3 print-order-2 ta-pt-7">
            <h1
              id="pageHeading"
              className="ta-pb-[24px] ta-font-bold ta-text-[24px] ta-text-black"
            >
              Success!
            </h1>
            <p className="no-print ta-pb-[24px]">
              Thank you {name} for creating an online reservation with us. You
              will recieve a confirmation email and a store representative will
              call you to finalize your reservation and collect any additional
              information that may be needed.
            </p>
            <div className="ta-pb-[24px]">
              <p className="ta-text-black ta-font-bold">Confirmation number:</p>
              <p>{orderInfo?.PrimaryOrderNumber}</p>
              <br />
              <h2 className="!ta-bg-transparent ta-tracking-normal ta-text-black ta-font-bold ta-text-base">
                1st Choice Details:
              </h2>
              <p>Date: {displayFirstDate}</p>
              <p>Time: {convertToAmPM(firstChoiceTime)}</p>
              <br />
              <h2 className="!ta-bg-transparent ta-tracking-normal ta-text-black ta-font-bold ta-text-base">
                2nd Choice Details:
              </h2>
              <p>Date: {displaySecondDate}</p>
              <p>Time: {convertToAmPM(secondChoiceTime)}</p>
              <br />
              <p>{selectedDealerLocation?.Name}</p>
              <p>{selectedDealerLocation?.Address1}</p>
              <p>
                {selectedDealerLocation?.City},{" "}
                {selectedDealerLocation?.StateAbbr}{" "}
                {selectedDealerLocation?.PostalCode}
              </p>
              <p>{selectedDealerLocation?.Phone}</p>
            </div>
            <div
              ref={componentRef}
              className="ta-flex ta-flex-col ta-items-end sm:ta-hidden"
            >
              <CustomIconButton
                classes={
                  "ta-flex no-print ta-items-center ta-justify-center ta-bg-white ta-text-button hover:ta-bg-button hover:ta-text-white ta-font-bold ta-text-sm ta-border ta-border-button ta-rounded-[3px] ta-ml-2 ta-px-[35px] ta-py-[5px]"
                }
                text={"Print"}
                onClick={handlePrint}
              />
            </div>
          </div>
          <div className="lg:ta-basis-1/3 print-imgCard">
            <OrderTotalWithImageCard
              inActive={true}
              onClick={() => {}}
              buttonText=""
              showButton={false}
              withoutBorder={true}
              cart={cart as Cart}
            />
          </div>
        </div>
      </div>
    </>
  );
};
