import { PowerReviewsRenderConfig } from "types/powerreviews";
import { useCallback, useEffect, useState } from "react";
import { displaySortOptions } from "helpers/sortingOptions";
import { AllSearchedTires } from "types/search";
import { BackButton } from "components/backButton/backButton";
import { SearchResultsForText } from "components/searchResultsForText/searchResultsForText";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { FilterByBrand } from "components/filters/filterByBrand";
import { FilterByCategory } from "components/filters/filterByCategory";
import { FilterByPrice } from "components/filters/filterByPrice";
import { FilterBySpeedRating } from "components/filters/filterBySpeedRating";
import { FilterByWarranty } from "components/filters/filterByWarranty";
import { SortingDropDown } from "components/sortingDropDown/sortingDropDown";
import { Tire } from "types/tire";
import { TiresAnytimeRoutes } from "routes";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { usePowerReviews } from "hooks/usePowerReviews";
import { useSearchContext } from "context/search";
import { backToFinder } from "constants/index";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { useDealerContext } from "context/dealer";
import { DisplaySearchResultsCards } from "components/displaySearchResultsCards/displaySearchResultsCards";

export const SearchedResultPage = () => {
  const { ready } = usePowerReviews();
  const [inActive, setInActive] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const { loading, load, unload } = useBusyIndicator();
  const {
    filteredAndSortedTires,
    searchedTires,
    comparedTires,
    sortingBy,
    addFilterByCategorie,
    addFilterByBrand,
    addFilterByPriceRanges,
    addFilterByMileageWarrantiesRange,
    addFilterBySpeedRating,
    removeCategorie,
    removeBrandId,
    removePriceRange,
    removeWarrantyRange,
    removeSpeedRating,
    clearFilters,
  } = useSearchContext();
  const { dealerStyling } = useDealerContext();
  const isGrid = dealerStyling && dealerStyling.GridView ? true : false;
  const tiresResult: Tire[] = filteredAndSortedTires?.TireResult!;
  let tiresWithoutFilters = searchedTires?.AdditionalTires.TireResult.length;

  useEffect(() => {
    // If tiresResult equals to search resutls length then list is not fileterd, otherwise filtererd
    if (tiresWithoutFilters === tiresResult?.length) {
      setIsFiltered(false);
    } else setIsFiltered(true);
    // If compared tires is greater then one then set Compare button active, otherwise inactive
    if (comparedTires.length > 1) setInActive(false);
    else setInActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiresResult, comparedTires]);
  const navigate = useNavigate();

  const onCompareClick = () => {
    navigate(TiresAnytimeRoutes.Compare);
  };

  useEffect(() => {
    // after our tires are updated on UX... call power reviews render function
    if (
      ready &&
      filteredAndSortedTires?.TireResult &&
      filteredAndSortedTires.TireResult.length > 0
    ) {
      const config: PowerReviewsRenderConfig = {
        api_key: process.env.REACT_APP_POWERREVIEWS_APIKEY!,
        merchant_group_id: process.env.REACT_APP_POWERREVIEWS_MERCHANTGROUPID!,
        merchant_id: process.env.REACT_APP_POWERREVIEWS_MERCHANTID!,
      };
      render(filteredAndSortedTires, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAndSortedTires, ready]);

  const render = useCallback(
    debounce((tires: AllSearchedTires, config: PowerReviewsRenderConfig) => {
      const configPayload = {
        api_key: config.api_key!,
        locale: `en_US`,
        merchant_group_id: config.merchant_group_id!,
        merchant_id: config.merchant_id!,
      };

      let payload = tires.TireResult!.map((t) => {
        return {
          ...configPayload,
          page_id: t.PageId?.toString(),
          components: {
            CategorySnippet: `pr-cat-${t.PageId}-${t.ManufacturerPartNumber}-`,
          },
        };
      });

      if (!isFiltered) {
        let strategyPayload = tires.StrategyTires?.TireResult?.map((t) => {
          return {
            ...configPayload,
            page_id: t.PageId.toString(),
            components: {
              CategorySnippet: `pr-cat-${t.PageId}-${t.ManufacturerPartNumber}-ft`,
            },
          };
        });
        if (strategyPayload && strategyPayload.length > 0) {
          payload = payload.concat(strategyPayload);
        }
      }

      //   console.log("calling PWR for plp", payload);
      pwr("render", payload);
    }, 200),
    []
  );

  return (
    <div className="ta-search-page ta-text-base">
      <div className="xl:ta-grid xl:ta-grid-cols-searchResultGrid xl:ta-gap-x-[24px] xl:ta-items-center">
        <BackButton text={backToFinder} navigation={TiresAnytimeRoutes.Home} />

        <div className="ta-mb-3 xl:ta-grid xl:ta-grid-cols-2 xl:ta-gap-x-2 xl:ta-items-center">
          <SearchResultsForText />

          <div className="xl:ta-hidden">
            <button
              type="button"
              className="ta-flex ta-items-center ta-justify-center ta-bg-button ta-text-white ta-text-center ta-text-sm ta-font-bold ta-w-full ta-rounded-[3px] ta-h-[30px] ta-my-3"
              onClick={() => setOpenFilters(!openFilters)}
            >
              <svg
                id="icon-filter"
                viewBox="0 0 22 28"
                width="14px"
                height="14px"
                className="ta-mr-2"
              >
                <path
                  d="M21.922 4.609c0.156 0.375 0.078 0.812-0.219 1.094l-7.703 7.703v11.594c0 0.406-0.25 0.766-0.609 0.922-0.125 0.047-0.266 0.078-0.391 0.078-0.266 0-0.516-0.094-0.703-0.297l-4-4c-0.187-0.187-0.297-0.438-0.297-0.703v-7.594l-7.703-7.703c-0.297-0.281-0.375-0.719-0.219-1.094 0.156-0.359 0.516-0.609 0.922-0.609h20c0.406 0 0.766 0.25 0.922 0.609z"
                  fill="currentColor"
                ></path>
              </svg>
              Filters
            </button>
          </div>

          <div className="ta-grid ta-grid-cols-2 ta-gap-2 xl:ta-flex xl:ta-ml-auto">
            <span className="ta-sr-only" aria-live="polite">
              {inActive ? (
                <span>Compare button disabled</span>
              ) : (
                <span>compare button enabled</span>
              )}
            </span>
            <div>
              <CustomIconButton
                text={`Compare ${comparedTires.length}`}
                onClick={onCompareClick}
                inActive={inActive}
                classes={`ta-rounded-[3px] ta-py-2 ta-px-5 ta-text-black ta-text-sm ta-font-bold ta-w-full xl:ta-py-1.5 xl:ta-w-auto xl:ta-whitespace-nowrap ${
                  inActive ? "ta-bg-[#c4c4c4]" : "ta-bg-button ta-text-white"
                }`}
              />
            </div>
            <SortingDropDown
              id="sort"
              sortBy={sortingBy}
              sortingOptions={displaySortOptions}
              classes="ta-w-full xl:ta-w-auto ta-border ta-border-[#bbb9b9] ta-shadow-[0_1px_3px_rgba(0,0,0,.30)]"
            />
          </div>
        </div>
      </div>
      <div className="xl:ta-flex xl:ta-items-start xl:ta-gap-[24px]">
        <div
          className={`ta-search-filters ta-fixed ta-top-0 ta-left-0 ta-z-[99] ta-w-full ta-h-full ta-overflow-y-auto xl:ta-overflow-visible before:ta-block before:ta-absolute before:-ta-z-10 before:ta-top-0 before:ta-left-0 before:ta-w-screen before:ta-h-full before:ta-bg-black/70 xl:ta-block xl:ta-static xl:before:ta-hidden xl:ta-shrink-0 xl:ta-w-[335px] ${
            openFilters ? "ta-block" : "ta-hidden"
          }`}
        >
          <div className="ta-bg-white ta-py-9 ta-px-11 ta-w-[66.2%] ta-absolute ta-h-full ta-overflow-y-auto xl:ta-overflow-visible xl:ta-static xl:ta-h-auto xl:ta-w-full xl:ta-py-0 xl:ta-px-0">
            <button
              type="button"
              className={`ta-flex ta-items-center ta-mb-4 ta-font-bold ta-text-black ta-text-sm xl:ta-mb-0 xl:ta-hidden`}
              onClick={() => setOpenFilters(!openFilters)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                className="ta-mr-2.5"
              >
                <path
                  id="Icon_awesome-arrow-alt-circle-left"
                  data-name="Icon awesome-arrow-alt-circle-left"
                  d="M6.563,12.563a6,6,0,1,1,6-6A6,6,0,0,1,6.563,12.563ZM9.369,5.5H6.563V3.783a.291.291,0,0,0-.5-.206L3.3,6.357a.288.288,0,0,0,0,.409l2.765,2.78a.29.29,0,0,0,.5-.206V7.627H9.369a.291.291,0,0,0,.29-.29V5.788A.291.291,0,0,0,9.369,5.5Z"
                  transform="translate(-0.563 -0.563)"
                />
              </svg>
              Back
            </button>
            <FilterByWarranty
              filterName={"Mileage Warranty"}
              filterData={searchedTires?.UniqueMileageWarrantiesFilter!}
              setFilter={addFilterByMileageWarrantiesRange}
              removeFilter={removeWarrantyRange}
              clearFilter={clearFilters}
              open={true}
            />
            <FilterByBrand
              filterName={"Brand"}
              filterData={searchedTires?.UniqueBrandsFilter!}
              setFilter={addFilterByBrand}
              removeFilter={removeBrandId}
              clearFilter={clearFilters}
              open={true}
            />
            <FilterByPrice
              filterName={"Price"}
              filterData={searchedTires?.UniquePriceRangesFilter!}
              setFilter={addFilterByPriceRanges}
              removeFilter={removePriceRange}
              clearFilter={clearFilters}
            />
            <FilterByCategory
              filterName={"Tread Categories"}
              filterData={getCategoriesTireCount(tiresResult)}
              setFilter={addFilterByCategorie}
              removeFilter={removeCategorie}
              clearFilter={clearFilters}
            />

            <FilterBySpeedRating
              filterName={"Speed Rating"}
              filterData={searchedTires?.UniqueSpeedRatingsFilter!}
              setFilter={addFilterBySpeedRating}
              removeFilter={removeSpeedRating}
              clearFilter={clearFilters}
            />
          </div>
        </div>
        <div
          className={`ta-search-results ta-grid ta-auto-flow-col md:ta-grid-cols-2 ta-gap-[8px] xl:ta-flex-grow ${
            isGrid
              ? "xl:ta-grid-cols-3"
              : "lg:ta-auto-flow-col lg:ta-grid-cols-1"
          }`}
        >
          <span className="ta-sr-only" aria-live="polite">
            Showing {tiresResult?.length} results.
          </span>
          <DisplaySearchResultsCards
            tires={tiresResult}
            load={load}
            unload={unload}
            isFiltered={isFiltered}
            isGrid={isGrid}
          />
        </div>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};

// Return tire counts for each category: winter, run flat & show all
const getCategoriesTireCount = (tires: Tire[]) => {
  let allTires = tires?.length || 0;
  let winter = 0;
  let runFlat = 0;
  if (tires?.length) {
    for (const tire of tires) {
      if (tire.Winter) winter += 1;

      if (tire.RunFlat) runFlat += 1;
    }
  }
  return [
    { category: "Show All", tireCount: allTires },
    { category: "Winter", tireCount: winter },
    { category: "Run Flat", tireCount: runFlat },
  ];
};
