import { useSearchContext } from "context/search";
import { isVehicleSearchPerformed } from "helpers/isVehicleSearchPerformed";
export const SearchResultsForText = () => {
  const { searchParamsData, searchedTires } = useSearchContext();
  // Form search results for text
  const searchResultsFor = () => {
    if (isVehicleSearchPerformed(searchParamsData)) {
      return `${searchParamsData?.Year} ${searchParamsData?.Make} ${searchParamsData?.Model} ${searchParamsData?.Option}`;
    } else if (searchedTires?.IsSplitFitment) {
      return `F: ${searchParamsData?.Width}/${searchParamsData?.AspRatio}ZR${searchParamsData?.Rim} R: ${searchParamsData?.Width2}/${searchParamsData?.AspRatio2}ZR${searchParamsData?.Rim2}`;
    } else {
      return `By Size: ${searchParamsData?.Width}/${searchParamsData?.AspRatio}R${searchParamsData?.Rim}`;
    }
  };

  return (
    <h2 className="ta-text-sm ta-text-black !ta-bg-transparent ta-tracking-normal ta-font-bold">
      Searched Results for {searchResultsFor()}
    </h2>
  );
};
