import { ChangeEvent } from "react";
import { IncludedItem, OrderDetails, ProductDetails } from "types/cart";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { CartItems } from "components/cartItems/cartItems";
import { CartAdditionalServices } from "components/cartAdditionalServices/cartAdditionalServices";
import { CartAddOn } from "components/cartAddOn/cartAddOn";
import { CartWarranty } from "components/cartWarranty/cartWarranty";
import { filterCartAddOns, itemHasWarranty } from "helpers/cart";
import { mapOrderdetailsToWarranty } from "helpers/mapOrderdetailsToWarranty";
import { uuid } from "helpers/uuid";
interface Props {
  productDetails: ProductDetails;
  updateQuantity: (tireId: number, quantity: number) => Promise<void>;
  removeTire: (tireId: number, type: string) => void;
  removeIncludedItem: (addOnId: number, type: string) => void;
  removeAddOn: (addOnId: number, type: string) => void;
  removeWarranty: (tireId: number, type: string) => void;
}

export const CartDetails = ({
  productDetails,
  updateQuantity,
  removeTire,
  removeIncludedItem,
  removeAddOn,
  removeWarranty,
}: Props) => {
  const { loading, load, unload } = useBusyIndicator();
  const onQuantityChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    // If user changes cart quantity, show busy indicator, update quantity, hide busy indicator
    load();
    await updateQuantity(
      parseInt(e.target.getAttribute("data-id") as string),
      parseInt(e.target.value)
    );
    unload();
  };
  // Remove tire from cart
  const onRemoveTireClick = (e: any) => {
    removeTire(
      parseInt(e.currentTarget.attributes["data-id"].value as string),
      "tireId"
    );
  };

  // Remove included items from cart
  const onRemoveIncludedItemOnClick = (e: any) => {
    removeIncludedItem(
      parseInt(e.currentTarget.attributes["data-id"].value as string),
      "includedId"
    );
  };

  // Remove add on from cart
  const onRemoveAddOnClick = (e: any) => {
    removeAddOn(
      parseInt(e.currentTarget.attributes["data-id"].value as string),
      "addOnId"
    );
  };

  // Remove Warranty from cart
  const onRemoveWarrantyClick = (e: any) => {
    removeWarranty(
      parseInt(e.currentTarget.attributes["data-id"].value as string),
      "warrantyId"
    );
  };

  return (
    <div className="ta-cart-details">
      <table className="ta-block ta-w-full ta-max-w-none ta-border-0 ta-text-sm sm:ta-table">
        <thead className="ta-block ta-w-full sm:ta-table-header-group sm:ta-w-auto print:ta-hidden">
          <tr className="ta-block ta-w-full ta-h-[40px] ta-bg-header sm:ta-table-row sm:ta-w-auto sm:ta-h-auto">
            <th
              colSpan={2}
              className="ta-text-left ta-hidden ta-text-base ta-text-white ta-pl-7 ta-pr-7 ta-pt-2.5 ta-pb-2.5 sm:ta-table-cell"
            >
              Details
            </th>
            <th className="ta-hidden ta-text-base ta-text-white ta-bg-header sm:ta-table-cell sm:ta-text-center">
              Price
            </th>
            <th className="ta-hidden ta-text-base ta-text-white ta-bg-header sm:ta-table-cell sm:ta-text-center">
              Quantity
            </th>
            <th className="ta-hidden ta-text-base ta-text-white ta-bg-header sm:ta-table-cell sm:ta-text-center">
              Total
            </th>
            <th className="ta-hidden ta-text-base ta-text-white ta-bg-header sm:ta-table-cell sm:ta-text-center">
              <span className="ta-sr-only">Action</span>
            </th>
          </tr>
        </thead>
        <tbody className="ta-block ta-w-full sm:ta-table-row-group">
          {productDetails.Order.map((item: OrderDetails) => {
            const isCartAddon = filterCartAddOns(item);
            const isWarranty = itemHasWarranty(item);
            return !isCartAddon && !isWarranty ? (
              <CartItems
                key={uuid()}
                item={item}
                onQuantityChange={onQuantityChange}
                onRemoveTireClick={onRemoveTireClick}
              />
            ) : isCartAddon ? (
              <CartAddOn
                key={uuid()}
                item={item}
                onRemoveAddOnClick={onRemoveAddOnClick}
              />
            ) : (
              <CartWarranty
                key={uuid()}
                warranty={mapOrderdetailsToWarranty(item)}
                onRemoveWarrantyClick={onRemoveWarrantyClick}
              />
            );
          })}
          {productDetails.IncludedItems.map((item: IncludedItem) => {
            return (
              <CartAdditionalServices
                key={uuid()}
                item={item}
                onRemoveIncludedItemOnClick={onRemoveIncludedItemOnClick}
              />
            );
          })}
        </tbody>
      </table>
      <BusyIndicator loading={loading} />
    </div>
  );
};
