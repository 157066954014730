import { OrderDetails, Warranty } from "types/cart";

export const mapOrderdetailsToWarranty = (order: OrderDetails): Warranty => {
  if (order.WarrantyDescription && order.WarrantyPrice) {
    return {
      Brand: order.ProductName,
      Description: order.WarrantyDescription,
      Price: order.WarrantyPrice,
      Quantity: order.Quantity,
      WarrantyImageUrl: order.WarrantyImageUrl,
      TireId: order.TireId ? order.TireId : 0,
    };
  }
  return {
    Brand: order.Brand as string,
    Description: order.WarrantyText as string,
    Price: order.Price as number,
    Quantity: order.Quantity,
    WarrantyImageUrl: order.WarrantyImageUrl,
    TireId: order.TireId ? order.TireId : 0,
  };
};
