import { ChangeEvent, useState, useEffect } from "react";
import { uuid } from "helpers/uuid";
import { shouldCheckShowAll } from "components/filters/helpers/filtersShowAll";
import { IFilterByCategory, Filter } from "types/filters";
import { Filters } from "types/search";
import { useSearchContext } from "context/search";
interface Props {
  filterName: string;
  filterData: IFilterByCategory[];
  setFilter: (type: string) => void;
  removeFilter: (type: string) => void;
  clearFilter: (filter: keyof Filters) => void;
  open?: boolean;
}

interface State {
  [key: string]: boolean;
}

export const FilterByCategory = ({
  filterName,
  filterData,
  setFilter,
  removeFilter,
  clearFilter,
  open = false,
}: Props) => {
  const [checked, setChecked] = useState<State>({});
  const [filterOpen, setOpen] = useState(open);
  const { filters } = useSearchContext();

  const initState = () => {
    let data: State = {};
    const filteredCategories = filters.categorie;
    let shouldCheckAll = true;
    for (let i = 0; i < filterData.length; i++) {
      const isFiltered = filteredCategories?.find(
        (item) => item === filterData[i].category
      );
      shouldCheckAll = shouldCheckAll && isFiltered === undefined;
      data = { ...data, [filterData[i].category]: isFiltered ? true : false };
    }
    data[Filter.All] = shouldCheckAll;
    return data;
  };

  useEffect(() => {
    setChecked(initState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckByCategory = (event: ChangeEvent<HTMLInputElement>) => {
    const { clearFilters: shouldClearFilters, showAll } = shouldCheckShowAll(
      checked,
      event.target.value,
      event.target.checked
    );
    if (event.target.checked && !shouldClearFilters) {
      setChecked(() => ({
        ...checked,
        [event.target.value]: true,
        [Filter.All]: showAll,
      }));
      setFilter(event.target.value);
    } else if (!shouldClearFilters) {
      setChecked(() => ({
        ...checked,
        [event.target.value]: false,
        [Filter.All]: showAll,
      }));
      removeFilter(event.target.value);
    } else {
      setChecked(() => ({
        ...checked,
        [Filter.All]: showAll,
      }));
      clearFilter("categorie");
    }
  };

  return (
    <div className="ta-bg-white ta-mb-2 ta-p-2.5 xl:ta-border xl:ta-border-[#bbb9b9] xl:ta-shadow-[0_1px_3px_rgba(0,0,0,.30)] xl:ta-rounded-[3px]">
      <div className="ta-text-sm ta-text-black ta-font-bold ta-m-0">
        <h3>
          <button
            type="button"
            onClick={() => setOpen(!filterOpen)}
            aria-expanded={filterOpen}
            className="ta-flex ta-items-center ta-w-full"
          >
            {filterName}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.478"
              height="4"
              viewBox="0 0 6.478 4"
              className={`ta-ml-auto ${!filterOpen && "-ta-rotate-90"}`}
            >
              <path
                id="icon-arrow-down"
                d="M6.478.761,5.717,0,3.239,2.473.761,0,0,.761,3.239,4Z"
              />
            </svg>
          </button>
        </h3>
      </div>
      {filterOpen && (
        <div className="list-container ta-mt-2">
          {filterData?.map((category) => {
            return (
              <div key={uuid()}>
                <label>
                  <input
                    value={category.category}
                    name={filterName}
                    type="checkbox"
                    onChange={handleCheckByCategory}
                    checked={checked[category.category as keyof State]}
                  />
                  <span className="ta-text-sm ta-ml-2">
                    {category.category}
                  </span>
                  <span className="ta-sr-only">
                    Page will update results on selection
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
