import { warrantyFormatter } from "helpers/warrantyFormatter";
import { Tire } from "types/tire";

interface Props {
  tire: Tire;
}
export const SearchResultsGridItemInfo = ({ tire }: Props) => {
  return (
    <>
      <div>Size: {tire.DisplayTireSize}</div>
      <div>
        Mileage Warranty:{" "}
        <span className="ta-font-bold">
          {warrantyFormatter(tire.Warranty)} Miles
        </span>
      </div>
    </>
  );
};
