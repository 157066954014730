import React, { useEffect, useState } from "react";
import { Payment, PaymentMethods } from "types/checkout";
import { CreditCardForm } from "components/creditCardForm/creditCardForm";
import americanExpress from "../..//assets/A.png";
import discover from "../../assets/D.png";
import masterCard from "../../assets/M.png";
import visa from "../../assets/V.png";
import cfn from "../../assets/T.png";

interface State extends Payment {}
interface Props {
  paymentMethods: PaymentMethods | undefined;
  payment: State;
  setPayment: React.Dispatch<React.SetStateAction<State>>;
  paymentError: Payment;
  setPaymentError: React.Dispatch<React.SetStateAction<Payment>>;
  setShowPlaceOrderBtn: React.Dispatch<React.SetStateAction<boolean>>;
  showError?: boolean;
  isActive: boolean;
  buildUrl: (isCardLogo?: boolean) => Promise<string>;
  navigateToConfirmationPage: () => void;
  payPalPlaceOrder: () => void;
}
const errorText: string = `Unfortunatley, the payment for this order has failed.
An issuing bank will  often decline an attempt to charge a card if the name, expiration date, or
post code you entered doesn't match the bank's information. Please re-enter the cards information
or try another form of payment`;

export const PaymentInfoCard = ({
  paymentMethods,
  payment,
  setPayment,
  showError,
  buildUrl,
  navigateToConfirmationPage,
  setShowPlaceOrderBtn,
  payPalPlaceOrder,
  isActive = true,
}: Props) => {
  const [formId, setFormId] = useState<string>("");
  const [cardTypesFormId, setCardTypesFormId] = useState<string>("");

  useEffect(() => {
    async function getCardTypesFormId() {
      setCardTypesFormId(await buildUrl(true));
    }
    getCardTypesFormId();
  }, []);
  const onChangeRadio = async (e: any) => {
    const name = e.currentTarget.value as keyof State;
    if (name === "payCreditCard") {
      const formId = await buildUrl();
      setShowPlaceOrderBtn(false);
      setFormId(formId);
    } else if (name === "payPayPal") {
      setShowPlaceOrderBtn(false);
    } else {
      setShowPlaceOrderBtn(true);
    }
    setPayment((prevState: State) => ({
      ...prevState,
      payInStore: false,
      payCreditCard: false,
      payPayPal: false,
      [name]: true,
    }));
  };
  return (
    <div>
      <form>
        <fieldset className="ta-text-[14px] ta-flex ta-flex-col ta-gap-6 xl:ta-flex-row xl:ta-items-start xl:ta-gap-2">
          <legend>Select Payment Method</legend>
          <div
            className={`ta-flex ta-items-baseline ta-gap-1 ${
              !paymentMethods?.payInStore ? "ta-hidden" : ""
            }`}
          >
            <input
              disabled={!isActive}
              name={"payment"}
              type="radio"
              onClick={onChangeRadio}
              value={"payInStore"}
              id="payInStoreRadio"
            />
            <label className="ta-whitespace-nowrap" htmlFor="payInStoreRadio">
              Pay In Store
            </label>
          </div>

          <div
            className={`ta-flex ta-items-center ta-gap-1 ta-mr-[95px] ${
              !paymentMethods?.payWithCreditCard ? "ta-hidden" : ""
            }`}
          >
            <input
              disabled={!isActive}
              name={"payment"}
              type="radio"
              onClick={onChangeRadio}
              value={"payCreditCard"}
              id="creditCardRadio"
            />
            <label className="ta-whitespace-nowrap" htmlFor="creditCardRadio">
              Credit Card
            </label>
            <span className="ta-flex ta-w-[25px]">
              <img
                src={americanExpress}
                alt="AmericanExpress"
                className="ta-h-6 ta-w-auto ta-py-[3px] ta-px-[1px] ta-grayscale ta-opacity-50"
              />
              <img
                src={discover}
                alt="Discover"
                className="ta-h-6 ta-w-auto ta-py-[3px] ta-px-[1px] ta-grayscale ta-opacity-50"
              />
              <img
                src={masterCard}
                alt="MasterCard"
                className="ta-h-6 ta-w-auto ta-py-[3px] ta-px-[1px] ta-grayscale ta-opacity-50"
              />
              <img
                src={visa}
                alt="Visa"
                className="ta-h-6 ta-w-auto ta-py-[3px] ta-px-[1px] ta-grayscale ta-opacity-50"
              />
              <img
                src={cfn}
                alt="cfn"
                className="ta-h-6 ta-w-auto ta-py-[3px] ta-px-[1px] ta-grayscale ta-opacity-50"
              />
            </span>
          </div>

          <div
            className={`ta-flex ta-flex-wrap ta-gap-2 ta-relative ${
              !paymentMethods?.payWithPaypal ? "ta-hidden" : ""
            }`}
          >
            <span>
              <label htmlFor="payPalRadio" className="ta-sr-only">
                Paypal
              </label>
              <input
                id="payPalRadio"
                disabled={!isActive}
                name={"payment"}
                type="radio"
                onClick={onChangeRadio}
                value={"payPayPal"}
              />
            </span>

            <span>
              <svg
                className="ta-h-4"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="PayPal, opens in a new window"
                role="img"
                viewBox="0 0 121 32"
              >
                <path
                  fill="#003087"
                  style={{ fill: "var(--color1, #003087)" }}
                  d="M38.597 6.399c-0.248 0-0.459 0.18-0.498 0.426l-2.876 18.236c-0.004 0.022-0.006 0.047-0.006 0.072 0 0.258 0.209 0.467 0.467 0.467h3.41c0.248 0 0.459-0.181 0.498-0.426l0.845-5.355c0.038-0.245 0.25-0.426 0.498-0.426h3.1c3.623 0 6.698-2.643 7.259-6.225 0.566-3.612-2.255-6.761-6.255-6.769zM41.912 10.47h2.481c2.042 0 2.707 1.205 2.497 2.538-0.21 1.335-1.243 2.319-3.219 2.319h-2.525zM58.231 11.71c-0.861 0.003-1.852 0.18-2.964 0.643-2.551 1.064-3.777 3.261-4.297 4.864 0 0-1.656 4.889 2.086 7.575 0 0 3.47 2.586 7.377-0.159l-0.067 0.429c-0.004 0.022-0.006 0.047-0.006 0.072 0 0.258 0.209 0.466 0.466 0.467h3.237c0.248 0 0.459-0.181 0.498-0.426l1.969-12.488c0.004-0.022 0.006-0.047 0.006-0.073 0-0.258-0.209-0.466-0.466-0.467h-3.237c-0.248 0-0.46 0.181-0.498 0.426l-0.106 0.672s-1.414-1.544-3.997-1.535zM58.337 15.654c0.372 0 0.712 0.051 1.017 0.149 1.397 0.448 2.189 1.79 1.96 3.245-0.283 1.791-1.751 3.11-3.635 3.11-0.371 0-0.711-0.051-1.017-0.149-1.396-0.448-2.193-1.79-1.964-3.245 0.283-1.791 1.756-3.11 3.64-3.11z"
                ></path>
                <path
                  fill="#0070e0"
                  style={{ fill: "var(--color2, #0070e0)" }}
                  d="M85.215 6.399c-0.248 0-0.459 0.18-0.498 0.426l-2.876 18.236c-0.004 0.022-0.006 0.047-0.006 0.072 0 0.258 0.209 0.467 0.467 0.467h3.41c0.248 0 0.459-0.181 0.498-0.426l0.845-5.355c0.038-0.245 0.25-0.426 0.498-0.426h3.1c3.623 0 6.697-2.643 7.259-6.225 0.567-3.612-2.255-6.761-6.254-6.769zM88.53 10.47h2.481c2.042 0 2.707 1.205 2.497 2.538-0.21 1.335-1.243 2.319-3.219 2.319h-2.525zM104.848 11.71c-0.861 0.003-1.852 0.18-2.964 0.643-2.552 1.064-3.777 3.261-4.297 4.864 0 0-1.656 4.889 2.086 7.575 0 0 3.47 2.586 7.377-0.159l-0.067 0.429c-0.004 0.022-0.006 0.047-0.006 0.072 0 0.258 0.209 0.467 0.467 0.467h3.237c0.248 0 0.459-0.181 0.498-0.426l1.97-12.488c0.004-0.022 0.006-0.047 0.006-0.073 0-0.258-0.209-0.467-0.467-0.467-0 0-0 0-0 0h-3.237c-0.248 0-0.46 0.181-0.498 0.426l-0.106 0.672s-1.414-1.544-3.997-1.535zM104.954 15.654c0.372 0 0.712 0.051 1.017 0.149 1.397 0.448 2.189 1.79 1.96 3.245-0.283 1.791-1.751 3.11-3.635 3.11-0.371 0-0.711-0.051-1.017-0.149-1.396-0.448-2.193-1.79-1.964-3.245 0.283-1.791 1.756-3.11 3.64-3.11z"
                ></path>
                <path
                  fill="#003087"
                  style={{ fill: "var(--color1, #003087)" }}
                  d="M68.066 12.146c-0.257 0-0.439 0.251-0.361 0.495l3.549 11.013-3.209 5.19c-0.156 0.252 0.025 0.577 0.321 0.577h3.793c0.228-0 0.427-0.121 0.538-0.302l0.002-0.003 9.912-16.397c0.152-0.252-0.030-0.574-0.324-0.574h-3.793c-0.23 0-0.431 0.123-0.541 0.307l-0.002 0.003-3.902 6.587-1.981-6.538c-0.064-0.213-0.261-0.359-0.483-0.359z"
                ></path>
                <path
                  fill="#0070e0"
                  style={{ fill: "var(--color2, #0070e0)" }}
                  d="M116.827 6.399c-0.248 0-0.459 0.181-0.498 0.426l-2.876 18.235c-0.004 0.022-0.006 0.047-0.006 0.073 0 0.258 0.209 0.467 0.467 0.467 0 0 0 0 0 0h3.41c0.248 0 0.459-0.181 0.498-0.426l2.876-18.236c0.004-0.022 0.006-0.047 0.006-0.072 0-0.258-0.209-0.466-0.466-0.467h-0z"
                ></path>
                <path
                  fill="#001c64"
                  style={{ fill: "var(--color3, #001c64)" }}
                  d="M10.168 6.399c-0.459 0-0.839 0.335-0.91 0.773l-0.001 0.005-1.514 9.6c0.071-0.448 0.457-0.779 0.911-0.779h4.435c4.464 0 8.252-3.257 8.944-7.67 0.049-0.294 0.080-0.638 0.087-0.988l0-0.008c-1.134-0.595-2.467-0.935-3.927-0.935z"
                ></path>
                <path
                  fill="#0070e0"
                  style={{ fill: "var(--color2, #0070e0)" }}
                  d="M22.121 7.335c-0.007 0.358-0.039 0.701-0.093 1.038l0.006-0.042c-0.692 4.413-4.48 7.67-8.944 7.67h-4.435c-0.454 0-0.84 0.33-0.911 0.779l-1.392 8.822-0.872 5.534c-0.006 0.035-0.009 0.076-0.009 0.118 0 0.413 0.335 0.748 0.748 0.748 0 0 0 0 0 0h4.814c0.459-0 0.839-0.335 0.91-0.773l0.001-0.005 1.268-8.043c0.072-0.444 0.453-0.779 0.912-0.779h2.834c4.464 0 8.252-3.256 8.944-7.669 0.491-3.132-1.086-5.982-3.782-7.395z"
                ></path>
                <path
                  fill="#003087"
                  style={{ fill: "var(--color1, #003087)" }}
                  d="M4.698 0c-0.454 0-0.84 0.33-0.911 0.778l-3.778 23.956c-0.072 0.455 0.28 0.866 0.74 0.866h5.602l2.905-18.422c0.072-0.444 0.452-0.778 0.911-0.779h8.026c1.461 0 2.793 0.341 3.927 0.935 0.078-4.018-3.238-7.335-7.796-7.335z"
                ></path>
              </svg>
            </span>
            {payment.payPayPal ? (
              <div>
                <button type="button" onClick={payPalPlaceOrder}>
                  <img
                    src="https://www.paypal.com/en_US/i/btn/btn_xpressCheckout.gif"
                    className="ta-w-auto"
                    alt="PayPal, opens in a new window"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </fieldset>
      </form>
      {cardTypesFormId ? (
        <div className="ta-flex ta-gap-2 ta-items-center">
          <span>We Accept:</span>
          <iframe
            title="acceptedPaymentsIframe"
            id="acceptedPayments"
            src={`/checkoutIcons.html?${cardTypesFormId}`}
            referrerPolicy="origin"
          />
        </div>
      ) : null}
      {payment.payCreditCard ? (
        <CreditCardForm
          formId={formId}
          navigateToConfirmationPage={navigateToConfirmationPage}
        />
      ) : null}
      {showError ? <p>{errorText}</p> : null}
    </div>
  );
};
