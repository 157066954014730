import { useEffect, useRef } from "react";
import Select, { SelectInstance } from "react-select";
import { SelectOptions } from "types/search";
import GridOption from "./gridOption";

interface Props {
  options: SelectOptions[];
  placeholder: string;
  value: number | string | undefined;
  onClick: (e: SelectOptions) => void;
  isMega: boolean;
  header?: string;
  disabled?: boolean;
  id?: string;
  isResetSearch?: boolean | undefined;
  setIsResetSearch?: any;
  wrapperClass?: string;
  labelClass?: string;
  inputClass?: string;
  required?: boolean;
  isSearchable?: boolean;
  defaultStyles?: boolean | undefined;
}

export const CustomDropDown = ({
  options,
  placeholder,
  value,
  onClick,
  isSearchable,
  header,
  isMega,
  id,
  isResetSearch,
  setIsResetSearch,
  wrapperClass,
  labelClass,
  inputClass,
  required = false,
  defaultStyles = false,
}: Props) => {
  const selectRef = useRef<SelectInstance<any> | null>(null);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: "50px",
      borderRadius: state.isFocused ? "5px 5px 0 0" : "5px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
      borderColor: state.isFocused
        ? provided.borderColor
        : provided.borderColor,
      background: "white",
      paddingLeft: "14px",
      "&:hover": {
        borderColor: state.isFocused
          ? provided.borderColor
          : provided.borderColor,
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected ? "#3f51b5" : "white",
      "&:hover": {
        background: "#f1f1f1",
      },
      paddingLeft: "14px",
      display: isMega ? "inline-block" : "",
      width: isMega ? "10%" : "",
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      padding: 0,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const handleChange = (selectedOption: SelectOptions) => {
    onClick(selectedOption);
  };
  useEffect(() => {
    if (isResetSearch && setIsResetSearch) {
      selectRef.current?.clearValue();
      setIsResetSearch(false);
    }
  }, [isResetSearch, setIsResetSearch]);

  return (
    <div className={`custom-drop${wrapperClass ? ` ${wrapperClass}` : ""}`}>
      <label
        htmlFor={id}
        className={`ta-text-black ta-block ta-mb-1 ${labelClass}`}
      >
        {header ? header : ""}
        {required && <span className="ta-text-red">*</span>}
      </label>
      <Select
        aria-label={header ? header : ""}
        isSearchable={isSearchable}
        ref={selectRef}
        required={required}
        options={options}
        placeholder={placeholder}
        value={options.find((option) => option.value === value)}
        onChange={handleChange}
        isDisabled={options.length === 0}
        styles={!defaultStyles ? customStyles : {}}
        id={id}
        components={{
          Option: GridOption,
        }}
        classNamePrefix="ta-select"
        className={`ta-relative ${inputClass}`}
      />
    </div>
  );
};
