import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { Input } from "components/input/input";
import { BillingForm } from "types/checkout";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { redBorder } from "constants/index";
import { useSearchContext } from "context/search";
import { mapStatesToSelectionOptions } from "helpers/mapStatesToSelectionOptions";
import { patterns } from "constants/index";
import { formValidator } from "helpers/formValidator";

interface State extends BillingForm {}
interface Props {
  billing: State;
  setBilling: React.Dispatch<React.SetStateAction<State>>;
  errors: BillingForm;
  setError: React.Dispatch<React.SetStateAction<BillingForm>>;
  setActiveButton?: Dispatch<SetStateAction<boolean>>;
}

const labels: BillingForm = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email Address",
  zipCode: "Zip Code",
  phone: "Phone Number",
  address: "Address",
  aptSuite: "Apt/Suite/Floor (Optional)",
  city: "City",
  state: "State",
  confirmEmail: "Confirm Email Address",
};

export const BillingInfoCard = ({
  billing,
  setBilling,
  errors,
  setError,
  setActiveButton,
}: Props) => {
  const { states } = useSearchContext();
  const options = mapStatesToSelectionOptions(states);
  const onClickState = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setBilling((prevState: State) => ({
        ...prevState,
        state: e.target.value,
      }));
      setError((prevState: BillingForm) => ({
        ...prevState,
        state: "",
      }));
    } else if (!e.target.value || e.target.value === "") {
      setError((prevState: BillingForm) => ({
        ...prevState,
        state: `State is Required`,
      }));
    }
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement> | InputMaskChangeEvent
  ) => {
    const name = e.target.name as keyof BillingForm;
    const value = e.target.value;
    const pattern = e.target.pattern;
    let isError = false;
    // If value for billing input is not set, show user error that field is required
    if (!value || value === "") {
      setError((prevState: BillingForm) => ({
        ...prevState,
        [name]: `${labels[name]} is Required`,
      }));
      isError = true;
    }
    // If input field has pattern(regex) set to it, check input matches pattern
    else if (pattern) {
      const regex = RegExp(pattern);
      // If user input doesn't match pattern show error
      if (!regex.test(value)) {
        setError((prevState: BillingForm) => ({
          ...prevState,
          [name]: `Invalid ${labels[name]}`,
        }));
        isError = true;
      }
    }
    // check email confirmation matches email
    if (name === "confirmEmail" && value !== "" && value !== billing.email) {
      setError((prevState: BillingForm) => ({
        ...prevState,
        [name]: "Emails do not match",
      }));
      isError = true;
    }
    // reset error object
    if (errors[name] !== "" && !isError) {
      setError((prevState: BillingForm) => ({
        ...prevState,
        [name]: "",
      }));
    }
    // Set user input to billing state
    setBilling((prevState: State) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const optionalFields = ["aptSuite"];
  useEffect(() => {
    const isFilledForm = formValidator<BillingForm>(
      billing,
      errors,
      optionalFields
    );
    if (setActiveButton) {
      setActiveButton(isFilledForm);
    }
  }, [billing, setActiveButton]);
  return (
    <form className="ta-text-xs ta-font-sans">
      <div className="ta-flex ta-mb-2">
        <Input
          classes="ta-mr-2 ta-w-full"
          value={billing.firstName}
          placeholder="First Name"
          name="firstName"
          onChange={onChange}
          errorText={errors.firstName}
          type="input"
          required={true}
          label="First Name:"
          id="firstName"
          autocomplete="given-name"
        />
        <Input
          classes="ta-w-full"
          value={billing.lastName}
          placeholder="Last Name"
          name="lastName"
          onChange={onChange}
          errorText={errors.lastName}
          type="input"
          required={true}
          label="Last Name:"
          id="lastName"
          autocomplete="family-name"
        />
      </div>

      <Input
        classes="ta-mb-2"
        value={billing.address}
        placeholder="Address"
        name="address"
        onChange={onChange}
        errorText={errors.address}
        type="input"
        required={true}
        label="Address:"
        id="address"
        autocomplete="address-line1"
      />
      <Input
        classes="ta-mb-2"
        value={billing.aptSuite as string}
        placeholder="Apt/ Suite/ Floor (Optional)"
        name="aptSuite"
        onChange={onChange}
        errorText=""
        type="input"
        label="Apt/ Suite/ Floor (Optional):"
        id="aptSuite"
        autocomplete="address-line2"
      />
      <Input
        classes="ta-mb-2"
        value={billing.city}
        placeholder="City"
        name="city"
        onChange={onChange}
        errorText={errors.city}
        type="input"
        required={true}
        label="City:"
        id="city"
        autocomplete="address-level2"
      />

      <div className="ta-flex ta-mb-2 ta-gap-4">
        <div className="ta-w-full">
          <div className="custom-drop ta-shrink-0 ta-checkout-select">
            <label
              className="ta-mb-1 ta-block ta-text-sm ta-font-bold ta-text-black"
              htmlFor="state-select"
            >
              State:
              <span className="ta-text-red">*</span>
            </label>
            <select
              id="state-select"
              className={`ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-h-[33px] ta-p-2 ta-w-full red-border ${
                !billing.state ? redBorder : ""
              }`}
              onChange={onClickState}
              aria-label={"State"}
              autoComplete="state"
            >
              <option value="">Choose State</option>
              {options?.map((option: any) => (
                <option
                  key={option.data_id}
                  label={option.label}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </select>
          </div>
          <div style={{ color: "darkred" }} aria-describedby="billingInfoState">
            {errors.state}
          </div>
        </div>

        <div className="ta-w-full">
          <label
            htmlFor="zipCode"
            className="ta-mb-1 ta-block ta-text-sm ta-font-bold ta-text-black"
          >
            Zip Code: <span className="ta-text-red">*</span>
          </label>
          <InputMask
            id="zipCode"
            onChange={onChange}
            mask={"99999"}
            value={billing.zipCode}
            required={true}
            name="zipCode"
            placeholder="Zip Code"
            autoComplete="postal-code"
            className={`ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-h-[33px] ta-p-2 ta-w-full ${
              !billing.zipCode ? redBorder : ""
            }`}
          />
          <div
            style={{ color: "darkred" }}
            aria-describedby="zipCode"
            role="status"
          >
            {errors.zipCode}
          </div>
        </div>
      </div>

      <div className="ta-mb-2">
        <label
          htmlFor="phone"
          className="ta-mb-1 ta-block ta-text-sm ta-font-bold ta-text-black"
        >
          Phone Number<span className="ta-text-red">*</span>
        </label>
        <InputMask
          onChange={onChange}
          mask={"(999)999-9999"}
          value={billing.phone}
          name="phone"
          placeholder="Phone Number:"
          id="phone"
          autoComplete="tel"
          className={` ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-p-2 ta-h-[33px] ta-px-3 ta-w-full ${
            !billing.zipCode ? redBorder : ""
          }`}
        />
        <div
          style={{ color: "darkred" }}
          aria-describedby="phone"
          role="status"
        >
          {errors.phone}
        </div>
      </div>

      <div className="ta-mb-2">
        <Input
          name="email"
          value={billing.email}
          placeholder="Email Address"
          onChange={onChange}
          errorText={errors.email}
          type="input"
          pattern={patterns.email}
          required={true}
          label="Email Address"
          autocomplete="email"
          id="email"
        />
      </div>

      <Input
        name="confirmEmail"
        value={billing.confirmEmail}
        placeholder="Confirm Email Address"
        onChange={onChange}
        errorText={errors.confirmEmail}
        type="input"
        required={true}
        label="Confirm Email Address"
        autocomplete="email"
        id="confirmEmail"
      />
    </form>
  );
};
