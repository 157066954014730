import { ChangeEvent, useState } from "react";
import { priceFormatter } from "helpers/pricing";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { StaggeredFitmentInfo } from "components/searchResultsListCard/staggeredFitmentInfo";
import { SearchResultsListItemInfo } from "components/searchResultsListCard/searchResultsListItemInfo";
import { SearchOptions } from "types/search";
import { Tire } from "types/tire";
import { TiresAnytimeRoutes } from "routes";
import { useNavigate } from "react-router-dom";
import { uuid } from "helpers/uuid";
import { useSearchContext } from "context/search";
import { SearchResultsPromotionCard } from "components/searchResultsPromotionCard/searchResultsPromotionCard";

interface Props {
  tire: Tire;
  index?: number;
  isStrategyTire: boolean;
  addToCart: (tire: Tire, searchOptions: SearchOptions) => Promise<void>;
  classes?: string;
}

const defaultTiresQuantity = 4;

export const SearchResultsListCard = ({
  tire,
  index,
  isStrategyTire,
  addToCart,
  classes,
}: Props) => {
  // State to manage quantity for add to cart option in search results
  const [tireQuantity, setTireQuantity] =
    useState<number>(defaultTiresQuantity);
  const { comparedTires, compareTires, removeComparedTires, searchedTires } =
    useSearchContext();
  // State to manage if check box is checked or not
  const [checkbox, setCheckbox] = useState(false);
  const handleChangeCheckbox = (e: any) => {
    // If tire compare is checked & only 3 tires or less are checked
    // set checked property to true & add tire to comparedTires search state context
    if (e.target.checked && !(comparedTires.length >= 3)) {
      compareTires(parseInt(e.target.attributes["data-id"].value));
      setCheckbox(!checkbox);
    }
    // If tire compare is checked & already 3 tires are checked for compare
    // Set check to false to reset checkbox & alert user only max of 3 are allowed
    else if (e.target.checked && comparedTires.length >= 3) {
      e.target.checked = false;
      alert("You can only select a maximum of three items to compare.");
    }
    // Compare is unchecked, remove tire from comparedTire search state context
    // and set checked property to false
    else {
      removeComparedTires(parseInt(e.target.attributes["data-id"].value));
      setCheckbox(!checkbox);
    }
  };

  const navigate = useNavigate();

  const addToCartClick = async () => {
    await addToCart(
      { ...tire, QuantityToAdd: tireQuantity },
      {} as SearchOptions
    );
    navigate(TiresAnytimeRoutes.Cart);
  };
  const onQuantityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // When tire quantity is changes update state with value
    setTireQuantity(parseInt(e.target.value));
  };

  const navigateToProductDetailsPage = () => {
    navigate(TiresAnytimeRoutes.ProductDetails, { state: tire });
  };

  const fitmentSearchPerformed = searchedTires?.IsSplitFitment;
  const quantityList = fitmentSearchPerformed
    ? [4, 8]
    : [1, 2, 3, 4, 5, 6, 7, 8];
  const InStock =
    tire.InventoryPrimaryWarehouse + tire.InventorySecondaryWarehouse > 0
      ? true
      : false;

  return (
    <>
      <div
        className={`ta-bg-white ta-border-[.5px] ta-border-[#bbb9b9] ta-shadow-[0_1px_3px_rgba(0,0,0,.30)] ta-text-base ta-text-center lg:ta-text-left ${classes}`}
      >
        <div className="ta-flex ta-items-start">
          {isStrategyTire ? (
            <div className="ta-mr-auto ta-bg-primary ta-text-white ta-uppercase ta-text-sm ta-font-bold ta-p-1 ta-text-center ta-w-[132px] ta-max-w-full ta-rounded-r-[3px]">
              {tire.StrategyLevelDisplay}
            </div>
          ) : null}
          <div className="ta-ml-auto ta-pt-2 ta-pr-2">
            <label className="ta-text-xs ta-flex ta-items-center">
              <span className="ta-mr-2">Compare</span>

              <input
                type="checkbox"
                defaultChecked={checkbox}
                onChange={handleChangeCheckbox}
                data-id={tire.TireId}
              />
            </label>
          </div>
        </div>

        <div className="ta-p-4 lg:ta-flex lg:ta-gap-[10px] lg:ta-items-start lg:ta-pl-5">
          <button
            type="button"
            className="lg:ta-pr-8 lg:ta-w-[180px]"
            onClick={navigateToProductDetailsPage}
            aria-label={`View ${tire.ManufacturerName} ${tire.ProductName} ${tire.StrategyLevelDisplay} details`}
          >
            <img
              src={tire.BrandImageUrl}
              alt={tire.ManufacturerName}
              className="ta-mb-5 ta-block ta-w-auto ta-mx-auto"
            />
            <img
              src={tire.ImageUrl}
              alt={tire.ProductName}
              className="ta-w-[110px] ta-h-auto ta-mx-auto"
            />
          </button>

          <div>
            <h2 className="!ta-bg-transparent ta-tracking-normal ta-font-bold ta-text-black ta-normal-case ta-text-lg">
              <button
                type="button"
                className="hover:ta-text-secondary hover:ta-underline"
                onClick={navigateToProductDetailsPage}
                aria-label={`View ${tire.ManufacturerName} ${tire.ProductName} ${tire.StrategyLevelDisplay} details`}
              >
                {tire.ProductName}
              </button>
            </h2>

            <div className="ta-mt-3">
              <div
                className="ta-inline-block"
                id={`pr-cat-${tire.PageId}-${tire.ManufacturerPartNumber}-${
                  isStrategyTire ? "ft" : ""
                }`}
              ></div>
            </div>

            {!fitmentSearchPerformed && (
              <SearchResultsListItemInfo
                tire={tire}
                navigateToProductDetailsPage={navigateToProductDetailsPage}
              />
            )}
            {fitmentSearchPerformed && (
              <StaggeredFitmentInfo
                tire={tire}
                navigateToProductDetailsPage={navigateToProductDetailsPage}
              />
            )}
            <SearchResultsPromotionCard
              tire={tire}
              classes="ta-pt-4 md:ta-justify-center lg:ta-justify-start"
            />
          </div>

          <div className="md:ta-mt-3 lg:ta-mt-0 lg:ta-ml-auto lg:ta-text-right">
            <div className="ta-text-sm">
              Availability:{" "}
              <span className="ta-font-bold">
                {InStock ? "In Stock" : "Not In Stock"}
              </span>
            </div>

            <div className="ta-mt-3">
              <div className="ta-text-2xl ta-font-bold">
                {!fitmentSearchPerformed && (
                  <div>${priceFormatter(tire._finalPrice)} ea.</div>
                )}
                {fitmentSearchPerformed && (
                  <div>
                    F: ${priceFormatter(tire._finalPrice)} ea.
                    <br />
                    R: ${priceFormatter(tire?._rearFinalPrice as number)} ea.
                  </div>
                )}
              </div>
              <div className="ta-text-sm">
                {!fitmentSearchPerformed && (
                  <>
                    {tire.InstallationText
                      ? tire.InstallationText
                      : `Installed: $
            ${priceFormatter(tire.InstallPrice + tire._finalPrice)}`}
                  </>
                )}
                {fitmentSearchPerformed && (
                  <>
                    Front Installed : $
                    {priceFormatter(tire.InstallPrice + tire._finalPrice)}
                    <br />
                    {tire._rearFinalPrice && (
                      <>
                        Rear Installed : $
                        {priceFormatter(
                          tire.InstallPrice + tire._rearFinalPrice
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="ta-mt-4 ta-flex ta-gap-[8px] lg:ta-block">
              <div className="ta-flex-grow">
                {tire.CanAddToCart && (
                  <>
                    <label
                      htmlFor={`quantity-${index}` + tire.TireId}
                      className="ta-sr-only"
                    >
                      Select quantity
                    </label>
                    <select
                      id={`quantity-${index}` + tire.TireId}
                      value={tireQuantity}
                      onChange={onQuantityChange}
                      data-id={tire.TireId}
                      className="ta-border ta-border-[#909090] ta-rounded-[2px] ta-w-[25%] ta-text-center lg:ta-mt-2.5 lg:ta-w-[185px] lg:ta-h-[40px]"
                    >
                      {quantityList.map((quantity) => (
                        <option key={uuid()} value={quantity}>
                          {quantity}
                        </option>
                      ))}
                    </select>
                    <br />
                    <CustomIconButton
                      text={"Add To Cart"}
                      onClick={addToCartClick}
                      classes=" ta-max-w-full ta-rounded-[3px] ta-w-full ta-p-2.5 ta-bg-button ta-font-bold ta-text-sm ta-text-white lg:ta-w-[185px] lg:ta-mt-2"
                    />
                  </>
                )}
                {!tire.CanAddToCart && tire.OutOfStockText !== "" && (
                  <span className="ta-block ta-max-w-full ta-rounded-[3px] ta-w-full ta-py-2.5 ta-font-bold ta-text-sm lg:ta-w-[185px] lg:ta-mt-2 ta-bg-none">
                    {tire.OutOfStockText}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
