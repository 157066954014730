import { SiteHeader } from "components/global/siteHeader";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ReactFocusLock from "react-focus-lock";
import useFocus from "hooks/useFocus";
interface Props {
  classes?: string;
  handleClose: () => void;
  modalContent: React.ReactNode;
  show: boolean;
  showCart: boolean;
  showClose: boolean;
  showSiteHeader: boolean;
  title: string;
  withoutLocation?: boolean;
}
export const Modal = ({
  title,
  modalContent,
  show,
  handleClose,
  showSiteHeader,
  showCart,
  showClose,
  classes,
  withoutLocation,
}: Props) => {
  const showHideClassName = show
    ? "modal fade display-block"
    : "modal fade display-none";
  const [dialogRef, setFocus] = useFocus();
  const location = useLocation();
  const divRef = useRef<any>(null);
  useEffect(() => {
    if (dialogRef) {
      setFocus();
      const divRef = dialogRef.current;

      if (divRef && typeof divRef.scrollTo === "function") {
        divRef.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        divRef.scrollTop = 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <ReactFocusLock>
      <div
        className={`ta-fixed ta-top-0 ta-left-0 ta-z-[99] ta-flex ta-flex-col ta-items-center ta-w-full ta-h-full ta-p-4 ta-bg-quaternary/90 ta-text-left ${showHideClassName}`}
        role="dialog"
        tabIndex={-1}
        title={title}
        ref={dialogRef}
      >
        <div
          ref={divRef}
          className={`ta-relative ta-m-auto ta-w-full ta-p-4 ta-rounded-[5px] ta-shadow-md ta-bg-white md:ta-p-10 lg:ta-w-[1400px] lg:ta-max-w-full ta-overflow-y-auto ${classes}`}
          role="document"
        >
          <div className="">
            <div className="modal-header">
              {showSiteHeader ? (
                <SiteHeader
                  showCart={showCart}
                  withoutLocation={withoutLocation}
                />
              ) : null}
              {showClose ? (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                  data-testid="close-modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              ) : null}
            </div>
            <div className="modal-body">{modalContent}</div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    </ReactFocusLock>
  );
};
