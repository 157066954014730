export const mapPriceRange = (value: string) => {
  const targetValue = value.split(" - ");
  return {
    MinPrice: parseInt(targetValue[0]),
    MaxPrice: parseInt(targetValue[1]),
  };
};

export const mapWarrantyRange = (value: string) => {
  const targetValue = value.split(" - ");
  return {
    MinMiles: parseInt(targetValue[0]),
    MaxMiles: parseInt(targetValue[1]),
  };
};
