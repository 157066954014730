import { Input } from "components/input/input";
import { useState, useEffect, ChangeEvent } from "react";
import { FormSignIn, WishlistCustomer } from "types/wishlist";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { patterns } from "constants/index";
import { formValidator } from "helpers/formValidator";

interface Form extends FormSignIn {}

const defaultState: Form = {
  email: "",
  zipCode: "",
};
const error: Form = {
  ...defaultState,
};

const labels = {
  email: "Email Address",
  zipCode: "Zip Code",
};

interface Props {
  submitFormForWishlistCreation: (customer: WishlistCustomer) => void;
  signIn?: boolean;
}

export const WishlistSignIn = ({
  submitFormForWishlistCreation,
  signIn,
}: Props) => {
  // State to manage wishlist Sign In form
  const [form, setForm] = useState<Form>(defaultState);
  // State to manage form errors
  const [errors, setError] = useState<Form>(error);
  // State to manage Sign In button active or inactive
  const [inActive, setInActive] = useState<boolean>(true);

  let isError = false;

  useEffect(() => {
    // If email & zip code were entered by user, set Sign In button active, otherwise inactive
    if (formValidator<Form>(form, errors)) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [form]);

  const onChange = (
    e: ChangeEvent<HTMLInputElement> | InputMaskChangeEvent
  ) => {
    const name = e.target.name as keyof Form;
    const value = e.target.value;
    const pattern = e.target.pattern;
    // If value for wishlist input is not set, show user error that field is required
    if (!value || value === "") {
      setError((prevState: Form) => ({
        ...prevState,
        [name]: `${labels[name]} is Rquired`,
      }));
      isError = true;
    }
    // If input field has pattern(regex) set to it, check input matches pattern
    else if (pattern) {
      const regex = new RegExp(pattern);
      // If user input doesn't match pattern show error
      if (!regex.test(value)) {
        setError((prevState: Form) => ({
          ...prevState,
          [name]: `Invalid ${labels[name]}`,
        }));
        isError = true;
      }
    }
    // reset error object
    if (errors[name] !== "" && !isError) {
      setError((prevState: Form) => ({
        ...prevState,
        [name]: "",
      }));
    }
    // Set user input to form state
    setForm((prevState: Form) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitFormToCreateWishlist = () => {
    // Sign In click triggers this handler to create user wishlist
    const customer: WishlistCustomer = {
      AlreadyCustomer: false,
      FirstName: "",
      LastName: "",
      Email: form.email,
      HaveInstallerContact: true,
      Phone: "",
      PostalCode: form.zipCode,
    };
    submitFormForWishlistCreation(customer);
  };
  return (
    <div>
      <h1 className="ta-text-2xl ta-text-black ta-font-bold ta-mb-10">
        My Wish List
      </h1>
      <p className="ta-text-sm ta-font-bold ta-text-black ta-mb-3">
        Fields with an asterisk are required.
      </p>
      <form className="ta-text-base" title="Wish List Sign In">
        <Input
          name="email"
          type="email"
          onChange={onChange}
          label={labels.email}
          value={form.email}
          errorText={errors.email}
          pattern={patterns.email}
          required
          placeholder="Email"
          showFocus={signIn}
          classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-mb-4"
        />

        <div className="ta-form-group ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-grid-rows-2">
          <label
            htmlFor="taWishListSignInZipcode"
            className="ta-font-bold ta-text-sm ta-text-black"
          >
            {labels.zipCode}:*
          </label>

          <InputMask
            id="taWishListSignInZipcode"
            onChange={onChange}
            mask={"99999"}
            value={form.zipCode}
            name={"zipCode"}
            required
            placeholder="Zip Code"
            className="ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-h-[33px] ta-px-3 ta-w-full"
          />
          {errors.zipCode !== "" ? (
            <p className="ta-col-start-2 ta-mt-2" style={{ color: "darkred" }}>
              {errors.zipCode}
            </p>
          ) : (
            ""
          )}
        </div>

        <div className="ta-grid ta-grid-cols-wishListInputGrid">
          <div className="ta-col-start-2 ta-mt-4">
            <button
              type="button"
              disabled={inActive}
              onClick={submitFormToCreateWishlist}
              className="disabled:ta-bg-white disabled:ta-text-button ta-p-1.5 ta-border ta-border-button ta-border-2 ta-rounded-[5px] ta-bg-button ta-font-bold ta-text-white ta-text-2xl ta-w-full"
            >
              Sign In
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
