import { WishlistDetails } from "components/wishlistDetails/wishlistDetails";
import { useEcommerceContext } from "context/ecommerce";
import { WishlistDetail, Details } from "types/wishlist";
import { OrderDetails } from "types/cart";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { OrderTotalsCard } from "components/orderTotalsCard/orderTotalsCard";
import { useDealerContext } from "context/dealer";
import { useNavigate, useLocation } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { BackButton } from "components/backButton/backButton";
import { backToSearchResultsText } from "constants/index";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { mapWishlistTotalsWithTotalsCards } from "helpers/mapWishlistTotalsWithTotalsCards";

export const WishlistPage = () => {
  const {
    wishlistDetails,
    wishlistsAndOrders,
    buildCartFromWishlist,
    removeFromWishlist,
  } = useEcommerceContext();
  const { selectedDealerLocation } = useDealerContext();
  const navigate = useNavigate();
  const { loading, load } = useBusyIndicator();
  let backTo = backToSearchResultsText;
  let routeTo = TiresAnytimeRoutes.Search;
  const { state } = useLocation();
  if (state) {
    // Router state to handle back button navigation
    // If wishlist is opened from finder page, back button is to finder page
    // Otherwise to search results
    const { backToText, backToRoute } = state;
    backTo = backToText;
    routeTo = backToRoute;
  }
  const componentRef = useRef(null);
  const navigateToCartPage = async () => {
    load();
    await buildCartFromWishlist(wishlistDetails?.OrderId as number);
    navigate(TiresAnytimeRoutes.Cart);
  };

  const clearWishlist = () => {
    removeFromWishlist(
      selectedDealerLocation?.DealerId as number,
      wishlistDetails?.OrderId as number
    );
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="ta-wishlist-summary" ref={componentRef}>
      <h1 className="ta-text-2xl ta-font-bold ta-text-black">
        Wish List Summary
      </h1>
      <div className="ta-flex ta-mb-5 ta-items-end ta-text-base lg:ta-items-center">
        <div className="ta-mr-auto lg:ta-flex lg-ta-items-center lg:ta-w-[74%]">
          <BackButton
            text={backTo}
            navigation={routeTo}
            classes="ta-print-none"
          />

          <div className="ta-mx-auto ta-text-sm ta-mb-7 lg:ta-mb-0">
            {wishlistDetails?.ContactEmail}
          </div>

          <div className="ta-ml-auto ta-text-sm ta-mb-2 lg:ta-mb-0">
            Expires: {wishlistsAndOrders?.ExpirationDate}
          </div>
        </div>
        <div>
          <CustomIconButton
            classes={
              "ta-print-none ta-flex ta-items-center ta-justify-center ta-bg-button ta-text-white ta-font-bold ta-text-sm ta-border ta-border-button ta-rounded-[3px] ta-p-1.5 ta-pl-6 ta-pr-6 ta-ml-2 hover:ta-underline"
            }
            text={"Print"}
            children={
              <svg
                className="ta-w-[12px] ta-h-[12px] ta-fill-current ta-mr-2"
                viewBox="0 0 26 28"
              >
                <path d="M6 24h14v-4h-14v4zM6 14h14v-6h-2.5c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-10v10zM24 15c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM26 15v6.5c0 0.266-0.234 0.5-0.5 0.5h-3.5v2.5c0 0.828-0.672 1.5-1.5 1.5h-15c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-3.5c-0.266 0-0.5-0.234-0.5-0.5v-6.5c0-1.641 1.359-3 3-3h1v-8.5c0-0.828 0.672-1.5 1.5-1.5h10.5c0.828 0 1.969 0.469 2.562 1.062l2.375 2.375c0.594 0.594 1.062 1.734 1.062 2.562v4h1c1.641 0 3 1.359 3 3z"></path>
              </svg>
            }
            onClick={handlePrint}
          />
        </div>
      </div>
      <div className="xl:ta-grid xl:ta-grid-cols-cartGrid xl:ta-gap-x-3 xl:ta-gap-y-7">
        <div className="ta-border ta-border-black ta-shadow-md">
          <WishlistDetails
            wishlist={mapWishlistToProductDetailsGrid(wishlistDetails)}
            installAmt={wishlistDetails?.InstallAmt}
          />
        </div>

        <div className="ta-print-none ta-border ta-border-black ta-shadow-md ta-mt-5 ta-pl-2.5 ta-pr-2.5 ta-pt-10 ta-pb-5 xl:ta-mt-0">
          <OrderTotalsCard
            orderTotals={mapWishlistTotalsWithTotalsCards(wishlistDetails)}
          >
            <CustomIconButton
              text={"Add to Cart"}
              onClick={navigateToCartPage}
              classes="ta-add-to-cart ta-border ta-border-1 ta-rounded-[3px] ta-w-full ta-p-1.5 ta-mt-4 ta-font-bold ta-text-2xl ta-bg-button ta-text-white"
            />
            <CustomIconButton
              text={"Clear Wish List"}
              onClick={clearWishlist}
              classes="ta-clear-cart ta-border ta-border-1 ta-rounded-[3px] ta-w-full ta-mt-2 ta-p-1.5 ta-font-bold ta-text-lg ta-text-button hover:ta-bg-button hover:ta-text-white"
            />
          </OrderTotalsCard>
        </div>
        <p className="ta-text-sm">Prices and Availability subject to change.</p>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};

const mapWishlistToProductDetailsGrid = (
  wishlistDetail: WishlistDetail | null | undefined
): OrderDetails[] => {
  const wishlist =
    wishlistDetail && wishlistDetail.Details
      ? wishlistDetail.Details.map((item: Details): OrderDetails => {
          return {
            DisplayPrice: item.Price,
            ImageUrl: item.TireImageUrl,
            TireSize: item.TireSize,
            PartNumber: item.PartNumber,
            ProductName: item.Description,
            ManufacturerName: item.Brand.split(",")[0],
            Quantity: item.Quantity,
            IsWarranty: item.IsWarranty,
            WarrantyDescription: item.WarrantyDescription,
            WarrantyImageUrl: item.WarrantyImageUrl,
            WarrantyPrice: item.WarrantyPrice,
            IsQuoteAddOn: item.IsQuoteAddOn,
            AddOnId: item.AddOnId,
            AddOnPrice: item.Price,
            AddOnDescription: item.Description,
            Brand: item.Brand,
            WarrantyText: item.WarrantyText,
            Price: item.Price,
            IsAddOnPriceRequired: item.IsAddOnPriceRequired ? true : false,
          };
        })
      : ([] as OrderDetails[]);
  return wishlist;
};
