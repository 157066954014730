import { useCallback, useEffect } from "react";

import { BackButton } from "components/backButton/backButton";
import { CompareCard } from "components/compareCard/compareCard";
import { PowerReviewsRenderConfig } from "types/powerreviews";
import { Tire } from "types/tire";
import { TiresAnytimeRoutes } from "routes";
import { backToSearchResultsText } from "constants/index";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { usePowerReviews } from "hooks/usePowerReviews";
import { useSearchContext } from "context/search";

export const ComparePage = () => {
  const { comparedTires, clearComparedTires } = useSearchContext();
  const { ready } = usePowerReviews();
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const navigate = useNavigate();
  // If user wants to leave compare page, clear compared tires from search context state
  // And navigate to search resutls
  const onLeavePageClick = () => {
    clearComparedTires();
    navigate(TiresAnytimeRoutes.Search);
  };

  useEffect(() => {
    // after our tires are updated on UX... call power reviews render function
    if (ready && comparedTires && comparedTires.length > 0) {
      const config: PowerReviewsRenderConfig = {
        api_key: process.env.REACT_APP_POWERREVIEWS_APIKEY!,
        merchant_group_id: process.env.REACT_APP_POWERREVIEWS_MERCHANTGROUPID!,
        merchant_id: process.env.REACT_APP_POWERREVIEWS_MERCHANTID!,
      };

      render(comparedTires, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedTires, ready]);

  const render = useCallback(
    debounce((tires: Tire[], config: PowerReviewsRenderConfig) => {
      const configPayload = {
        api_key: config.api_key!,
        locale: `en_US`,
        merchant_group_id: config.merchant_group_id!,
        merchant_id: config.merchant_id!,
      };

      let payload = tires.map((t) => {
        return {
          ...configPayload,
          page_id: t.PageId.toString(),
          components: {
            CategorySnippet: `pr-cmp-${t.PageId}-${t.ManufacturerPartNumber}`,
          },
        };
      });

      pwr("render", payload);
    }, 200),
    []
  );

  return (
    <div className="ta-text-base ta-compare-overlay">
      <div className="ta-compare-overlay-header ta-grid ta-grid-flow-row ta-mb-4 ta-text-center md:ta-grid-cols-3 md:ta-justify-between">
        <div className="ta-text-center ta-align-self-center ta-justify-self-center md:ta-justify-self-start">
          <BackButton
            text={backToSearchResultsText}
            navigation={TiresAnytimeRoutes.Search}
            onClick={onLeavePageClick}
          />
        </div>

        <h2 className="ta-text-sm !ta-bg-transparent ta-tracking-normal ta-mt-5 ta-mb-4 md:ta-my-0 md:ta-mx-auto">
          {new Date().toLocaleDateString("en-US", options)}
        </h2>

        <div className="ta-justify-self-stretch ta-align-self-stretch ta-w-full md:ta-text-right">
          <button
            type="button"
            className="ta-inline-flex ta-w-full ta-items-center ta-justify-center ta-bg-button ta-text-sm ta-text-white ta-font-bold ta-py-1.5 ta-px-6 ta-rounded-[3px] md:ta-w-auto"
            onClick={window.print}
          >
            <svg
              className="ta-w-[12px] ta-h-[12px] ta-fill-current ta-mr-2"
              viewBox="0 0 26 28"
            >
              <path d="M6 24h14v-4h-14v4zM6 14h14v-6h-2.5c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-10v10zM24 15c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM26 15v6.5c0 0.266-0.234 0.5-0.5 0.5h-3.5v2.5c0 0.828-0.672 1.5-1.5 1.5h-15c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-3.5c-0.266 0-0.5-0.234-0.5-0.5v-6.5c0-1.641 1.359-3 3-3h1v-8.5c0-0.828 0.672-1.5 1.5-1.5h10.5c0.828 0 1.969 0.469 2.562 1.062l2.375 2.375c0.594 0.594 1.062 1.734 1.062 2.562v4h1c1.641 0 3 1.359 3 3z"></path>
            </svg>
            Print
          </button>
        </div>
      </div>
      <div className="ta-compare-overlay-items ta-grid ta-grid-flow-row ta-gap-2 sm:ta-grid-cols-3">
        {comparedTires.map((tire: Tire) => {
          return <CompareCard tire={tire} key={tire.TireId} />;
        })}
      </div>
    </div>
  );
};
