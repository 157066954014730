import { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { SearchByVehicleTabContent } from "components/searchByVehicleTabContent/searchByVehicleTabContent";
import { SearchByTireSizeTabContent } from "components/searchByTireSizeTabContent/searchByTireSizeTabContent";
import { SelectOptions } from "types/search";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { SearchByLicensePlateTabContent } from "components/searchByLicensePlateTabContent/searchByLicensePlateTabContent";
import { useDealerContext } from "context/dealer";
import { useSearchContext } from "context/search";
import { SearchTabFooterButtons } from "components/searchTabFooterButtons/searchTabFooterButtons";

export const FinderPage = () => {
  const { loading, load, unload } = useBusyIndicator();
  const { dealerStyling } = useDealerContext();
  const {
    clearAllFilters,
    resetRatioAndRims,
    resetManufacturerAndModelsAndOptions,
  } = useSearchContext();

  useEffect(() => {
    // clean up user actions
    // clear filers set for search results
    clearAllFilters();
    // clear search by tire size selected options
    resetRatioAndRims();
    // clear search by vehilce selected options
    resetManufacturerAndModelsAndOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`ta-border ta-border-[#6E6E6E] ta-rounded-[3px] ta-shadow-md ta-text-base ${
        dealerStyling?.Shape === "square" ? "ta-max-w-3xl ta-m-auto" : ""
      }${dealerStyling && dealerStyling.Shape ? "dealerStylingComplete" : ""}`}
    >
      <h2 className="!ta-bg-header ta-text-white ta-font-bold ta-p-3.5 ta-text-lg ta-text-center ta-rounded-tr-[3px] ta-rounded-tl-[3px] ta-uppercase md:ta-text-2xl">
        Shop for Tires Anytime!
      </h2>
      <Tabs>
        <TabList className="ta-flex ta-items-center ta-w-100">
          <Tab
            className="ta-flex-grow ta-p-4 !ta-m-0 ta-font-bold ta-bg-darkgrey2 ta-text-center ta-text-white ta-text-base md:ta-px-8 md:ta-py-4 md:ta-text-xl ta-cursor-pointer"
            selectedClassName="ta-rounded-tr-[5px] ta-bg-lightgrey tab-active"
          >
            By Vehicle
          </Tab>
          <Tab
            className="ta-flex-grow ta-p-4 !ta-m-0 ta-font-bold ta-bg-darkgrey2 ta-text-center ta-text-white ta-text-base md:ta-px-8 md:ta-py-4 md:ta-text-xl ta-cursor-pointer"
            selectedClassName="ta-rounded-tr-[5px] ta-bg-lightgrey ta-rounded-tl-[5px] tab-active"
          >
            By Tire Size
          </Tab>
          <Tab
            className="ta-flex-grow ta-p-4 !ta-m-0 ta-font-bold ta-bg-darkgrey2 ta-text-center ta-text-white ta-text-base md:ta-px-8 md:ta-py-4 md:ta-text-xl ta-cursor-pointer"
            selectedClassName="ta-rounded-tl-[5px] ta-bg-lightgrey tab-active"
          >
            By License Plate
          </Tab>
        </TabList>
        <TabPanel
          className={`${
            dealerStyling?.Style === "transparent"
              ? "ta-bg-lightgrey"
              : "ta-bg-lightgrey/50"
          }`}
        >
          <SearchByVehicleTabContent load={load} unload={unload} />
        </TabPanel>
        <TabPanel
          className={`${
            dealerStyling?.Style === "transparent"
              ? "ta-bg-lightgrey"
              : "ta-bg-lightgrey/50"
          }`}
        >
          <SearchByTireSizeTabContent load={load} unload={unload} />
        </TabPanel>
        <TabPanel
          className={`${
            dealerStyling?.Style === "transparent"
              ? "ta-bg-lightgrey"
              : "ta-bg-lightgrey/50"
          }`}
        >
          <SearchByLicensePlateTabContent load={load} unload={unload} />
        </TabPanel>
      </Tabs>
      <SearchTabFooterButtons />
      <BusyIndicator loading={loading} />
    </div>
  );
};

// Map drop down data for Search By Vehicle to {label: value, value: value} object
export const mapObjecToSelectionOptions = (obj: any[]) => {
  const keys: string[] = Object.keys(obj[0]);
  const label = keys[0],
    value = keys[0];
  const options: SelectOptions[] = [];
  for (let i = 0; i < obj.length; i++) {
    options.push({ label: obj[i][label], value: obj[i][value] });
  }
  return options;
};
