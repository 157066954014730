import { Order, WishListSummary } from "types/wishlist";
import { priceFormatter } from "helpers/pricing";
import { useEcommerceContext } from "context/ecommerce";
import { useNavigate } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { back } from "constants/index";

interface Props {
  wishlistSummary: WishListSummary;
}

export const UserWishlistOrderCard = ({ wishlistSummary }: Props) => {
  const { getWishlistDetails } = useEcommerceContext();
  const navigate = useNavigate();
  const onWishlistOrdersCard = async (e: any) => {
    // Get order id, call getWishlistDetails api & navigate to wishlist page
    const orderId = parseInt(e.target.attributes["data-id"].value);
    await getWishlistDetails(orderId);
    // Set router state for wishlist page to come back to this page
    navigate(TiresAnytimeRoutes.Wishlist, {
      state: {
        backToText: back,
        backToRoute: TiresAnytimeRoutes.UserWishlist,
      },
    });
  };
  return (
    <div>
      <h3 className="ta-font-bold ta-text-2xl ta-my-3">My Orders</h3>
      <div className="ta-text-base ta-border ta-shadow-md">
        <table className="ta-w-full ta-block lg:ta-table">
          <thead className="ta-w-full ta-block lg:ta-table-row-group">
            <tr className="ta-w-full ta-block ta-bg-header ta-text-white ta-p-4 lg:ta-table-row lg:ta-p-0">
              <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto">
                Tires Installed at
              </th>
              <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto">
                Order #
              </th>
              <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto lg:ta-text-center">
                Order Date
              </th>
              <th className="ta-p-2 ta-hidden ta-w-full lg:ta-table-cell lg:ta-w-auto">
                Total
              </th>
            </tr>
          </thead>
          <tbody className="ta-w-full ta-block lg:ta-table-row-group">
            {wishlistSummary.Orders &&
              wishlistSummary.Orders.map((order: Order) => (
                <tr
                  className="ta-border-b ta-block ta-w-full lg:ta-table-row"
                  key={order.Id}
                >
                  <td className="ta-block ta-w-full ta-p-2 lg:ta-table-cell lg:ta-w-auto">
                    <div className="ta-mb-2">{order.DealerName}</div>
                  </td>
                  <td className="ta-p-2 ta-block ta-w-full lg:ta-table-cell lg:ta-w-auto">
                    <button
                      type="button"
                      data-id={order.Id}
                      onClick={onWishlistOrdersCard}
                      className="ta-text-secondary hover:ta-underline"
                    >
                      {order.OrderNumber}
                    </button>
                  </td>
                  <td className="ta-block lg:ta-text-center ta-w-full ta-p-2 lg:ta-table-cell lg:ta-w-auto">
                    {order.OrderDate}
                  </td>
                  <td className="ta-font-bold ta-block ta-w-full ta-p-2 lg:ta-table-cell lg:ta-w-auto">
                    ${priceFormatter(order.Total)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
