import { useEcommerceContext } from "context/ecommerce";
import { UserWishlistListCard } from "components/userWishlistListCard/userWishlistListCard";
import { WishListSummary } from "types/wishlist";
import { UserWishlistOrderCard } from "components/userWishlistOrderCard/userWishlistOrderCard";
import { BackButton } from "components/backButton/backButton";
import { TiresAnytimeRoutes } from "routes";
import { backToFinder } from "constants/index";

export const UserWishlistsPage = () => {
  const { wishlistsAndOrders } = useEcommerceContext();
  return (
    <div>
      <div className="ta-mr-auto">
        <BackButton text={backToFinder} navigation={TiresAnytimeRoutes.Home} />
      </div>
      <div>
        <h3 className="ta-text-2xl ta-font-bold ta-my-4">My Wish Lists</h3>
        <p className="ta-text-sm ta-mb-2">
          Prices and Availability subject to change
        </p>
      </div>
      <UserWishlistListCard
        wishlistSummary={wishlistsAndOrders as WishListSummary}
      />

      <div className="ta-my-3">
        <UserWishlistOrderCard
          wishlistSummary={wishlistsAndOrders as WishListSummary}
        />
      </div>
    </div>
  );
};
