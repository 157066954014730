import { SearchParams, Option } from "types/search";

export const mapOptionsToSearchParams = (option: Option): SearchParams => {
  return {
    Make: option.AutoMake,
    Model: option.AutoModel,
    Year: option.AutoYear,
    Option: option.AutoOption as string,
    Width: option.SecWidth as number,
    AspRatio: option.AspRatio as number,
    Rim: option.Rim,
    LoadRating: option.LoadIndex as string,
    SpeedRating: option.SpeedRate as string,
    Width2: option.RearSecWidth as number,
    AspRatio2: option.RearAspRatio as number,
    Rim2: option.RearRim as number,
    LoadRating2: option.RearLoadIndex as string,
    VIN: null,
    TireSize: option.TireSizeField as string,
  } as SearchParams;
};
