import { ChangeEvent, useRef } from "react";
import { redBorder } from "constants/index";
import { convertDateToMMDDYYYY } from "helpers/timeAndDateConverter";
interface Props {
  dateRef?: any;
  name: string;
  value: string;
  errorText: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  isActive: boolean;
  id?: string;
  label?: string;
  ariaLabel?: string;
}
export const DatePicker = ({
  dateRef,
  name,
  value,
  onChange,
  placeholder,
  required,
  isActive = true,
  id,
  label,
  ariaLabel,
}: Props) => {
  let displayValue = "";
  if (value && value !== "") {
    displayValue = convertDateToMMDDYYYY(value);
  }
  const inputRef = useRef<HTMLInputElement>(null);
  function handleClick() {
    inputRef?.current?.showPicker();
  }

  return (
    <div className={`ta-form-group ta-w-full`}>
      <div className="ta-sr-only" role="status">
        {displayValue ? `Selected date: ${displayValue}` : ""}
      </div>
      {label && (
        <label
          htmlFor={id}
          className="ta-text-sm ta-mb-1 ta-block ta-font-bold ta-text-black"
          aria-label={ariaLabel ? ariaLabel : label}
        >
          {label}
          {required && <span className="ta-text-red">*</span>}
        </label>
      )}
      <div
        className={`ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-h-[33px] ta-p-2 ta-w-full ta-flex ta-items-center ${
          required && !value ? redBorder : ""
        }`}
      >
        <input
          disabled={!isActive}
          className="ta-text-[0.67rem] ta-w-full"
          type="text"
          name={name}
          value={displayValue}
          placeholder={placeholder}
          readOnly
        />
        <button
          onClick={handleClick}
          disabled={!isActive}
          type="button"
          className="ta-relative calendar-button"
          ref={dateRef}
        >
          <input
            disabled={!isActive}
            className="ta-opacity-0 calendar-input"
            type="date"
            name={name}
            onChange={onChange}
            value={value}
            style={{ width: "24px" }}
            min={tomorrow()}
            ref={inputRef}
            id={id}
            tabIndex={-1}
          />
          <svg
            className="ta-absolute ta-top-[0.5rem] ta-right-0 ta-w-3 ta-h-3"
            xmlns="http://www.w3.org/2000/svg"
            width="10.5"
            height="12"
            viewBox="0 0 10.5 12"
            style={{ pointerEvents: "none" }}
            aria-hidden="true"
          >
            <path
              id="Icon_awesome-calendar-day"
              data-name="Icon awesome-calendar-day"
              d="M0,10.875A1.125,1.125,0,0,0,1.125,12h8.25A1.125,1.125,0,0,0,10.5,10.875V4.5H0Zm1.5-4.5A.376.376,0,0,1,1.875,6h2.25a.376.376,0,0,1,.375.375v2.25A.376.376,0,0,1,4.125,9H1.875A.376.376,0,0,1,1.5,8.625ZM9.375,1.5H8.25V.375A.376.376,0,0,0,7.875,0h-.75A.376.376,0,0,0,6.75.375V1.5h-3V.375A.376.376,0,0,0,3.375,0h-.75A.376.376,0,0,0,2.25.375V1.5H1.125A1.125,1.125,0,0,0,0,2.625V3.75H10.5V2.625A1.125,1.125,0,0,0,9.375,1.5Z"
              fill="#707070"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

// Date selection for appointments starts tomorrow
const tomorrow = (): string => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date.toISOString().split("T")[0];
};
