import { addPrecision, removePrecision } from "helpers/precisionHelper";
import { warrantyFormatter } from "helpers/warrantyFormatter";
import { Tire } from "types/tire";

interface Props {
  tire: Tire;
}
export const StaggeredFitmentInfo = ({ tire }: Props) => {
  return (
    <>
      <div className="ta-pt-2 ta-pb-4 ta-px-1.5 ta-text-sm ta-text-center md:ta-text-left md:ta-py-2">
        <div className="ta-flex ta-gap-1.5">
          <div>Item#:</div>
          <div>
            F: {tire.ManufacturerPartNumber}
            <br />
            R: {tire.RearManufacturerPartNumber}
          </div>
        </div>
      </div>
      <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm ta-bg-[#D3D3D3]">
        <div className="ta-flex ta-gap-1.5">
          <div>Tire Size:</div>
          <div className="ta-font-bold">
            F: {tire.DisplayTireSize}
            <br />
            R: {tire.RearDisplayTireSize}
          </div>
        </div>
        <div className="ta-flex ta-gap-1.5">
          <div>Mileage Warranty:</div>
          <div className="ta-font-bold">
            F: {warrantyFormatter(tire.Warranty)}
            {tire.RearWarranty && (
              <>
                <br />
                R: {warrantyFormatter(tire.RearWarranty)}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm">
        <div className="ta-flex ta-gap-1.5">
          Load Rating:{" "}
          <div className="ta-font-bold">
            F: {removePrecision(tire.LoadRating)}
            {tire.RearLoadRating && (
              <>
                <br />
                R: {removePrecision(tire.RearLoadRating)}
              </>
            )}
          </div>
        </div>
        <div className="ta-flex ta-gap-1.5">
          Speed Rating:{" "}
          <div className="ta-font-bold">
            F: {tire.SpeedRating}
            {tire.RearSpeedRating && (
              <>
                <br />
                R: {tire.RearSpeedRating}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm ta-bg-[#D3D3D3]">
        <div className="ta-flex ta-gap-1.5">
          <div>Load Ply:</div>
          <div className="ta-font-bold">
            F: {tire.PlyRating}
            <br />
            R: {tire.RearPlyRating}
          </div>
        </div>
        <div className="ta-flex ta-gap-1.5">
          <div>Sidewall:</div>
          <div className="ta-font-bold">
            F: {tire.SideWall}
            <br />
            R: {tire.RearSideWall}
          </div>
        </div>
      </div>
      <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm">
        <div className="ta-flex ta-gap-1.5">
          <div>Load Capacity:</div>
          <div className="ta-font-bold">
            F: {tire.LoadCapacity} lbs.
            <br />
            R: {tire.RearLoadCapacity} lbs.
          </div>
        </div>
        <div className="ta-flex ta-gap-1.5">
          <div>UTQG:</div>
          <div className="ta-font-bold">
            F: {tire.UTQG}
            <br />
            R: {tire.RearUTQG}
          </div>
        </div>
      </div>
      <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-items-center ta-text-sm ta-bg-[#D3D3D3]">
        <div className="ta-flex ta-gap-1.5">
          <div>Maximum PSI:</div>
          <div className="ta-font-bold">
            F: {tire.MaxTirePressure}
            <br />
            R: {tire.RearMaxTirePressure}
          </div>
        </div>
        <div className="ta-flex ta-gap-1.5">
          <div>Tread Depth:</div>
          <div className="ta-font-bold">
            F: {addPrecision(tire.TreadDepth)}
            <br />
            R: {tire.RearTreadDepth ? addPrecision(tire.RearTreadDepth) : "N/A"}
          </div>
        </div>
      </div>
      <div className="ta-py-0.5 ta-px-1.5 ta-grid ta-grid-cols-2 ta-text-sm">
        <div className="ta-flex ta-gap-1.5">
          <div>Overall Diameter:</div>
          <div className="ta-font-bold">{tire.OverallDiameter}"</div>
        </div>
        <div>
          Tire Weight: <span className="ta-font-bold">{tire.Weight} lbs.</span>
        </div>
      </div>
    </>
  );
};
