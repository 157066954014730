import { Input } from "components/input/input";
import { ChangeEvent, useEffect, useState } from "react";
import { WishlistCustomer, FormSignUp } from "types/wishlist";
import { patterns } from "constants/index";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { formValidator } from "helpers/formValidator";

interface Form extends FormSignUp {}

const defaultState: Form = {
  firstName: "",
  lastName: "",
  email: "",
  zipCode: "",
  phone: "",
};
const error: Form = {
  ...defaultState,
};

const labels: Form = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  zipCode: "Zip/Postal Code",
  phone: "Phone",
};

interface Props {
  submitFormForWishlistCreation: (customer: WishlistCustomer) => void;
  signIn: boolean;
}
export const WishlistSignupForm = ({
  submitFormForWishlistCreation,
  signIn,
}: Props) => {
  // State to manage wishlist Sign up form
  const [form, setForm] = useState<Form>(defaultState);
  // State to manage form errors
  const [errors, setError] = useState<Form>(error);
  // State whether to show phone field based on user checkbox selection
  const [showPhoneInput, setShowPhoneInput] = useState<boolean>(false);
  // State to manage Sign Up button active or inactive
  const [inActive, setInActive] = useState<boolean>(true);
  useEffect(() => {
    // If form fields have been entered by user set sign up button active, otherwise inactive
    if (
      formValidator<Form>(form, errors, !showPhoneInput ? ["phone"] : undefined)
    ) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [form, showPhoneInput]);

  const maskZipcode = (value: string) => {
    // Mask zipcode entry to match pattern 99999-9999
    if (value.length > 5 && value[5] !== "-") {
      value = `${value.substring(0, 5)}-${value.substring(5, value.length)}`;
      return value;
    }
    return value;
  };

  const handleZipCodeChange = (value: string) => {
    // Validate zipcode entry is only 10 characters, mask it, set value to state
    const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const valid = regex.test(value);
    if (value.length > 10) {
      return;
    }
    if (!valid) {
      setError((prevState: Form) => ({
        ...prevState,
        zipCode: `Invalid zipcode`,
      }));
    }
    const newVal = maskZipcode(value);
    setForm((prevState: Form) => ({
      ...prevState,
      zipCode: newVal,
    }));
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement> | InputMaskChangeEvent
  ) => {
    const name = e.target.name as keyof Form;
    const value = e.target.value;
    const pattern = e.target.pattern;
    let isError = false;
    // If value for wishlist input is not set, show user error that field is required
    if (!value || value === "") {
      setError((prevState: Form) => ({
        ...prevState,
        [name]: `${labels[name]} is Required`,
      }));
      isError = true;
    }
    // If input field has pattern(regex) set to it, check input matches pattern
    else if (pattern) {
      const regex = new RegExp(pattern);
      // If user input doesn't match pattern show error
      if (!regex.test(value)) {
        setError((prevState: Form) => ({
          ...prevState,
          [name]: `Invalid ${labels[name]}`,
        }));
        isError = true;
      }
    }
    // reset error object
    if (errors[name] !== "" && !isError) {
      setError((prevState: Form) => ({
        ...prevState,
        [name]: "",
      }));
    }
    // If input is for zipcode, call handler for it
    if (name === `zipCode` && value) {
      handleZipCodeChange(value);
      return;
    }
    // Set user input to form state
    setForm((prevState: Form) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitFormToCreateWishlist = () => {
    // Sign up click triggers this handler to create user wishlist
    const customer: WishlistCustomer = {
      AlreadyCustomer: false,
      FirstName: form.firstName,
      LastName: form.lastName,
      Email: form.email,
      HaveInstallerContact: showPhoneInput,
      Phone: form.phone,
      PostalCode: form.zipCode,
    };
    submitFormForWishlistCreation(customer);
  };

  return (
    <div className="ta-text-base">
      <h1 className="ta-text-2xl ta-text-black ta-font-bold ta-mb-10">
        Wish List Sign Up!
      </h1>
      <p className="ta-text-sm ta-font-bold ta-text-black ta-mb-3">
        Fields with an asterisk are required.
      </p>
      <form className="ta-mb-4">
        <Input
          name="firstName"
          id="taWishListSignupFirstName"
          type="text"
          autocomplete="given-name"
          onChange={onChange}
          label={labels.firstName}
          value={form.firstName}
          placeholder="First Name"
          errorText={errors.firstName}
          showFocus={!signIn}
          required
          classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
        />
        <Input
          name="lastName"
          id="taWishListSignupLastName"
          type="text"
          autocomplete="family-name"
          onChange={onChange}
          label={labels.lastName}
          value={form.lastName}
          placeholder="Last Name"
          errorText={errors.lastName}
          required
          classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
        />
        <Input
          name="email"
          id="taWishListSignupEmail"
          type="email"
          autocomplete="email"
          onChange={onChange}
          label={labels.email}
          value={form.email}
          placeholder="Email Address"
          errorText={errors.email}
          pattern={patterns.email}
          required
          classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
        />
        <Input
          name="zipCode"
          id="taWishListSignupZipcode"
          type="text"
          autocomplete="postal-code"
          onChange={onChange}
          label={labels.zipCode}
          value={form.zipCode}
          placeholder="Zip Code"
          errorText={errors.zipCode}
          required
          classes="ta-grid ta-grid-cols-wishListInputGrid ta-items-center ta-gap-4 ta-mb-4"
        />
        <div className="ta-grid ta-grid-cols-wishListInputGrid ta-mb-4">
          <div className="ta-col-start-2">
            <label>
              <input
                type="checkbox"
                onClick={() => setShowPhoneInput(!showPhoneInput)}
                className="ta-mr-1"
              />
              Please have installer contact me about my wish list
            </label>
          </div>
        </div>

        {showPhoneInput ? (
          <div className="ta-grid ta-grid-cols-wishListInputGrid ta-mb-4">
            <label
              htmlFor="taWishListSignUpPhone"
              className="ta-font-bold ta-text-black"
            >
              {labels.phone}:*
            </label>
            <InputMask
              id="taWishListSignUpPhone"
              onChange={onChange}
              autoComplete="tel"
              mask={"(999)999-9999"}
              value={form.phone}
              name={"phone"}
              required={showPhoneInput}
              className="ta-border ta-border-[#8f8f8f] ta-rounded-[3px] ta-h-[33px] ta-px-3"
            />
            {errors.phone !== "" ? (
              <div className="ta-col-start-2" style={{ color: "darkred" }}>
                {errors.phone}
              </div>
            ) : (
              ""
            )}{" "}
          </div>
        ) : (
          ""
        )}
        <div className="ta-grid ta-grid-cols-wishListInputGrid">
          <div className="ta-col-start-2">
            <button
              type="button"
              disabled={inActive}
              onClick={submitFormToCreateWishlist}
              className="ta-py-1.5 ta-border ta-border-button ta-border-2 ta-rounded-[5px] ta-bg-button ta-font-bold ta-text-white ta-w-full ta-text-2xl disabled:ta-bg-white disabled:ta-text-button"
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
