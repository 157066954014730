import {
  Cart,
  CartItem,
  IncludedItem,
  RemoveFromCartPayload,
} from "types/cart";
import {
  OrderInfo,
  WishListSummary,
  Wishlist,
  WishlistCustomer,
  WishlistDetail,
  WishlistResp,
} from "types/wishlist";
import { SearchOptions } from "types/search";
import { Tire } from "types/tire";
import React, { useContext, useState } from "react";
import { get, post } from "services/apiServices";
import { useAuth } from "./auth";
import { useDealerContext } from "./dealer";
import { uuid } from "helpers/uuid";
import { PayPalPlaceOrder, PlaceOrder } from "types/checkout";
import { getDisplayPrice } from "helpers/pricing";
import { Toaster } from "components/toaster/toaster";

export interface IEcommerce {
  cart?: Cart | null | undefined;
  storeOrderInfo?: PlaceOrder | null | undefined;
  payPalOrderInfo?: PayPalPlaceOrder | null | undefined;
  wishlistsAndOrders: WishListSummary | null | undefined;
  wishlistDetails: WishlistDetail | null | undefined;
}

export interface IEcommerceContext extends IEcommerce {
  addToCart: (tire: Tire, searchOptions: SearchOptions) => Promise<void>;
  updateCart: (cart: Cart, dealerLocation: number) => Promise<void>;
  updateUserCartItems: (tireId: number, quantity: number) => Promise<void>;
  clearCart: () => void;
  removeFromCart: (
    cartItem: CartItem | IncludedItem,
    tireId: number,
    isWarranty: boolean,
    isAddOn: boolean,
    isTireAddOn: boolean
  ) => void;
  removeTireFromCart: (tireId: number) => void;
  removeIncludedItemFromCart: (addOnId: number) => void;
  removeAddOnFromCart: (addOnId: number) => void;
  removeFromWishlist: (
    dealerLocationId: number,
    wishlistId: number
  ) => Promise<void>;
  removeWarrantyFromCart: (tireId: number) => void;
  createWishlist: (customer: WishlistCustomer) => Promise<void>;
  getWishlistDetails: (wishlistId: number) => Promise<void>;
  getWishlistOrdersForAccount: (
    email: string,
    zipCode: string
  ) => Promise<void>;
  placeOrder: (
    dealerLocationId: number,
    wishlistId: number | null,
    orderInfo: OrderInfo
  ) => Promise<PlaceOrder>;
  placePayPalOrder: (
    dealerLocationId: number,
    wishlistId: number | null,
    orderInfo: OrderInfo
  ) => Promise<PayPalPlaceOrder>;
  buildCartFromWishlist: (orderId: number) => Promise<void>;
  applyDiscountCode: (
    couponCode: string,
    customerInfo?: OrderInfo
  ) => Promise<void>;
}

const defaultState: IEcommerce = {
  cart: undefined,
  wishlistsAndOrders: undefined,
  wishlistDetails: undefined,
  storeOrderInfo: undefined,
  payPalOrderInfo: undefined,
};

const defaultContext: IEcommerceContext = {
  ...defaultState,
  addToCart: async (tire: Tire, searchOptions: SearchOptions) => {},
  updateCart: async (cart: Cart, dealerLocation: number) => {},
  updateUserCartItems: async (tireId: number, quantity: number) => {},
  clearCart: () => {},
  removeFromCart: (
    cartItem: CartItem | IncludedItem,
    tireId: number,
    isWarranty: boolean,
    isAddOn: boolean,
    isTireAddOn: boolean
  ) => {},
  removeTireFromCart: (tireId: number) => {},
  removeIncludedItemFromCart: (addOnId: number) => {},
  removeAddOnFromCart: (addOnId: number) => {},
  removeFromWishlist: async (
    dealerLocationId: number,
    wishlistId: number
  ) => {},
  removeWarrantyFromCart: (tireId: number) => {},
  createWishlist: async (customer: WishlistCustomer) => {},
  getWishlistDetails: async (orderId: number) => {},
  buildCartFromWishlist: async (orderId: number) => {},
  getWishlistOrdersForAccount: async (email: string, zipCode: string) => {},
  placeOrder: async (
    dealerLocationId: number,
    wishlistId: number | null,
    orderInfo: OrderInfo
  ) => {
    return {} as PlaceOrder;
  },
  placePayPalOrder: async (
    dealerLocationId: number,
    wishlistId: number | null,
    orderInfo: OrderInfo
  ) => {
    return {} as PayPalPlaceOrder;
  },
  applyDiscountCode: async (couponCode: string, customerInfo?: OrderInfo) => {},
};

export type EcommerceProps = {
  children?: React.ReactNode;
};

export const EcommerceContext =
  React.createContext<IEcommerceContext>(defaultContext);

export const EcommerceProvider: React.FC<EcommerceProps> = ({ children }) => {
  const { token, dealerGroupId } = useAuth();
  const { selectedDealerLocation } = useDealerContext();
  //#region State to manage user cart & wishlists
  const [cart, setCart] = useState<Cart | null | undefined>(defaultState.cart);
  const [wishlistsAndOrders, setWishlistsAndOrders] = useState<
    WishListSummary | null | undefined
  >(null);
  const [wishlistDetails, setWishlistDetails] = useState<
    WishlistDetail | null | undefined
  >(null);
  //#endregion
  // #region State to manage user store orders & paypal
  const [storeOrderInfo, setStoreOrderInfo] = useState<
    PlaceOrder | null | undefined
  >(defaultState.storeOrderInfo);
  const [payPalOrderInfo, setPayPalOrderInfo] = useState<
    PayPalPlaceOrder | null | undefined
  >(defaultState.payPalOrderInfo);
  const headers = {
    headers: { Authorization: `Bearer ${token?.access_token}` },
  };
  //#region asynchronous api calls for add, remove, and update cart
  const addToCart = async (tire: Tire, searchOptions: SearchOptions) => {
    const payload = {
      dealerGroupId: dealerGroupId,
      dealerId: tire.DealerId, // This is store location ID
      cartItems: cart?.CartItems,
      tire,
      searchOptions,
      couponCode: cart?.CouponCode,
    };
    const resp = await post<Cart>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/AddTireToCart`,
      payload,
      headers
    );
    if (resp.data) {
      resp.data.ShoppingCartUniqueIdentifier = uuid();
      for (let i = 0; i < resp.data.CartItems.length; i++) {
        if (
          !resp.data.CartItems[i].Amount ||
          resp.data.CartItems[i].Amount !== 0
        ) {
          resp.data.CartItems[i].Amount = getDisplayPrice(
            resp.data.CartItems[i]
          );
        }
      }
    }
    setCart(resp?.data);
  };
  const updateCart = async (cart: Cart) => {
    const resp = await post<Cart>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/UpdateCart`,
      {
        DealerGroupId: dealerGroupId,
        dealerId: selectedDealerLocation?.DealerId,
        Cart: cart,
      },
      headers
    );

    setCart(resp?.data);
  };

  const updateUserCartItems = async (tireId: number, quantity: number) => {
    let newCart: Cart = { ...cart } as Cart;
    if (cart) {
      for (let i = 0; i < cart.CartItems.length; i++) {
        if (cart.CartItems[i].Tire.TireId === tireId) {
          newCart.CartItems[i].Quantity = quantity;
          break;
        }
      }
    }

    await updateCart(newCart);
  };

  const removeFromCart = async (
    cartItem: CartItem | IncludedItem,
    tireId: number,
    isWarranty: boolean,
    isAddOn: boolean,
    isTireAddOn: boolean
  ) => {
    const payload = {
      DealerGroupId: dealerGroupId,
      Cart: cart,
      AddOnId: cartItem.AddOnId,
    } as RemoveFromCartPayload;

    if (isAddOn && !isTireAddOn) {
      payload.DealerId = cart?.CartItems[0]?.DealerId!;
      payload.IsAddOn = true;
      payload.IsWarranty = false;
      payload.IsTireAddOn = isTireAddOn;
    } else {
      payload.DealerId = cart?.CartItems[0]?.DealerId;
      payload.TireId = tireId;
      payload.IsWarranty = isWarranty;
      payload.IsAddOn = isAddOn;
      payload.IsTireAddOn = isTireAddOn;
    }

    const resp = await post<Cart>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/RemoveFromCart`,
      payload,
      headers
    );

    setCart(resp?.data);
  };

  const clearCart = () => {
    setCart(undefined);
  };

  const removeTireFromCart = (tireId: number) => {
    if (cart) {
      for (let i = 0; i < cart.CartItems.length; i++) {
        if (cart.CartItems[i].Tire.TireId === tireId) {
          return removeFromCart(
            cart.CartItems[i],
            cart.CartItems[i].Tire.TireId,
            false,
            false,
            false
          );
        }
      }
    }
  };

  const removeIncludedItemFromCart = (addOnId: number) => {
    if (cart) {
      for (let i = 0; i < cart.IncludedItems.length; i++) {
        if (cart.IncludedItems[i].AddOnId === addOnId) {
          return removeFromCart(cart.IncludedItems[i], 0, false, true, true);
        }
      }
    }

    return {} as IncludedItem;
  };

  const removeAddOnFromCart = (addOnId: number) => {
    if (cart) {
      for (let i = 0; i < cart.CartItems.length; i++) {
        if (
          cart.CartItems[i].IsQuoteAddOn &&
          cart.CartItems[i].AddOnId === addOnId
        ) {
          return removeFromCart(cart.CartItems[i], 0, false, true, false);
        }
      }
    }
  };

  const removeWarrantyFromCart = (tireId: number) => {
    if (cart) {
      for (let i = 0; i < cart.CartItems.length; i++) {
        if (
          cart.CartItems[i].IsWarranty &&
          cart.CartItems[i].Tire.TireId === tireId
        ) {
          return removeFromCart(cart.CartItems[i], tireId, true, false, false);
        }
      }
    }
  };
  //#endregion
  //#region
  //#region asynchronous api calls for create, build, remove, and get wishlist
  const createWishlist = async (customer: WishlistCustomer) => {
    const payload = {
      dealerGroupId: dealerGroupId,
      cart: cart,
      customer,
    };

    const resp = await post<WishlistResp>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/CreateWishList`,
      payload,
      headers
    );
    // if empty object an error occurred
    if (JSON.stringify(resp) === `{}`) {
      return;
    }
    setCart(defaultState.cart);
    setWishlistsAndOrders(resp?.data.WishListSummary);
    setWishlistDetails(resp?.data.WishListDetail);
  };

  const removeFromWishlist = async (
    dealerLocationId: number,
    wishlistId: number
  ) => {
    const payload = {
      dealerGroupId: dealerGroupId,
      dealerId: dealerLocationId,
      wishlistId,
    };
    await post<WishListSummary>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/RemoveWishlist?dealerGroupId=${dealerGroupId}&dealerId=${dealerLocationId}&wishlistId=${wishlistId}`,
      payload,
      headers
    );

    const updatedWishlists: Wishlist[] | undefined =
      wishlistsAndOrders?.Wishlists?.filter((w: any) => w.Id !== wishlistId);
    setWishlistsAndOrders({
      ...wishlistsAndOrders,
      Wishlists: updatedWishlists,
    });
    setWishlistDetails(undefined);
  };

  const getWishlistDetails = async (wishlistId: number) => {
    const resp = await get<WishlistDetail>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetWishlistDetails?orderHeaderId=${wishlistId}`,
      headers
    );
    setWishlistDetails(resp?.data);
  };

  const buildCartFromWishlist = async (orderId: number) => {
    const resp = await get<Cart>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/BuildCartFromWishlist?dealerGroupId=${dealerGroupId}&id=${orderId}`,
      headers
    );
    setCart(resp?.data);
  };

  const getWishlistOrdersForAccount = async (
    email: string,
    zipCode: string
  ) => {
    const resp = await get<WishListSummary>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetOrdersForAccount?dealerGroupId=${dealerGroupId}&postalCode=${zipCode}&email=${email}`,
      headers
    );
    setWishlistsAndOrders(resp?.data);
  };
  //#endregion

  //#region Place Orders
  // Place Order methods
  const placeOrder = async (
    dealerLocationId: number,
    wishlistId: number | null,
    orderInfo: OrderInfo
  ) => {
    const payload = {
      dealerGroupId: dealerGroupId,
      cart: cart,
      dealerId: dealerLocationId,
      orderInfo: orderInfo,
      orderStatus: Symbol("OrderPlaced"),
      wishlistId: wishlistId,
    };
    const resp = await post<PlaceOrder>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/PlaceOrder`,
      payload,
      headers
    );

    setStoreOrderInfo(resp.data);
    return resp.data;
  };

  const placePayPalOrder = async (
    dealerLocationId: number,
    wishlistId: number | null,
    orderInfo: OrderInfo
  ) => {
    const payload = {
      dealerGroupId: dealerGroupId,
      cart: cart,
      dealerId: dealerLocationId,
      orderInfo: orderInfo,
      wishlistId: wishlistId,
    };
    const resp = await post<PayPalPlaceOrder>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/PlacePayPalOrder`,
      payload,
      headers
    );

    setPayPalOrderInfo(resp.data);
    return resp.data;
  };
  //#endregion

  // apply discount code
  const applyDiscountCode = async (
    couponCode: string,
    customerInfo?: OrderInfo
  ) => {
    const payload = {
      dealerGroupId: dealerGroupId,
      dealerId: selectedDealerLocation?.DealerId,
      CouponCode: couponCode,
      Cart: { ...cart, CustomerInfo: { ...customerInfo } },
    };
    const resp = await post<Cart>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/ApplyDiscountCode`,
      payload,
      headers
    );
    if (resp.data && !resp.data.LastChangeSuccessful) {
      Toaster(resp.data.LastChangeFailureMessage);
      return;
    }
    if (!resp.data) {
      Toaster("Invalid Coupon Code");
      return;
    }
    setCart(resp.data);
  };

  return (
    <EcommerceContext.Provider
      value={{
        cart: cart ?? defaultState.cart,
        wishlistsAndOrders,
        wishlistDetails,
        storeOrderInfo: storeOrderInfo ?? defaultState.storeOrderInfo,
        payPalOrderInfo: payPalOrderInfo ?? defaultState.payPalOrderInfo,
        addToCart,
        updateCart,
        updateUserCartItems,
        removeFromCart,
        clearCart,
        removeTireFromCart,
        removeIncludedItemFromCart,
        removeAddOnFromCart,
        removeFromWishlist,
        removeWarrantyFromCart,
        createWishlist,
        getWishlistDetails,
        getWishlistOrdersForAccount,
        buildCartFromWishlist,
        placeOrder,
        placePayPalOrder,
        applyDiscountCode,
      }}
    >
      {children}
    </EcommerceContext.Provider>
  );
};

export const useEcommerceContext = () => useContext(EcommerceContext);
