import { useEffect, useState } from "react";
import { SearchByVehicleCard } from "components/searchByVehicleCard/searchByVehicleCard";
import { SearchTabFooter } from "components/searchTabFooter/searchTabFooter";
import { SearchParams, Option } from "types/search";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { useDealerContext } from "context/dealer";
import { isSearchResultsEmpty } from "helpers/isSearchResultsEmpty";

export interface State {
  make: string | undefined;
  year: number | undefined;
  model: string | undefined;
  body: Option | undefined;
}

export interface Props {
  load: () => void;
  unload: () => void;
}

export const SearchByVehicleTabContent = ({ load, unload }: Props) => {
  const { searchTires, resetManufacturerAndModelsAndOptions } =
    useSearchContext();
  const { dealerStyling } = useDealerContext();
  // State to manage vehicle options
  const [options, setOptions] = useState<State>({} as State);
  // State to manage find tires active or inactive
  const [inActive, setInActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const [isResetSearch, setIsResetSearch] = useState<boolean>(false);

  useEffect(() => {
    // If all required fields are selected make find tires active
    if (options.make && options.year && options.model && options.body) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [options]);

  const findTires = async (dealerId?: number) => {
    // Find tires button call
    load();
    const searchResults = await searchTires(
      {
        Make: options.make as string,
        Model: options.model as string,
        Year: options.year as number,
        Option: options.body?.AutoOption as string,
        Width: options.body?.SecWidth as number,
        AspRatio: options.body?.AspRatio as number,
        Rim: options.body?.Rim as number,
        LoadRating: options.body?.LoadIndex as string,
        SpeedRating: options.body?.SpeedRate as string,
        Width2: options.body?.RearSecWidth as number,
        AspRatio2: options.body?.RearAspRatio as number,
        Rim2: options.body?.RearRim as number,
        LoadRating2: options.body?.RearLoadIndex as string,
        VIN: null,
        TireSize: options.body?.TireSizeField as string,
      } as SearchParams,
      dealerId
    );
    unload();
    if (isSearchResultsEmpty(searchResults)) {
      navigate(TiresAnytimeRoutes.EmptySearchResults, {
        state: {
          isTireBySize: false,
          isByLicensePlate: false,
          isByVehicle: true,
        },
      });
    } else {
      navigate(TiresAnytimeRoutes.Search);
    }
  };

  const resetSearch = () => {
    // If user selects reset search, reset user manaufacturer, model & options selection
    setIsResetSearch(true);
    setOptions({} as State);
    resetManufacturerAndModelsAndOptions();
  };

  return (
    <div
      className={`ta-px-4 ta-py-3.5 md:ta-px-8 ${
        dealerStyling?.Shape === "rectangle" ? "ta-grid md:ta-grid-cols-2" : ""
      }`}
    >
      <SearchByVehicleCard
        stateOpt={options}
        setOptions={setOptions}
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />

      <SearchTabFooter
        classes={`${
          dealerStyling?.Shape === "rectangle"
            ? "ta-flex ta-flex-col ta-justify-evenly md:ta-pl-8"
            : ""
        }`}
        inActive={inActive}
        findTires={findTires}
        resetSearch={resetSearch}
      />
    </div>
  );
};
