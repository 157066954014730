import { CustomIconButton } from "components/customIconButton/customIconButton";
import { Input } from "components/input/input";
import { ChangeEvent, useState } from "react";
import { useEcommerceContext } from "context/ecommerce";
import { Appointment, BillingForm } from "types/checkout";
import { placeOrderPayload } from "helpers/placeOrderPayload";
import { OrderInfo } from "types/wishlist";

interface Props {
  customerInfo: BillingForm;
  appointment: Appointment;
}
export const DiscountCodeInput = ({ customerInfo, appointment }: Props) => {
  const [couponCode, setCouponCode] = useState("");
  const { applyDiscountCode } = useEcommerceContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
  };

  const onClick = async () => {
    await applyDiscountCode(
      couponCode,
      customerInfo
        ? placeOrderPayload(customerInfo, appointment, "InStore", couponCode)
        : ({} as OrderInfo)
    );
  };
  return (
    <form className="ta-text-xs ta-font-sans ta-flex ta-mb-6">
      <div className="ta-mr-2 ta-w-[50%]">
        <Input
          value={couponCode}
          placeholder="Enter Discount Code"
          name="DiscountCode"
          onChange={onChange}
          errorText={""}
          type="input"
        />
      </div>
      <div className="ta-w-[25%]">
        <CustomIconButton
          classes={`ta-border ta-border-button ta-bg-white ta-text-button hover:ta-bg-button hover:ta-text-white ta-w-full ta-h-full
         ta-font-bold ta-rounded-[3px] `}
          text={"Apply"}
          onClick={onClick}
          inActive={couponCode === ""}
        />
      </div>
    </form>
  );
};
