import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/auth";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { DealerProvider } from "./context/dealer";
import { EcommerceProvider } from "./context/ecommerce";
import { SearchProvider } from "./context/search";
import { Toaster } from "react-hot-toast";

const element = document.getElementById("ta-widget-container") as HTMLElement;
const dealerGroupId = element.getAttribute("data-dealer");
const root = ReactDOM.createRoot(element);
if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mocks/browser");
  worker.start();
}
root.render(
  <React.StrictMode>
    {dealerGroupId && (
      <AuthProvider dealerGroupId={dealerGroupId}>
        <DealerProvider>
          <SearchProvider>
            <EcommerceProvider>
              <Toaster />
              <App />
            </EcommerceProvider>
          </SearchProvider>
        </DealerProvider>
      </AuthProvider>
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
