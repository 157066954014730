import {
  Dealer,
  DealerLocation,
  DealerLocations,
  DealerProps,
  DealerStyle,
  SelectedLocation,
} from "types/dealer";
import React, { useEffect, useState } from "react";

import { useAuth } from "./auth";
import { useBackendData } from "../hooks/useBackendData";
import { useContext } from "react";

export interface IDealer {
  selectedDealerLocation: DealerLocation | null | undefined;
  dealerStyling: DealerStyle | null | undefined;
  dealerLocations: DealerLocations | null | undefined;
  selectedDealerGroup: Dealer | undefined;
  switchDealerLocation: (newLocation: number) => void;
  updateForceDealerSelection: () => void;
}

export interface IDealerContext extends IDealer {}

export interface ThemeStyle {
  [index: string]: string;
}

export interface ThemeItem {
  name: string;
  colors: ThemeStyle;
}

const defaultState: IDealer = {
  selectedDealerLocation: undefined,
  dealerStyling: undefined,
  dealerLocations: undefined,
  selectedDealerGroup: undefined,
  switchDealerLocation: () => {},
  updateForceDealerSelection: () => {},
};

const defaultContext: IDealerContext = {
  ...defaultState,
};

export const DealerContext =
  React.createContext<IDealerContext>(defaultContext);

export const DealerProvider: React.FC<DealerProps> = ({ children }) => {
  //#region State to manage data needed from this context
  const [selectedDealerLocation, setSelectedDealerLocation] = useState<
    DealerLocation | null | undefined
  >(null);
  const [dealerStyling, setDealerStyling] = useState<DealerStyle>(
    {} as DealerStyle
  );
  //#endregion
  const { dealerGroupId } = useAuth();
  //#region use useSWR to get dealer info, styles, selectedlocation and available locations
  const { data: defaultSelectedLocation } = useBackendData<SelectedLocation>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetSelectedDealerLocation?dealerGroupId=${dealerGroupId}&dealerId=${dealerGroupId}`
  );

  // get selected group details
  const { data: selectedDealerGroup } = useBackendData<Dealer>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetSelectedDealerGroupDetail?dealerGroupId=${dealerGroupId}`
  );
  const { data: dealerLocations } = useBackendData<
    DealerLocations | undefined | null
  >(
    () =>
      defaultSelectedLocation &&
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetAvailableDealerLocations?dealerGroupId=${dealerGroupId}&selectedDealerId=${defaultSelectedLocation.DealerId}`
  );
  const { data: dealerStyles } = useBackendData<DealerStyle>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetDealerStyling?dealerId=${dealerGroupId}`
  );

  const themesList: ThemeItem[] = [
    {
      name: "default",
      colors: {
        primary: "36 127 137",
        secondary: "0 96 223",
        tertiary: "0 0 0",
        quaternary: "0 0 0",
        header: "0 0 0",
        link: "0 96 223",
        button: "0 96 223",
        input: "0 96 223",
      },
    },
    {
      name: "blue",
      colors: {
        primary: "0 74 141",
        secondary: "0 74 141",
        tertiary: "0 74 141",
        quaternary: "0 74 141",
        header: "0 74 141",
        link: "0 74 141",
        button: "0 74 141",
        input: "0 74 141",
      },
    },
    {
      name: "darkgrey",
      colors: {
        primary: "47 47 47",
        secondary: "0 96 223",
        tertiary: "47 47 47",
        quaternary: "0 0 0",
        header: "47 47 47",
        link: "0 96 223",
        button: "0 96 223",
        input: "0 96 223",
      },
    },
    {
      name: "lightgrey",
      colors: {
        primary: "105 105 105",
        secondary: "0 96 223",
        tertiary: "162 162 162",
        quaternary: "47 47 47",
        header: "162 162 162",
        link: "0 96 223",
        button: "162 162 162",
        input: "0 96 223",
      },
    },
    {
      name: "red",
      colors: {
        primary: "0 0 0",
        secondary: "0 96 223",
        tertiary: "226 26 35",
        quaternary: "0 0 0",
        header: "226 26 35",
        link: "0 96 223",
        button: "226 26 35",
        input: "0 96 223",
      },
    },
  ];

  //#endregion
  useEffect(() => {
    // If dealerStyles changes check color & apply appropriate styles
    setDealerStyling(dealerStyles as DealerStyle);
    const htmlElement = document.documentElement;
    themesList.forEach((item) => {
      if (dealerStyles?.Color === item.name) {
        for (const prop in item.colors) {
          htmlElement.style.setProperty(
            `--color-${prop}`,
            `${item.colors[prop]}`
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerStyles]);

  useEffect(() => {
    // If dealerLocations list changes, find default selected dealer & set it to state\
    if (dealerLocations && Array.isArray(dealerLocations)) {
      setSelectedDealerLocation(
        dealerLocations?.find((l) => l.IsSelectedDealer)
      );
    }
  }, [dealerLocations]);

  const switchDealerLocation = (newLocationId: number) => {
    // This gets called when user changes store location from location selection page
    const newLocationObject = dealerLocations?.find(
      (l) => l.DealerId === newLocationId
    );
    if (newLocationObject) {
      setSelectedDealerLocation({ ...newLocationObject });
    }
  };

  const updateForceDealerSelection = () => {
    // Sets force dealer selection to false once user selected location from finder page
    setDealerStyling((prevState: DealerStyle) => ({
      ...prevState,
      ForceDealerSelect: false,
    }));
  };

  return (
    <DealerContext.Provider
      value={{
        selectedDealerLocation,
        dealerStyling,
        dealerLocations,
        selectedDealerGroup,
        switchDealerLocation,
        updateForceDealerSelection,
      }}
    >
      {children}
    </DealerContext.Provider>
  );
};

export const useDealerContext = () => useContext(DealerContext);
