import { OrderDetails } from "types/cart";
import { priceFormatter } from "helpers/pricing";
import { uuid } from "helpers/uuid";
import { filterCartAddOns, itemHasWarranty } from "helpers/cart";
import { CartAddOn } from "components/cartAddOn/cartAddOn";
import { CartWarranty } from "components/cartWarranty/cartWarranty";
import { mapOrderdetailsToWarranty } from "helpers/mapOrderdetailsToWarranty";

interface Props {
  wishlist: OrderDetails[];
  installAmt?: number;
}

export const WishlistDetails = ({ wishlist, installAmt }: Props) => {
  return (
    <table className="ta-block ta-w-full ta-max-w-none ta-border-0 ta-text-sm sm:ta-table">
      <thead className="ta-block ta-w-full sm:ta-table-header-group sm:ta-w-auto print:ta-hidden">
        <tr className="ta-block ta-w-full ta-h-[40px] ta-bg-header sm:ta-table-row sm:ta-w-auto sm:ta-h-auto">
          <th
            colSpan={2}
            className="ta-text-left ta-hidden ta-text-base ta-text-white ta-pl-8 ta-pr-3.5 ta-py-2.5 sm:ta-table-cell"
          >
            Details
          </th>
          <th className="ta-hidden ta-text-base ta-text-white ta-bg-header ta-px-3.5 ta-py-2.5 ta-text-center sm:ta-table-cell">
            Price
          </th>
          <th className="ta-hidden ta-text-base ta-text-white ta-bg-header ta-px-3.5 ta-py-2.5 ta-text-center sm:ta-table-cell">
            Quantity
          </th>
          <th className="ta-hidden ta-text-base ta-text-white ta-bg-header ta-px-3.5 ta-py-2.5 sm:ta-table-cell sm:ta-text-center xl:ta-pl-2.5">
            Total
          </th>
          <th className="ta-print-none ta-hidden ta-text-base ta-text-white ta-bg-header ta-px-20 ta-py-2.5 sm:ta-table-cell"></th>
        </tr>
      </thead>
      <tbody className="ta-block ta-w-full sm:ta-table-row-group sm:ta-w-auto">
        {wishlist.map((item: OrderDetails) => {
          const isWarranty = itemHasWarranty(item);
          const isAddOn = filterCartAddOns(item);
          return !isAddOn && !isWarranty ? (
            <tr
              className="ta-block ta-w-full ta-border-b ta-border-grey sm:ta-table-row sm:ta-w-auto sm:ta-border-b-0 ta-clear-both"
              key={uuid()}
            >
              <td className="ta-block ta-float-left ta-py-5 ta-px-3 ta-w-[80px] ta-min-h-[150px] sm:ta-table-cell sm:ta-float-none sm:ta-border-b sm:ta-border-grey sm:ta-pr-0 lg:ta-min-h-0">
                <img
                  alt={item.ProductName}
                  src={item.ImageUrl}
                  className="ta-w-[80px] lg:ta-max-w-none"
                />
              </td>

              <td className="ta-block ta-w-full ta-py-5 ta-pr-3.5 sm:ta-table-cell sm:ta-w-auto sm:ta-border-b sm:ta-border-grey sm:ta-px-3.5">
                <div className="ta-font-bold">{item.ManufacturerName}</div>
                <div className="ta-font-bold">{item.ProductName}</div>
                <div>{item.TireSize}</div>
                <div>Item#: {item.PartNumber}</div>
              </td>

              <td className="ta-block ta-float-left ta-py-3 ta-pl-1 ta-pr-3 sm:ta-py-0 sm:ta-float-none sm:ta-px-3.5 sm:ta-table-cell sm:ta-w-auto sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
                ${priceFormatter(item.DisplayPrice)}{" "}
                <span className="sm:hidden">ea.</span>
              </td>

              <td className="ta-block ta-float-left ta-w-[44px] ta-p-2 ta-border ta-border-black ta-rounded-[3px] ta-text-center sm:ta-table-cell sm:ta-float-none sm:ta-w-auto sm:ta-border-0 sm:ta-border-b sm:ta-border-grey sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
                {item.Quantity}
              </td>

              <td className="ta-block ta-clear-left ta-float-left ta-pl-[85px] ta-pr-7 ta-pb-4 sm:ta-float-none sm:ta-table-cell sm:ta-w-auto sm:ta-px-3.5 sm:ta-border-b sm:ta-border-grey sm:ta-text-center">
                <div className="ta-font-bold">
                  ${priceFormatter(item.DisplayPrice * item.Quantity)}
                </div>
              </td>
              <td className="ta-block ta-clear-both sm:ta-table-cell sm:ta-border-b sm:ta-border-grey"></td>
            </tr>
          ) : isAddOn ? (
            <CartAddOn
              item={item}
              onRemoveAddOnClick={(e: any) => {}}
              canRemove={false}
            />
          ) : (
            <CartWarranty
              warranty={mapOrderdetailsToWarranty(item)}
              onRemoveWarrantyClick={(e: any) => {}}
              canRemove={false}
            />
          );
        })}
        {installAmt ? (
          <tr className="ta-block ta-w-full ta-border-b ta-border-grey sm:ta-table-row sm:ta-w-auto">
            <td className="ta-w-[80px]" aria-hidden="true"></td>
            <td
              className="ta-block ta-w-full ta-pt-5 ta-pl-[85px] sm:ta-table-cell sm:ta-w-auto sm:ta-px-4 sm:ta-pb-5"
              colSpan={3}
            >
              <span className="ta-font-bold">Installation Price</span>
            </td>
            <td className="ta-block ta-w-full ta-pt-2 ta-pb-4 ta-pl-[85px] ta-pr-7 ta-clear-left sm:ta-px-3.5 sm:ta-py-0 sm:ta-px-0 sm:ta-table-cell sm:ta-border-b sm:ta-border-grey sm:ta-w-auto sm:ta-text-center">
              <div className="ta-font-bold">
                ${priceFormatter(installAmt as number)}
              </div>
            </td>
            <td className="ta-block ta-clear-both sm:ta-table-cell sm:ta-border-b sm:ta-border-grey"></td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};
