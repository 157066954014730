import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { errorHandler } from "../helpers/errorHandler";

export const get = async <T>(
  url: string,
  headers: any
): Promise<AxiosResponse<T> | undefined> => {
  try {
    const resp = await axios.get<T>(url, headers);
    return resp;
  } catch (err: any) {
    errorHandler(err);
    toast.error(`${err.response.statusText}\n${err.message}`, {
      id: err.response.statusText,
    });
    return {} as Promise<AxiosResponse<T>>;
  }
};

export const post = async <T>(
  url: string,
  payload: any,
  headers: any
): Promise<AxiosResponse<T>> => {
  try {
    const resp = await axios.post<T>(url, payload, headers);
    return resp;
  } catch (err: any) {
    errorHandler(err);
    toast.error(`${err.response.statusText}\n${err.message}`, {
      id: err.response.statusText,
    });
    return {} as Promise<AxiosResponse<T>>;
  }
};
