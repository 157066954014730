import { OrderDetails } from "types/cart";
import { priceFormatter } from "helpers/pricing";
import { uuid } from "helpers/uuid";
import { ChangeEvent } from "react";

interface Props {
  item: OrderDetails;
  onQuantityChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onRemoveTireClick: (e: any) => void;
}
const quantityList = [1, 2, 3, 4, 5, 6, 7, 8];
export const CartItems = ({
  item,
  onQuantityChange,
  onRemoveTireClick,
}: Props) => {
  const removeText = "Remove";
  if (filterCartTires(item)) {
    return (
      <tr
        className="ta-block ta-w-full ta-border-b ta-border-grey sm:ta-table-row sm:ta-w-auto sm:ta-border-b-0"
        key={item.TireId}
      >
        <td className="ta-block ta-absolute ta-p-4 ta-w-[100px] sm:ta-w-[120px] sm:ta-static sm:ta-min-h-0 sm:ta-table-cell sm:ta-border-b sm:ta-border-grey">
          <img alt="" src={item.ImageUrl} className="ta-max-w-full" />
        </td>

        <td className="ta-block ta-w-full ta-p-4 ta-pl-[100px] xs:ta-pl-[120px] sm:ta-table-cell sm:ta-pl-1 sm:ta-w-auto sm:ta-border-b sm:ta-border-grey">
          <div className="ta-font-bold">{item.ManufacturerName}</div>
          <div className="ta-font-bold">{item.ProductName}</div>
          <div>{item.TireSize}</div>
          <div>Item#: {item.PartNumber}</div>
        </td>

        <td className="ta-block ta-py-1 ta-pl-[100px] xs:ta-pl-[120px]  ta-pr-4 ta-float-left xs:ta-w-3/5 sm:ta-py-0 sm:ta-float-none sm:ta-pl-7 sm:ta-table-cell sm:ta-w-auto sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
          ${priceFormatter(item.DisplayPrice)}{" "}
          <span className="sm:hidden">ea.</span>
        </td>

        <td className="ta-block ta-float-left sm:ta-align-middle sm:ta-float-none sm:ta-pl-7 sm:ta-pr-7 sm:ta-align-middle sm:ta-table-cell sm:ta-w-auto sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
          <select
            value={item.Quantity}
            onChange={onQuantityChange}
            data-id={item.TireId}
            className="ta-border ta-border-darkgrey ta-rounded ta-p-1 ta-w-[40px]"
          >
            {quantityList.map((quantity) => (
              <option key={uuid()} value={quantity}>
                {quantity}
              </option>
            ))}
          </select>
          <div aria-live="polite">
            {item.Quantity >= 8 ? (
              <span style={{ color: "darkred" }}>
                Only 8 tires can be added at a time
              </span>
            ) : null}
          </div>
        </td>

        <td className="ta-inline-block ta-clear-left ta-pl-[100px] xs:ta-pl-[120px]  ta-pr-3 ta-pb-4 ta-pt-3 xs:ta-w-1/2 sm:ta-w-auto sm:ta-pl-3 sm:ta-table-cell sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
          <div className="ta-font-bold">
            ${priceFormatter(item.DisplayPrice * item.Quantity)}
          </div>
          <span className="ta-print-none ta-sr-only" aria-live="polite">
            total cost is {item.DisplayPrice * item.Quantity}
          </span>
        </td>

        <td className="ta-block ta-pl-[95px] xs:ta-pl-[110px] sm:ta-pl-0 ta-pb-4 ta-pr-3 xs:ta-w-1/2 sm:ta-w-auto sm:ta-py-0 sm:ta-px-4 sm:ta-table-cell sm:ta-text-center sm:ta-border-b sm:ta-border-grey">
          <button
            data-id={item.TireId}
            type="button"
            onClick={onRemoveTireClick}
            className="ta-flex ta-flex-nowrap ta-items-center sm:ta-m-auto"
          >
            <span className="ta-sr-only lg:ta-not-sr-only ta-text-xs lg:ta-text-base">
              {removeText}
              <span className="ta-sr-only">{item.ProductName}</span>
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.5"
              height="12"
              viewBox="0 0 10.5 12"
              className="ta-text-button ta-ml-2"
            >
              <path
                id="Icon_awesome-trash"
                data-name="Icon awesome-trash"
                d="M10.125.75H7.312L7.092.312A.563.563,0,0,0,6.588,0H3.909a.556.556,0,0,0-.5.312L3.187.75H.375A.375.375,0,0,0,0,1.125v.75a.375.375,0,0,0,.375.375h9.75a.375.375,0,0,0,.375-.375v-.75A.375.375,0,0,0,10.125.75Zm-8.878,10.2A1.125,1.125,0,0,0,2.37,12H8.13a1.125,1.125,0,0,0,1.123-1.055L9.75,3h-9Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </td>
      </tr>
    );
  } else {
    return <></>;
  }
};

const filterCartTires = (item: OrderDetails) => {
  return !item.IsWarranty && !item.IsQuoteAddOn;
};
