import { ChangeEvent } from "react";
import { SelectOptions } from "types/search";
import { uuid } from "helpers/uuid";
interface Props {
  placeholder: string;
  options: SelectOptions[];
  styles?: React.CSSProperties;
  classes?: string;
  disabled: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  value?: string | number;
  required?: boolean;
  id?: string;
}
export const DropDown = ({
  placeholder,
  options,
  styles,
  classes,
  disabled,
  onChange,
  value,
  required,
  id,
}: Props) => {
  return (
    <select
      id={id}
      value={value}
      className={classes}
      style={styles}
      required={required}
      disabled={disabled}
      onChange={onChange}
    >
      <option defaultValue={""} value="">
        {placeholder}
      </option>
      {options.map((option: SelectOptions) => (
        <option
          data-id={option.data_id ? option.data_id : undefined}
          key={uuid()}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};
