import { addPrecision, removePrecision } from "helpers/precisionHelper";
import { warrantyFormatter } from "helpers/warrantyFormatter";
import { Tire } from "types/tire";

interface Props {
  tire: Tire;
  navigateToProductDetailsPage: () => void;
}
export const SearchResultsListItemInfo = ({
  tire,
  navigateToProductDetailsPage,
}: Props) => {
  return (
    <div className="ta-mt-4 ta-text-sm">
      <div className="md:ta-mb-3 md:ta-grid md:ta-grid-cols-2 md:ta-gap-3 lg:ta-grid-cols-3">
        <div>
          Tire Size:{" "}
          <span className="ta-font-bold">{tire.DisplayTireSize}</span>
        </div>
        <div>
          Item #:{" "}
          <button
            type="button"
            className="ta-text-link ta-underline"
            onClick={navigateToProductDetailsPage}
          >
            {tire.ManufacturerPartNumber}
          </button>
        </div>
      </div>

      <div className="md:ta-grid md:ta-grid-cols-2 md:ta-gap-3 lg:ta-grid-cols-3">
        <div>
          Load Rating:{" "}
          <span className="ta-font-bold">
            {removePrecision(tire.LoadRating)}
          </span>
        </div>
        <div>
          Speed Rating: <span className="ta-font-bold">{tire.SpeedRating}</span>
        </div>
        <div>
          Mileage Warranty:{" "}
          <span className="ta-font-bold">
            {warrantyFormatter(tire.Warranty)}
          </span>
        </div>
        <div>
          UTQG: <span className="ta-font-bold">{tire.UTQG}</span>
        </div>
        <div>
          Sidewall: <span className="ta-font-bold">{tire.SideWall}</span>
        </div>
        <div>
          Load Ply: <span className="ta-font-bold">{tire.PlyRating}</span>
        </div>
        <div>
          Tread Depth:{" "}
          <span className="ta-font-bold">{addPrecision(tire.TreadDepth)}</span>
        </div>
      </div>
    </div>
  );
};
