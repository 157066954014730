type Generic = { [key: string]: any };

// Validates if user entered all required fields & no errors exist
export function formValidator<T extends Generic>(
  form: Exclude<T, Function>,
  errors: Exclude<T, Function>,
  optionalFields?: string[]
): boolean {
  // check no errors exist first then validate all required fields are entered
  const errorFields = Object.keys(errors);
  const isError = errorFields.find((field) => errors[field] !== "");
  if (isError) {
    return false;
  }
  const allFields = Object.keys(form);
  const requiredFields = optionalFields
    ? allFields.filter(
        (field) => !optionalFields.find((optField) => optField === field)
      )
    : allFields;
  // check all required fields are filled out
  const isFormFilled = requiredFields.every((field) => form[field] !== "");
  return isFormFilled;
}
