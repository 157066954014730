interface ErrorTextProps {
  text: string;
}

export const ErrorText: React.FC<ErrorTextProps> = ({ text }) => {
  return (
    <div className="ta-mt-1">
      <p className="ta-text-sm ta-text-red ta-font-semibold">{text}</p>
    </div>
  );
};
