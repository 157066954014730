import { Filter } from "types/filters";
interface FilterObj {
  [key: string]: boolean;
}

// Check if `Show All` needs to be checked or not
export const shouldCheckShowAll = (
  filterState: FilterObj,
  filter: string,
  checked: boolean
) => {
  let clearFilters = false,
    showAll = false;
  if (checked && filter === Filter.All) {
    clearFilters = isAnyOtherFilterChecked(filterState);
    showAll = true;
    return { clearFilters, showAll };
  } else if (!checked) {
    showAll = isNoneChecked(filterState, filter);
    return { clearFilters, showAll };
  }
  for (const key in filterState) {
    if (key === filter && key !== Filter.All) {
      // filterState[Filter.All] = false;
      // return false;
      return { clearFilters, showAll };
    }
  }
  // filterState[Filter.All] = true;
  // return false;
  showAll = true;
  return { clearFilters, showAll };
};

// If filter isn't filtered by anything this will return true to check `Show All`
const isNoneChecked = (filterState: FilterObj, filter: string) => {
  for (const key in filterState) {
    if (filterState[key]) {
      if (key !== filter) {
        return false;
      }
    }
  }
  return true;
};

// Check if any other filtere is checked when user selects `Show All` filter,
// If other filters are checked unselect all and select `Show All`
const isAnyOtherFilterChecked = (filterState: FilterObj) => {
  let clearFilters = false;
  for (const key in filterState) {
    if (filterState[key]) {
      if (key === Filter.All) {
        filterState[Filter.All] = true;
        continue;
      }
      filterState[key] = false;
      clearFilters = true;
    }
  }
  return clearFilters;
};
