import { BillingInfoCard } from "components/billingInfoCard/billingInfoCard";
import { AppointmentInfoCard } from "components/appointmentInfoCard/appointmentInfoCard";
import { PaymentInfoCard } from "components/paymentInfoCard/paymentInfoCard";
import {
  Appointment,
  BillingForm,
  Payment,
  PaymentMethods,
} from "types/checkout";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { useEcommerceContext } from "context/ecommerce";
import { filterCartItemsToTiresOnly } from "helpers/mapCartTotalsWithTotalsCards";
import { DiscountCodeInput } from "components/discountCodeInput/DiscountCodeInput";
import { post } from "services/apiServices";
import { useAuth } from "context/auth";
import { useDealerContext } from "context/dealer";
import { Cart } from "types/cart";
import { TiresAnytimeRoutes } from "routes";
import { placeOrderPayload } from "helpers/placeOrderPayload";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { useBackendData } from "hooks/useBackendData";
import { DealerDetails } from "types/dealer";

interface BillingState extends BillingForm {}
interface AppointmentState extends Appointment {}
interface PaymentState extends Payment {}

interface Props {
  billing: BillingState;
  setBilling: React.Dispatch<React.SetStateAction<BillingState>>;
  billingError: BillingForm;
  setBillingError: React.Dispatch<React.SetStateAction<BillingForm>>;
  appointment: AppointmentState;
  setAppointment: React.Dispatch<React.SetStateAction<AppointmentState>>;
  appointmentError: Appointment;
  setAppointmentError: React.Dispatch<React.SetStateAction<Appointment>>;
  payment: PaymentState;
  setPayment: React.Dispatch<React.SetStateAction<PaymentState>>;
  paymentError: Payment;
  setPaymentError: React.Dispatch<React.SetStateAction<Payment>>;
  setShowPlaceOrderBtn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckoutForm = ({
  billing,
  setBilling,
  billingError,
  setBillingError,
  appointment,
  setAppointment,
  appointmentError,
  setAppointmentError,
  payment,
  setPayment,
  paymentError,
  setPaymentError,
  setShowPlaceOrderBtn,
}: Props) => {
  const { cart, placePayPalOrder, clearCart } = useEcommerceContext();
  const { token, dealerGroupId } = useAuth();
  const { selectedDealerLocation } = useDealerContext();
  const navigate = useNavigate();
  const { loading, load, unload } = useBusyIndicator();

  const mutatedCart = filterCartItemsToTiresOnly(cart);

  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethods | undefined
  >(undefined);
  const [isActive, setIsActive] = useState(false);
  const [isActiveNextStep, setIsActiveNextStep] = useState(false);
  const [isActiveButton, setIsActiveButton] = useState(false);

  const dateRef = useRef<HTMLInputElement>(null);
  const { data } = useBackendData<DealerDetails>(
    `${process.env.REACT_APP_BASE_URL}//api/TireSearchingApi/GetDealerDetails?dealerGroupId=${dealerGroupId}&dealerId=${selectedDealerLocation?.DealerId}`
  );

  const buildUrlFor1stMileFormId = async (isCardLogo = false) => {
    const resp = await post<Cart>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/BuildURL?isCardLogo=${isCardLogo}`,
      {
        DealerGroupId: dealerGroupId,
        dealerId: selectedDealerLocation?.DealerId,
        Cart: mutatedCart,
        OrderInfo: {
          Address1: billing.address,
          Address2: billing.aptSuite,
          City: billing.city,
          StateAbbr: billing.state,
          Email: billing.confirmEmail,
          PostalCode: billing.zipCode || "",
          Phone: billing.phone,
          FirstName: billing.firstName,
          LastName: billing.lastName,
          PaymentMethod: `Credit Card`,
          Appointment1: appointment.firstChoiceDate,
          Appointment2: appointment.secondChoiceDate,
        },
      },
      headers
    );
    if (resp && resp.data) {
      return resp.data.FirstMileURL;
    }
    return "";
  };

  const navigateToConfirmationPage = () => {
    // Set router state to data needed in confirmation page for display
    navigate(TiresAnytimeRoutes.Confirmation, {
      state: {
        name: billing.firstName,
        firstChoiceDate: appointment.firstChoiceDate,
        firstChoiceTime: appointment.firstChoiceTime,
        secondChoiceDate: appointment.secondChoiceDate,
        secondChoiceTime: appointment.secondChoiceTime,
        cart: cart,
        paymentMethod: "Credit Card",
      },
    });
    clearCart();
  };

  const payPalPlaceOrder = async () => {
    // place paypal order
    load();
    const payPalOrderResp = await placePayPalOrder(
      selectedDealerLocation?.DealerId as number,
      null,
      placeOrderPayload(billing, appointment, "PayPal")
    );
    unload();
    // navigate to payPal form page
    if (payPalOrderResp && payPalOrderResp.PaypalUrl) {
      clearCart();
      navigate(TiresAnytimeRoutes.payPalCheckout);
    }
  };

  const handleButonState = async () => {
    setIsActive(true);
    if (dateRef.current) {
      dateRef.current.click();
    }
  };
  const headers = {
    headers: { Authorization: `Bearer ${token?.access_token}` },
  };
  useEffect(() => {
    if (data) {
      setPaymentMethods(mapPaymentMethods(data));
    }
  }, [selectedDealerLocation, data]);

  return (
    <div className={"ta-grid lg:ta-grid-cols-2 ta-gap-2"}>
      {/* Billing Start */}
      <div className="ta-flex ta-flex-col ta-gap-y-6">
        <h2 className="!ta-bg-transparent ta-tracking-normal ta-m-0 ta-text-base ta-font-bold ta-text-base">
          1. Billing Information:
        </h2>
        <BillingInfoCard
          setActiveButton={setIsActiveButton}
          billing={billing}
          setBilling={setBilling}
          errors={billingError}
          setError={setBillingError}
        />
        <CustomIconButton
          text={"Continue"}
          classes={`ta-border ta-border-button ta-bg-white ta-text-button ${
            isActiveButton ? "hover:ta-bg-button hover:ta-text-white" : ""
          } ta-w-full ta-font-bold ta-text-2xl ta-rounded-[3px] ta-p-3 ta-mt-2 xl:ta-mt-8`}
          inActive={!isActiveButton}
          onClick={handleButonState}
        />
      </div>
      {/* Billing End */}

      <div className={`${!isActive ? "ta-hidden" : null}`}>
        <div className="ta-flex ta-flex-col ta-gap-6">
          {/* Appointment Start */}
          <div className="ta-flex ta-flex-col ta-gap-y-6">
            <h2 className="!ta-bg-transparent ta-tracking-normal ta-mb-0 ta-font-bold ta-text-base">
              2. Preferred Appointment:
            </h2>

            <AppointmentInfoCard
              ref={dateRef}
              appointment={appointment}
              setAppointment={setAppointment}
              errors={appointmentError}
              setError={setAppointmentError}
              isActive={isActive}
              nextStep={setIsActiveNextStep}
            />
          </div>
          {/* Appointment End */}

          {/* Payment Start */}
          <div className="ta-flex ta-flex-col">
            <h2 className="!ta-bg-transparent ta-tracking-normal ta-mb-[18px] ta-text-base ta-font-bold ta-text-base">
              3. Secured Payment:
            </h2>
            <DiscountCodeInput
              customerInfo={billing}
              appointment={appointment}
            />
            <PaymentInfoCard
              paymentMethods={paymentMethods}
              isActive={isActiveNextStep}
              payment={payment}
              setPayment={setPayment}
              paymentError={paymentError}
              setPaymentError={setPaymentError}
              buildUrl={buildUrlFor1stMileFormId}
              navigateToConfirmationPage={navigateToConfirmationPage}
              setShowPlaceOrderBtn={setShowPlaceOrderBtn}
              payPalPlaceOrder={payPalPlaceOrder}
            />
          </div>
          {/* Payment End */}
        </div>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};

const mapPaymentMethods = (data: DealerDetails) => {
  return {
    payWithCreditCard: data?.HasCreditCard && !!data?.FirstMileIdentifier,
    payWithPaypal: data?.HasPayPal,
    payInStore: data?.HasPayInStore,
  };
};
