import { ApiToken } from "types/authentication";
import axios from "axios";
import dayjs from "helpers/dayjs";

export const requestNewToken = async (dealerId: string) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/Token`;
    const params = new URLSearchParams();

    params.append("grant_type", "password");
    params.append("dealerId", dealerId);

    const tokenResponse = await axios.post(url, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (tokenResponse.status === 200 && tokenResponse?.data) {
      return {
        access_token: tokenResponse.data.access_token,
        expires: dayjs.utc().add(tokenResponse.data.expires_in, "s").valueOf(),
      } as ApiToken;
    }
  } catch (err) {
    console.log("ERROR", err);
  }
  return null;
};
