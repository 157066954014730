import { Cart, CartItem, OrderTotalCards } from "types/cart";
import { calculateCartDiscounts } from "./pricing";
// Map cart totals to OrderTotalsCard component
export const mapCartTotalsWithTotalsCards = (
  cart: Cart | null | undefined
): OrderTotalCards => {
  const mutatedCart = filterCartItemsToTiresOnly(cart);
  return {
    SubTotal: mutatedCart ? (mutatedCart.SubTotal as number) : 0,
    Discounts:
      mutatedCart && mutatedCart.CartItems
        ? calculateCartDiscounts(mutatedCart.CartItems)
        : 0,
    Tax: mutatedCart ? (mutatedCart.Tax as number) : 0,
  };
};

export const filterCartItemsToTiresOnly = (cart: Cart | null | undefined) => {
  if (!cart) return undefined;
  const mutatedCart = { ...cart, CartItems: [] as CartItem[] };
  for (let i = 0; i < cart.CartItems.length; i++) {
    if (!cart.CartItems[i].IsQuoteAddOn && !cart.CartItems[i].IsWarranty) {
      mutatedCart.CartItems.push(cart.CartItems[i]);
    }
  }
  return mutatedCart;
};
