import { Rebate } from "types/tire";
import { uuid } from "helpers/uuid";

interface Props {
  rebates: Rebate;
}
export const Rebates = ({ rebates }: Props) => {
  return (
    <div className="ta-flex ta-flex-col md:ta-flex-row md:ta-gap-7">
      <div className="ta-mb-3 5">
        <img
          src={rebates.ImageUrl}
          alt="Rebates"
          className="ta-max-w-[283px]"
        />
      </div>
      <div>
        <h1 className="ta-text-2xl ta-font-bold">{rebates.Name}</h1>
        <p className="ta-text-xl">{rebates.Description}</p>

        <div className="ta-mt-7">
          <a
            target={"noopener noreferrer"}
            href={rebates.PdfUrl}
            className="ta-block ta-w-full ta-rounded-[3px] ta-py-2 !ta-bg-button ta-bg-none ta-font-bold ta-text-sm ta-text-center ta-text-white hover:ta-bg-[length:0px_0px]"
          >
            Download Rebate Form
            <span className="ta-sr-only">will open in a new window</span>
          </a>
          <a
            target={"noopener noreferrer"}
            href={rebates.RebateSite}
            className="ta-block ta-w-full ta-rounded-[3px] ta-mt-4 ta-py-2 !ta-bg-button ta-bg-none ta-font-bold ta-text-sm ta-text-center ta-text-white hover:ta-bg-[length:0px_0px]"
          >
            Submit Rebate Online
            <span className="ta-sr-only">will open in a new window</span>
          </a>
        </div>
      </div>
      {rebates.SalesClasses && rebates.SalesClasses.length > 0 && (
        <p className="ta-text-sm ta-mt-12 xl:ta-mt-14">
          {rebates.SalesClasses?.map(
            (item: string, i: number, arr: string[]) => {
              if (i === arr.length - 1) {
                return <span key={uuid()}>{item}</span>;
              }
              return <span key={uuid()}>{item}, </span>;
            }
          )}
        </p>
      )}
    </div>
  );
};
