import { CurrentLocationCard } from "../currentLocationCard/currentLocationCard";
import { useEcommerceContext } from "context/ecommerce";
import { TiresAnytimeRoutes } from "routes";
import { useNavigate } from "react-router-dom";

interface Props {
  showCart: boolean;
  withoutLocation?: boolean;
}
export const SiteHeader = ({ showCart, withoutLocation }: Props) => {
  const { cart } = useEcommerceContext();
  const itemsNumber = cart && cart.CartItems ? cart.CartItems.length : 0;
  const navigate = useNavigate();

  const handleCartNav = () => {
    if (itemsNumber !== 0) {
      navigate(TiresAnytimeRoutes.Cart);
    }
  };

  return (
    <header className="ta-container">
      <div className="ta-grid ta-grid-cols-header ta-items-center xl:ta-items-end ta-pb-6 ta-gap-8 xl:ta-grid-cols-headerXl xl:ta-gap-12">
        <div className="ta-justify-self-start">
          <CurrentLocationCard withoutLocation={withoutLocation} />
        </div>

        {showCart ? (
          <div className="header__cart ta-justify-self-end ta-pr-2.5 xl:ta-pr-0">
            <button
              type="button"
              className="ta-flex ta-items-center cart"
              onClick={handleCartNav}
              disabled={itemsNumber === 0 ? true : false}
            >
              <div className="ta-text-lg ta-text-link ta-font-bold ta-hidden xl:ta-block">
                Cart
              </div>
              <div
                className={`ta-relative ta-flex ta-flex-nowrap ta-items-center ta-text-link xl:ta-pl-3.5 xl:ta-ml-3.5 xl:ta-border-l xl:ta-border-link ${
                  itemsNumber === 0 ? "ta-text-grey" : "ta-text-link"
                }`}
              >
                <span className="ta-absolute -ta-top-4 -ta-right-3 ta-px-0.5 ta-rounded-full ta-whitespace-nowrap ta-bg-white ta-font-bold ta-text-base xl:ta-mr-3.5 xl:ta-font-normal xl:ta-static">
                  {itemsNumber}{" "}
                  <span className="ta-hidden xl:ta-inline">items</span>
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-6 h-6 xl:h-3.5`}
                  width="16.134"
                  height="14.948"
                  viewBox="0 0 16.134 14.948"
                >
                  <path
                    d="M20.066,6.519,7.227,6.87l-.5-1.4H4l.561.98H5.4l2.1,6.143a2.279,2.279,0,0,0-.77,1.747,2.206,2.206,0,0,0,2.235,2.235H20.134V14.9H8.889a.6.6,0,0,1-.557-.561.52.52,0,0,1,.347-.488h0l11.387-2.376Z"
                    transform="translate(-4 -5.47)"
                    fill="currentColor"
                  />
                  <circle
                    cx="1.678"
                    cy="1.678"
                    r="1.678"
                    transform="translate(4.469 11.592)"
                    fill="currentColor"
                  />
                  <circle
                    cx="1.678"
                    cy="1.678"
                    r="1.678"
                    transform="translate(12.709 11.592)"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </header>
  );
};
