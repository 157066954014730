import { SearchParams } from "types/search";

export const isVehicleSearchPerformed = (
  searchParams: SearchParams | undefined
) => {
  if (!searchParams) return false;
  return !!(
    searchParams.Year &&
    searchParams.Make &&
    searchParams.Model &&
    searchParams.Option
  );
};
