import { SelectOptions, States } from "types/search";

export const mapStatesToSelectionOptions = (states: States[] | undefined) => {
  const options: SelectOptions[] = [];
  if (!states) {
    return options;
  }
  for (let i = 0; i < states.length; i++) {
    options.push({
      label: states[i].Description,
      value: states[i].Description,
      data_id: states[i].Id,
    });
  }
  return options;
};
