interface Props {
  url: string;
  text?: string;
  className?: string;
}
export const MapIt = ({ className, url, text }: Props) => {
  return (
    <a
      target="noopener noreferrer"
      href={url}
      className="ta-inline-flex ta-items-center ta-text-link hover:ta-underline"
      aria-label={`${text} will open in a new window`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="24"
        viewBox="0 0 18 24"
        className="ta-mr-2 w-[12px] ta-h-[16px]"
      >
        <path
          d="M8.075,23.516C1.264,13.642,0,12.629,0,9A9,9,0,0,1,18,9c0,3.629-1.264,4.642-8.075,14.516a1.125,1.125,0,0,1-1.85,0ZM9,12.75A3.75,3.75,0,1,0,5.25,9,3.75,3.75,0,0,0,9,12.75Z"
          fill="currentColor"
        />
      </svg>
      {text}
    </a>
  );
};
