import React, { ChangeEvent } from "react";
import { CustomDropDown } from "components/customDropDown/customDropDown";
import { SelectOptions, States } from "types/search";
import { State } from "components/searchByLicensePlateTabContent/searchByLicensePlateTabContent";
import { mapStatesToSelectionOptions } from "helpers/mapStatesToSelectionOptions";

interface Props {
  stateOpt: State;
  setOptions: React.Dispatch<React.SetStateAction<State>>;
  states: States[];
  isResetSearch?: boolean | undefined;
  setIsResetSearch?: any;
}

export const SearchByLicensePlateCard = ({
  stateOpt,
  setOptions,
  states,
  isResetSearch,
  setIsResetSearch,
}: Props) => {
  const options = mapStatesToSelectionOptions(states);
  const onChangeStateDropDown = (e: SelectOptions | null) => {
    if (e) {
      setOptions((prevState: State) => ({
        ...prevState,
        state: e.value,
      }));
    }
  };
  const onChangePlateNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setOptions((prevState: State) => ({
        ...prevState,
        plate: e.target.value,
      }));
    }
  };
  return (
    <div className="md:ta-grid md:ta-grid-cols-2 md:ta-gap-[15px]">
      <div>
        <label
          htmlFor="license-plate-number"
          className="ta-text-black ta-block ta-mb-1"
        >
          Enter Your License Plate Number
        </label>
        <input
          id="license-plate-number"
          type="text"
          placeholder={"Enter Your License Plate Number"}
          value={stateOpt.plate}
          onChange={onChangePlateNumber}
          className="ta-h-[50px] ta-text-sm ta-w-full placeholder:ta-text-black/25 ta-rounded-[5px] ta-px-3.5 ta-mb-2 ta-shadow-md"
        />
      </div>

      <CustomDropDown
        onClick={onChangeStateDropDown}
        placeholder={"Choose State"}
        header={"Choose State"}
        options={options}
        value={stateOpt.state}
        id="searchByPlate"
        isMega={false}
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />
    </div>
  );
};
