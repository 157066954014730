import { MouseEventHandler, useState } from "react";
import { useDealerContext } from "context/dealer";
import { LocationSelectionPage } from "pages/locationSelection/locationSelectionPage";
import { Dialog } from "components/dialog/dialog";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { useBusyIndicator } from "hooks/useBusyIndicator";
interface Props {
  inActive: boolean;
  findTires: () => void;
  resetSearch: MouseEventHandler<HTMLButtonElement>;
  classes?: string;
}

export const SearchTabFooter = ({
  inActive,
  findTires,
  resetSearch,
  classes,
}: Props) => {
  const { dealerStyling } = useDealerContext();
  const { loading } = useBusyIndicator();
  // State to manage location selection dialog from finder page
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={`${classes}`}>
      {dealerStyling?.ForceDealerSelect ? (
        <>
          <button
            type="button"
            className="ta-w-full ta-border-2 ta-rounded-[3px] ta-text-lg ta-font-bold ta-mt-5 ta-p-3.5 cursor-pointer enabled:ta-text-white ta-border-button ta-text-button enabled:ta-bg-button"
            disabled={inActive}
            onClick={handleOpen}
          >
            Find My Tires
          </button>
          <Dialog
            title=""
            content={
              <LocationSelectionPage
                hideBackButton={true}
                search={findTires}
                onClose={handleClose}
              />
            }
            open={open}
            onClose={handleClose}
          />
        </>
      ) : (
        <button
          type="button"
          className="ta-w-full ta-border-2 ta-border-button ta-rounded-[3px] ta-text-lg ta-text-button ta-font-bold ta-mt-5 ta-p-3.5 cursor-pointer enabled:ta-text-white enabled:ta-bg-button"
          onClick={() => findTires()}
          disabled={inActive}
        >
          Find My Tires
        </button>
      )}
      <button
        type="button"
        className={`${
          dealerStyling?.Shape === "rectangle"
            ? "ta-w-full ta-justify-center ta-border-2 ta-border-tertiary ta-rounded-[3px] ta-text-lg ta-text-tertiary ta-font-bold ta-mt-5 ta-p-3.5 cursor-pointer hover:ta-text-white hover:ta-bg-tertiary"
            : "ta-underline ta-text-sm ta-text-black cursor-pointer ta-absolute ta-left-2/4 -ta-translate-x-2/4 ta-bottom-3"
        }`}
        onClick={resetSearch}
      >
        Reset My Search
      </button>
      <BusyIndicator loading={loading} />
    </div>
  );
};
