export const removePrecision = (num: string | number) => {
  if (!num) return undefined;
  if (typeof num === "string") {
    return parseInt(num).toFixed(0);
  }
  return num.toFixed(0);
};

export const addPrecision = (num: string | number, precision = 2) => {
  if (!num) return undefined;
  if (typeof num === "string") {
    return parseInt(num).toFixed(precision);
  }
  return num.toFixed(precision);
};
