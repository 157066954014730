import { Tire } from "types/tire";

export const isCategorieInList = (tire: Tire, category: string[]) => {
  for (let i = 0; i < category.length; i++) {
    if (tire[category[i].replace(/\s/g, "") as keyof Tire]) {
      return true;
    }
  }
  return false;
};
