import { priceFormatter } from "helpers/pricing";
import { warrantyFormatter } from "helpers/warrantyFormatter";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { Dialog } from "components/dialog/dialog";
import { InputNumber } from "components/inputNumber/inputNumber";
import { Rebates } from "components/rebate/rebate";
import { SearchOptions } from "types/search";
import { Tire } from "types/tire";
import { TiresAnytimeRoutes } from "routes";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { useEcommerceContext } from "context/ecommerce";
import { useNavigate } from "react-router-dom";
import { useSearchContext } from "context/search";
import { useState } from "react";
import { StaggeredFitmentInfo } from "components/compareCard/staggeredFitmentInfo";
import { removePrecision, addPrecision } from "helpers/precisionHelper";

interface Props {
  tire: Tire;
}

export const CompareCard = ({ tire }: Props) => {
  const navigate = useNavigate();
  const { addToCart } = useEcommerceContext();
  const { clearComparedTires, searchedTires } = useSearchContext();
  // State to manage tires quantity in compare page
  const [count, setCount] = useState<number>(4);
  // State to manage open & close rebates dialog
  const [open, setOpen] = useState<boolean>(false);
  const { loading, load } = useBusyIndicator();

  // Add to cart button, call addToCart api, navigate to cart page & clear compared tires context state
  const addToCartClick = async () => {
    load();
    await addToCart({ ...tire, QuantityToAdd: count }, {} as SearchOptions);
    navigate(TiresAnytimeRoutes.Cart);
    clearComparedTires();
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fitmentSearchPerformed = searchedTires?.IsSplitFitment;

  const navigateToProductDetailsPage = () => {
    navigate(TiresAnytimeRoutes.ProductDetails, { state: tire });
  };

  const hasRebate = tire.Rebates && tire.Rebates.length > 0 ? true : false;

  return (
    <div className="ta-flex ta-flex-col ta-mb-4 md:ta-mb-0">
      <div className="ta-bg-white ta-border-[.5px] ta-border-[#bbb9b9] ta-shadow-[0_1px_3px_rgba(0,0,0,.30)] ta-mb-2 ta-text-base ta-text-center ta-py-4 md:ta-relative md:ta-h-[91%]">
        <img
          src={tire.BrandImageUrl}
          alt={tire.ManufacturerName}
          className="ta-w-auto ta-mx-auto ta-h-[25px]"
        />

        <h2 className="!ta-bg-transparent ta-tracking-normal ta-font-bold ta-text-black ta-normal-case ta-text-lg md:ta-text-base xl:ta-text-lg ta-p-1">
          {tire.ProductName}
        </h2>

        <img
          src={tire.ImageUrl}
          alt={tire.ProductName}
          className="ta-w-auto ta-mx-auto ta-mb-4 ta-h-[145px]"
        />

        <div className="ta-flex ta-items-end ta-justify-center ta-mb-4 ta-gap-2 ta-compare-product__reviews">
          <div>Ratings:</div>
          <div
            id={`pr-cmp-${tire.PageId}-${tire.ManufacturerPartNumber}`}
          ></div>
        </div>

        {fitmentSearchPerformed && (
          <>
            <StaggeredFitmentInfo
              tire={tire}
              navigateToProductDetailsPage={navigateToProductDetailsPage}
            />
          </>
        )}

        {!fitmentSearchPerformed && (
          <>
            <div className="ta-bg-[#D3D3D3] ta-p-2">
              Item#:{" "}
              <span className="ta-underline">
                {tire.ManufacturerPartNumber}
              </span>
            </div>

            <div className="ta-p-2">
              Tire Size:{" "}
              <span className="ta-font-bold">{tire.DisplayTireSize}</span>
            </div>

            <div className="ta-bg-[#D3D3D3] ta-p-2">
              Load Rating:{" "}
              <span className="ta-font-bold">
                {removePrecision(tire.LoadRating)}
              </span>
            </div>

            <div className="ta-p-2">
              Speed Rating:{" "}
              <span className="ta-font-bold">{tire.SpeedRating}</span>
            </div>
            <div className="ta-bg-[#D3D3D3] ta-p-2">
              UTQG: <span className="ta-font-bold">{tire.UTQG}</span>
            </div>
            <div className="ta-p-2">
              Mileage Warranty:{" "}
              <span className="ta-font-bold">
                {warrantyFormatter(tire.Warranty)}
              </span>
            </div>

            <div className="ta-bg-[#D3D3D3] ta-p-2">
              Tread Depth:{" "}
              <span className="ta-font-bold">
                {addPrecision(tire.TreadDepth)}
              </span>
            </div>

            <div className="ta-p-2">
              Sidewall: <span className="ta-font-bold">{tire.SideWall}</span>
            </div>

            <div className="ta-bg-[#D3D3D3] ta-p-2">
              Load Ply:{" "}
              <span className="ta-font-bold">
                {tire.LoadCapacity === "SL" ? "None" : tire.LoadCapacity}
              </span>
            </div>

            <div className="ta-p-2">
              Tire Weight: <span className="ta-font-bold">{tire.Weight}</span>
            </div>

            <div className="ta-bg-[#D3D3D3] ta-p-2">
              Max PSI:{" "}
              <span className="ta-font-bold">
                {tire.MaxTirePressure && tire.MaxTirePressure !== ""
                  ? tire.MaxTirePressure
                  : "None"}
              </span>
            </div>
          </>
        )}

        <div className="ta-border-t ta-py-1">
          <span className="ta-text-2xl ta-font-bold">
            ${priceFormatter(tire.CalculatedPrice)} ea.
          </span>

          <div>
            {tire.InstallationText
              ? tire.InstallationText
              : `Installed: $
            ${priceFormatter(tire.InstallPrice + tire._finalPrice)}`}
          </div>
        </div>

        <div className={hasRebate ? "ta-text-sm ta-border-t ta-pt-2" : ""}>
          {hasRebate && (
            <div>
              <span className="ta-font-bold">Mail-In Rebate:</span>{" "}
              {tire.Rebates[0]?.RebateDisplayText}
            </div>
          )}

          {hasRebate ? (
            <>
              <CustomIconButton
                text={"Click here for form"}
                onClick={handleOpen}
                classes="ta-text-link ta-underline hover:ta-no-underline"
              />
              <Dialog
                title=""
                content={<Rebates rebates={tire.Rebates[0]} />}
                open={open}
                onClose={handleClose}
              />
            </>
          ) : null}
        </div>
      </div>

      <div className="ta-mt-auto">
        {tire.CanAddToCart && (
          <>
            <div>
              <InputNumber
                count={count}
                setCount={setCount}
                classes="ta-w-full ta-border ta-border-[#909090] ta-font-bold ta-text-center ta-p-2.5 ta-mt-4"
                dualsForStaggeredFitment={fitmentSearchPerformed}
              />
            </div>
            <button
              type="button"
              className="ta-bg-button ta-text-white ta-text-sm ta-font-bold ta-rounded-[3px] ta-py-2.5 ta-w-full ta-mt-2"
              data-id={tire.TireId}
              onClick={addToCartClick}
            >
              Add to Cart
            </button>
          </>
        )}
        {!tire.CanAddToCart && tire.OutOfStockText !== "" && (
          <div className="ta-print-none ta-rounded-[3px] ta-mt-6 ta-px-2 ta-py-4 ta-text-base ta-font-bold ta-w-full md:ta-text-right md:ta-mt-2 md:ta-p-2 md:ta-pr-0">
            {tire.OutOfStockText}
          </div>
        )}
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};
