import { useNavigate } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { backToFinder } from "constants/index";
import { useEcommerceContext } from "context/ecommerce";
import { Dialog } from "components/dialog/dialog";
import { WishlistSignIn } from "components/wishlistSignIn/wishlistSignIn";
import { useState } from "react";
import { WishlistCustomer } from "types/wishlist";

export const SearchTabFooterButtons = () => {
  const { getWishlistOrdersForAccount, wishlistsAndOrders } =
    useEcommerceContext();
  const [openWishlist, setOpenWishlist] = useState<boolean>(false);

  const navigate = useNavigate();

  const goToCartPage = () => {
    // If user selects cart from finder page, go to cart page
    // Set router state for cart page to come back to finder page for back button
    navigate(TiresAnytimeRoutes.Cart, {
      state: {
        backToText: backToFinder,
        backToRoute: TiresAnytimeRoutes.Home,
      },
    });
  };

  const onWishlistClick = () => {
    // If user selects wishlist button, check if ecommerce context state already has
    // user wishlist from previous get wishlist call, then navigate to user wishlist page
    // Otherwise prompt wishlist sign in dialog for user to sign in
    if (wishlistsAndOrders && wishlistsAndOrders.Wishlists) {
      navigate(TiresAnytimeRoutes.UserWishlist);
      return;
    }
    setOpenWishlist(true);
  };

  const submitSignInToGetUserAccount = async (customer: WishlistCustomer) => {
    // get user wishlist
    if (customer.Email && customer.PostalCode) {
      await getWishlistOrdersForAccount(customer.Email, customer.PostalCode);
      navigate(TiresAnytimeRoutes.UserWishlist);
    }
  };

  return (
    <div
      className={`ta-bg-lightgrey ta-flex ta-justify-between ta-px-8 ta-pb-3`}
    >
      <Dialog
        title=""
        content={
          <WishlistSignIn
            submitFormForWishlistCreation={submitSignInToGetUserAccount}
          />
        }
        open={openWishlist}
        onClose={() => setOpenWishlist(false)}
      />
      <button
        type="button"
        className={`ta-flex ta-items-center ta-text-sm ta-text-[#696969] ta-underline cursor-pointer`}
        onClick={onWishlistClick}
      >
        <span className="ta-mr-2">Wish List</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.714"
          height="12"
          viewBox="0 0 13.714 12"
        >
          <path
            id="Icon_awesome-gift"
            data-name="Icon awesome-gift"
            d="M.857,13.393a.856.856,0,0,0,.857.857H6V9.964H.857Zm6.857.857H12a.856.856,0,0,0,.857-.857V9.964H7.714Zm5.143-8.571H11.729A2.336,2.336,0,0,0,12,4.607,2.361,2.361,0,0,0,9.643,2.25c-1.114,0-1.835.571-2.759,1.829C5.96,2.821,5.239,2.25,4.125,2.25A2.361,2.361,0,0,0,1.768,4.607a2.3,2.3,0,0,0,.271,1.071H.857A.856.856,0,0,0,0,6.536V8.679a.43.43,0,0,0,.429.429H13.286a.43.43,0,0,0,.429-.429V6.536A.856.856,0,0,0,12.857,5.679Zm-8.735,0a1.071,1.071,0,0,1,0-2.143c.533,0,.927.088,2.306,2.143Zm5.521,0H7.337C8.713,3.629,9.1,3.536,9.643,3.536a1.071,1.071,0,0,1,0,2.143Z"
            transform="translate(0 -2.25)"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        type="button"
        className={`ta-flex ta-items-center ta-text-sm ta-text-[#696969] ta-underline cursor-pointer`}
        onClick={goToCartPage}
      >
        <span className="ta-mr-2">Cart</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.952"
          height="12"
          viewBox="0 0 12.952 12"
        >
          <path
            id="Path_29"
            data-name="Path 29"
            d="M16.9,6.24,6.59,6.5l-.4-1.028H4l.45.72h.67L6.8,10.7a1.614,1.614,0,0,0-.618,1.283A1.552,1.552,0,0,0,6.7,13.151a1.86,1.86,0,0,0,1.275.475h8.972V12.4H7.924a.463.463,0,0,1-.447-.412.386.386,0,0,1,.278-.358h0L16.9,9.881Z"
            transform="translate(-4 -5.47)"
            fill="currentColor"
          />
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="1.678"
            cy="1.678"
            r="1.678"
            transform="translate(3.356 8.644)"
            fill="currentColor"
          />
          <circle
            id="Ellipse_2"
            data-name="Ellipse 2"
            cx="1.678"
            cy="1.678"
            r="1.678"
            transform="translate(9.527 8.644)"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  );
};
