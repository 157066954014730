import { CartItem } from "types/cart";
import { Tire } from "types/tire";
import { SearchedTires } from "types/search";
import { Details } from "types/wishlist";

export const getDisplayPrice = (item: CartItem) => {
  let _displayPrice = 0;
  if (item.Tire) {
    if (
      !item.Tire.ShowMarkupPrice ||
      item.Tire.MAPPrice <= item.Tire.CalculatedPrice
    ) {
      if (
        !item.IsWarranty &&
        item.Tire.HasDiscount &&
        item.Tire.DiscountType === 2
      ) {
        _displayPrice = item.Tire.CalculatedPrice;
      }
      if (
        !item.IsWarranty &&
        (!item.Tire.HasDiscount ||
          (item.Tire.HasDiscount && item.Tire.DiscountType === 2))
      ) {
        _displayPrice = item.Tire.CalculatedPrice - item.Tire.DiscountAmount;
      }
      if (
        !item.IsWarranty &&
        item.Tire.HasDiscount &&
        item.Tire.DiscountType !== 2
      ) {
        _displayPrice = item.Tire.CalculatedPrice;
      }
    }
    if (
      item.Tire.ShowMarkupPrice &&
      item.Tire.MAPPrice > item.Tire.CalculatedPrice
    ) {
      _displayPrice = item.Tire.MAPPrice;
    }
  }

  return _displayPrice;
};

// Get tire price
export const getTirePrice = (tire: Tire) => {
  let _finalPrice = 0;
  if (tire.ShowMarkupPrice && tire.MAPPrice > tire.CalculatedPrice) {
    _finalPrice = tire.MAPPrice;
  }
  if (!tire.ShowMarkupPrice || tire.MAPPrice <= tire.CalculatedPrice) {
    if (tire.CanDisplayCalculatedPrice && tire.DiscountAmount <= 0) {
      _finalPrice = tire.CalculatedPrice;
    }
    if (
      tire.CanDisplayCalculatedPrice &&
      tire.HasDiscount &&
      tire.DiscountAmount > 0
    ) {
      _finalPrice = tire.CalculatedPrice - tire.DiscountAmount;
    }
  }
  tire._finalPrice = _finalPrice;
};
// Get rear tire price
export const getRearTirePrice = (tire: Tire) => {
  let _finalPrice = 0;
  if (tire.ShowMarkupPrice && tire.MAPPrice > tire.CalculatedPrice) {
    _finalPrice = tire.MAPPrice;
  }
  if (!tire.ShowMarkupPrice || tire.MAPPrice <= tire.CalculatedPrice) {
    if (tire.CanDisplayCalculatedPrice && tire.DiscountAmount <= 0) {
      _finalPrice = tire.CalculatedPrice;
    }
    if (tire.CanDisplayCalculatedPrice && tire.DiscountAmount > 0) {
      _finalPrice = tire.CalculatedPrice;
    }
    if (tire.ShowMarkupPrice && !tire.CanDisplayCalculatedPrice) {
      _finalPrice = tire.MAPPrice;
    }
  }
  if (tire.ShowMarkupPrice && tire.RearMAPPrice > tire.RearCalculatedPrice) {
    _finalPrice = tire.RearMAPPrice;
  }
  if (!tire.ShowMarkupPrice || tire.MAPPrice <= tire.CalculatedPrice) {
    if (tire.CanDisplayRearCalculatedPrice && tire.RearDiscountAmount <= 0) {
      _finalPrice = tire.RearCalculatedPrice;
    }
    if (
      tire.CanDisplayCalculatedPrice &&
      tire.CanDisplayRearCalculatedPrice &&
      tire.HasDiscount &&
      tire.DiscountAmount > 0
    ) {
      _finalPrice = tire.CalculatedPrice - tire.DiscountAmount;
    }
    if (
      tire.CanDisplayRearCalculatedPrice &&
      tire.CanDisplayCalculatedPrice &&
      tire.HasDiscount &&
      tire.RearDiscountAmount > 0
    ) {
      _finalPrice = tire.RearCalculatedPrice - tire.RearDiscountAmount;
    }
  }

  tire._rearFinalPrice = _finalPrice;
};

// Get pricing for list of tires
export const getPricing = (searchedTires: SearchedTires): SearchedTires => {
  if (!searchedTires) return searchedTires;
  for (let i = 0; i < searchedTires.AdditionalTires.TireResult.length; i++) {
    getTiresPrices(
      searchedTires.AdditionalTires.TireResult[i],
      searchedTires.IsSplitFitment
    );
  }
  if (searchedTires.StrategyTires && searchedTires.StrategyTires.TireResult) {
    for (let i = 0; i < searchedTires.StrategyTires.TireResult.length; i++) {
      getTiresPrices(
        searchedTires.StrategyTires.TireResult[i],
        searchedTires.IsSplitFitment
      );
    }
  }
  return searchedTires;
};

const getTiresPrices = (tire: Tire, isSplitFitment: boolean) => {
  getTirePrice(tire);
  if (isSplitFitment) {
    getRearTirePrice(tire);
  }
};

// format price to 99.99 pattern
export const priceFormatter = (price: number | undefined | null) => {
  return price && !Number.isNaN(price)
    ? price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : Number.isNaN(price) || price === undefined || price === null
    ? 0.0
    : price;
};
// calculate total discount per user cart
export const calculateCartDiscounts = (cartItems: CartItem[]) => {
  let discount = 0;
  cartItems.forEach((item: CartItem) => {
    if (item.Tire?.DiscountType === 2) {
      discount += item.Tire?.DiscountAmount * item.Quantity;
    } else {
      discount += item.Tire?.DiscountAmount ?? 0;
    }
  });
  return discount;
};

// calculate total discount per user wishlist
export const calculateWishlistDiscounts = (wishlistDetails: Details[]) => {
  let discount = 0;
  wishlistDetails.forEach((detail: Details) => {
    if (detail.DiscountType === 2) {
      discount += detail.DiscountAmount & detail.Quantity;
    } else {
      discount += detail.DiscountAmount ?? 0;
    }
  });
  return discount;
};
