import axios from "axios";

import { useAuth } from "../context/auth";

export const useHttpRequests = () => {
  const { token, loadToken } = useAuth();

  const fetcher = async (url: string) => {
    const resp = await axios.get(url, {
      headers: { Authorization: `Bearer ${token?.access_token}` },
    });
    // if we were redirected to login, either endpoint environment
    // changed or token expired
    if (resp.request.responseURL.includes(`Account/Login`)) {
      await loadToken(true);
    }
    return resp.data;
  };
  return { fetcher };
};
