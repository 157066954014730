import { useDealerContext } from "context/dealer";
import { LocationSelectionPage } from "pages/locationSelection/locationSelectionPage";
import { useState } from "react";
import { Dialog } from "components/dialog/dialog";

interface Props {
  withoutLocation?: boolean;
}

export const CurrentLocationCard = ({ withoutLocation = false }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { selectedDealerLocation } = useDealerContext();
  const changeLocation = () => {
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="ta-flex sm:ta-items-center">
      <div className={`ta-text-link ${withoutLocation ? "ta-hidden" : ""}`}>
        <button
          type="button"
          onClick={changeLocation}
          aria-label="change location"
          className="ta-text-tertiary ta-pr-[15px] ta-pt-[5px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            viewBox="0 0 18 24"
          >
            <path
              d="M8.075,23.516C1.264,13.642,0,12.629,0,9A9,9,0,0,1,18,9c0,3.629-1.264,4.642-8.075,14.516a1.125,1.125,0,0,1-1.85,0ZM9,12.75A3.75,3.75,0,1,0,5.25,9,3.75,3.75,0,0,0,9,12.75Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
      <div className="xl:ta-ml-3">
        <h1 className="ta-text-lg ta-text-black font-bold uppercase m-0 xl:ta-block">
          {selectedDealerLocation?.Name}
        </h1>

        <div className="ta-text-sm xl:ta-flex xl:ta-gap-[8px]">
          <div className="xl:ta-block">
            {selectedDealerLocation?.Address1}&nbsp;&nbsp;|&nbsp;&nbsp;
            {selectedDealerLocation?.City}, {selectedDealerLocation?.StateAbbr}{" "}
            {selectedDealerLocation?.PostalCode.split("-")[0]}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {selectedDealerLocation?.Phone}{" "}
          </div>
          <div className={withoutLocation ? "ta-hidden" : ""}>
            <button
              onClick={changeLocation}
              className="ta-underline ta-text-link xl:ta-inline"
            >
              Change Location
            </button>
          </div>
          <Dialog
            title=""
            content={
              <LocationSelectionPage
                hideBackButton={true}
                onClose={handleClose}
              />
            }
            open={open}
            onClose={handleClose}
          />
        </div>
      </div>
    </div>
  );
};
