import React, { useEffect, useState } from "react";

import { ApiToken } from "types/authentication";
import { requestNewToken } from "services/tokenService";
import { useContext } from "react";

export interface IAuth {
  token?: ApiToken | null | undefined;
  loading: boolean;
  dealerGroupId: string;
}

export interface IAuthContext extends IAuth {
  loadToken: (forceFetch?: boolean) => Promise<void>;
}

const defaultState: IAuthContext = {
  token: undefined,
  dealerGroupId: "",
  loading: true,
  loadToken: async () => {},
};

const defaultContext: IAuthContext = {
  ...defaultState,
};

export const AuthContext = React.createContext<IAuthContext>(defaultContext);

export type AuthProps = {
  dealerGroupId: string;
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProps> = ({
  dealerGroupId,
  children,
}) => {
  // Check session storage if token already stored
  const localToken = sessionStorage.getItem("ta-token");
  // State to manage token value
  const [token, setToken] = useState<ApiToken | null | undefined>(
    defaultState.token
  );
  // State to manage whether we are loading new token or not
  const [loading, setLoading] = useState(defaultState.loading);

  const loadToken = async (forceFetch = false) => {
    setLoading(true);
    // If token already exist, check if its expired request new token, otherwise token still valid
    if (localToken && !forceFetch) {
      const parsedToken = JSON.parse(localToken) as ApiToken;
      if (Date.now() < parsedToken.expires) {
        setLoading(false);
        setToken(parsedToken);
        return;
      }
    }
    // If token expired or no token exist in session storage, request new token & store to session storage
    const token = await requestNewToken(dealerGroupId);
    setToken(token);
    sessionStorage.setItem("ta-token", JSON.stringify(token));
    setLoading(false);
  };

  useEffect(() => {
    loadToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        dealerGroupId,
        loading,
        loadToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
