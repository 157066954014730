import { MouseEventHandler, useEffect } from "react";
import useFocus from "hooks/useFocus";

interface Props {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string | React.ReactNode;
  classes?: string;
  inActive?: boolean;
  showFocus?: boolean;
}

export const CustomIconButton = ({
  text,
  classes,
  onClick,
  children,
  inActive = false,
  showFocus,
}: Props) => {
  const [customButtonRef, setFocus] = useFocus();
  useEffect(() => {
    if (showFocus) {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFocus]);
  // Button that supports having child react components (svg, icons, etc)
  return (
    <button
      type="button"
      className={`${classes}`}
      onClick={onClick}
      disabled={inActive}
      ref={customButtonRef}
    >
      {children}
      <span>{text}</span>
    </button>
  );
};
