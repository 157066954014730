import { Appointment, BillingForm } from "types/checkout";
import { OrderInfo } from "types/wishlist";
import { convertDateToMMDDYYYY, convertToAmPM } from "./timeAndDateConverter";

export const placeOrderPayload = (
  billing: BillingForm,
  appointment: Appointment,
  paymentMethod: string,
  couponCode?: string
): OrderInfo => {
  const firstChoiceTime = convertToAmPM(appointment.firstChoiceTime);
  const secondChoiceTime = convertToAmPM(appointment.secondChoiceTime);
  const firstDateAndTime = `${convertDateToMMDDYYYY(
    appointment.firstChoiceDate
  )} ${firstChoiceTime}`;
  const secondDateAndTime = `${convertDateToMMDDYYYY(
    appointment.secondChoiceDate
  )} ${secondChoiceTime}`;
  return {
    Address1: billing.address,
    Address2: billing.aptSuite as string,
    Appointment1: firstDateAndTime,
    Appointment2: secondDateAndTime,
    City: billing.city,
    Email: billing.email,
    EmailConfirm: billing.confirmEmail,
    FirstChoiceAmPm: firstChoiceTime.includes("PM") ? "PM" : "AM",
    FirstChoiceDate: appointment.firstChoiceDate,
    FirstChoiceHour: firstChoiceTime.split(":")[0],
    FirstChoiceMinute: firstChoiceTime.split(":")[1].split(" ")[0],
    FirstName: billing.firstName,
    LastName: billing.lastName,
    PaymentMethod: paymentMethod,
    Phone: billing.phone,
    PostalCode: billing.zipCode,
    SecondChoiceAmPm: secondChoiceTime.includes("PM") ? "PM" : "AM",
    SecondChoiceHour: secondChoiceTime.split(":")[0],
    SecondChoiceMinute: secondChoiceTime.split(":")[1].split(" ")[0],
    SecondChoiceDate: appointment.secondChoiceDate,
    StateAbbr: billing.state,
    State: { Id: null, Description: null },
    couponCode: couponCode,
  };
};
