import {
  Cart,
  CartItem,
  IncludedItem,
  OrderDetails,
  ProductDetails,
} from "types/cart";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BackButton } from "components/backButton/backButton";
import { CartDetails } from "components/cartDetails/cartDetails";
import { CustomIconButton } from "components/customIconButton/customIconButton";
import { Dialog } from "components/dialog/dialog";
import { OrderTotalsCard } from "components/orderTotalsCard/orderTotalsCard";
import { TiresAnytimeRoutes } from "routes";
import { WishlistSignupPage } from "pages/wishlistSignup/wishlistSignupPage";
import { backToSearchResultsText } from "constants/index";
import { getDisplayPrice } from "helpers/pricing";
import { mapCartTotalsWithTotalsCards } from "helpers/mapCartTotalsWithTotalsCards";
import { useEcommerceContext } from "context/ecommerce";
import { useReactToPrint } from "react-to-print";

export const CartPage = () => {
  const {
    cart,
    removeTireFromCart,
    removeWarrantyFromCart,
    removeIncludedItemFromCart,
    removeAddOnFromCart,
    updateUserCartItems,
    clearCart,
  } = useEcommerceContext();
  // State to handle opening & closing dialog
  const [open, setOpen] = useState<boolean>(false);
  // State to handle whether wishlist button should be active or not
  const [inActive, setInActive] = useState<boolean>(true);
  // State to handle focus placement after removing items from cart
  const [showFocus, setShowFocus] = useState<boolean>(false);
  const [focusWishlist, setFocusWishlist] = useState<boolean>(false);

  useEffect(() => {
    // If cart is defined set wishlist button active, otherwise no
    if (cart && cart.CartItems.length > 0) {
      setInActive(false);
      return;
    }
    setInActive(true);
  }, [cart]);

  let backTo = backToSearchResultsText;
  let routeTo = TiresAnytimeRoutes.Search;
  const navigate = useNavigate();
  const { state } = useLocation();
  if (state) {
    // Router state to handle back button navigation
    // If cart is opened from finder page, back button is to finder page
    // Otherwise to search results
    const { backToText, backToRoute } = state;
    backTo = backToText;
    routeTo = backToRoute;
  }
  const componentRef = useRef(null);
  const navigateToCheckoutPage = () => {
    navigate(TiresAnytimeRoutes.Checkout);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFocusWishlist(true);
  };

  const onCartTireRemove = (id: number, type: string) => {
    if (type === "tireId") {
      removeTireFromCart(id);
    } else if (type === "addOnId") {
      removeAddOnFromCart(id);
    } else if (type === "warrantyId") {
      removeWarrantyFromCart(id);
    } else if (type === "includedId") {
      removeIncludedItemFromCart(id);
    }
    // focus is moving behind the modal on item removal
    // moving focus to the back button instead
    setShowFocus(true);
  };

  useEffect(() => {
    if (showFocus) {
      setShowFocus(false);
    }
    if (focusWishlist) {
      setFocusWishlist(false);
    }
  }, [showFocus, focusWishlist]);

  return (
    <>
      <div className="ta-flex ta-justify-center ta-flex-col ta-items-center ta-mb-5 sm:ta-flex-row sm:ta-justify-end">
        <div className="ta-mr-auto">
          <BackButton
            text={backTo}
            navigation={routeTo}
            focusButton={showFocus}
            classes={"ta-bg-transparent"}
          />
        </div>
        <div className="ta-flex ta-flex-col sm:ta-flex-row ta-items-center ta-text-center ta-gap-2">
          <div className="ta-order-last sm:ta-order-1">
            <CustomIconButton
              classes={
                "ta-flex ta-gap-2 sm:ta-items-center ta-justify-center ta-bg-white ta-text-button hover:ta-bg-button hover:ta-text-white ta-font-bold ta-text-sm sm:ta-border sm:ta-border-button ta-rounded-[3px] sm:ta-p-2 sm:ta-pl-4 sm:ta-pr-4 disabled:ta-text-grey disabled:ta-border-grey disabled:ta-bg-white"
              }
              text={<>Clear Cart</>}
              onClick={clearCart}
              children={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.5"
                  height="12"
                  viewBox="0 0 10.5 12"
                  className="ta-mr-2 ta-order-last ta-m-auto"
                >
                  <path
                    id="Icon_awesome-trash"
                    data-name="Icon awesome-trash"
                    d="M10.125.75H7.312L7.092.312A.563.563,0,0,0,6.588,0H3.909a.556.556,0,0,0-.5.312L3.187.75H.375A.375.375,0,0,0,0,1.125v.75a.375.375,0,0,0,.375.375h9.75a.375.375,0,0,0,.375-.375v-.75A.375.375,0,0,0,10.125.75Zm-8.878,10.2A1.125,1.125,0,0,0,2.37,12H8.13a1.125,1.125,0,0,0,1.123-1.055L9.75,3h-9Z"
                    fill="currentColor"
                  />
                </svg>
              }
            />
          </div>
          <div className="ta-flex ta-gap-2">
            <CustomIconButton
              classes={
                "ta-flex ta-items-center ta-justify-center ta-bg-white ta-text-button hover:ta-bg-button hover:ta-text-white ta-font-bold ta-text-sm ta-border ta-border-button ta-rounded-[3px] ta-p-2 ta-pl-4 ta-pr-4 disabled:ta-text-grey disabled:ta-border-grey disabled:ta-bg-white"
              }
              text={<>Add To Wish List</>}
              children={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.714"
                  height="12"
                  viewBox="0 0 13.714 12"
                  aria-hidden="true"
                  className="ta-mr-2"
                >
                  <path
                    d="M.857,13.393a.856.856,0,0,0,.857.857H6V9.964H.857Zm6.857.857H12a.856.856,0,0,0,.857-.857V9.964H7.714Zm5.143-8.571H11.729A2.336,2.336,0,0,0,12,4.607,2.361,2.361,0,0,0,9.643,2.25c-1.114,0-1.835.571-2.759,1.829C5.96,2.821,5.239,2.25,4.125,2.25A2.361,2.361,0,0,0,1.768,4.607a2.3,2.3,0,0,0,.271,1.071H.857A.856.856,0,0,0,0,6.536V8.679a.43.43,0,0,0,.429.429H13.286a.43.43,0,0,0,.429-.429V6.536A.856.856,0,0,0,12.857,5.679Zm-8.735,0a1.071,1.071,0,0,1,0-2.143c.533,0,.927.088,2.306,2.143Zm5.521,0H7.337C8.713,3.629,9.1,3.536,9.643,3.536a1.071,1.071,0,0,1,0,2.143Z"
                    transform="translate(0 -2.25)"
                    fill="currentColor"
                  />
                </svg>
              }
              showFocus={focusWishlist}
              onClick={handleOpen}
              inActive={inActive}
            />
            <Dialog
              title=""
              content={<WishlistSignupPage />}
              open={open}
              onClose={handleClose}
            />
            <CustomIconButton
              classes={
                "ta-flex ta-items-center ta-justify-center ta-text-white ta-bg-button ta-font-bold ta-text-sm ta-border ta-border-button ta-rounded-[3px] ta-p-2 ta-pl-6 ta-pr-6 hover:ta-underline disabled:ta-bg-grey disabled:ta-border-grey disabled:ta-no-underline"
              }
              text={"Print"}
              children={
                <svg
                  className="ta-w-[12px] ta-h-[12px] ta-fill-current ta-mr-2"
                  viewBox="0 0 26 28"
                >
                  <path d="M6 24h14v-4h-14v4zM6 14h14v-6h-2.5c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-10v10zM24 15c0-0.547-0.453-1-1-1s-1 0.453-1 1 0.453 1 1 1 1-0.453 1-1zM26 15v6.5c0 0.266-0.234 0.5-0.5 0.5h-3.5v2.5c0 0.828-0.672 1.5-1.5 1.5h-15c-0.828 0-1.5-0.672-1.5-1.5v-2.5h-3.5c-0.266 0-0.5-0.234-0.5-0.5v-6.5c0-1.641 1.359-3 3-3h1v-8.5c0-0.828 0.672-1.5 1.5-1.5h10.5c0.828 0 1.969 0.469 2.562 1.062l2.375 2.375c0.594 0.594 1.062 1.734 1.062 2.562v4h1c1.641 0 3 1.359 3 3z"></path>
                </svg>
              }
              onClick={handlePrint}
              inActive={inActive}
            />
          </div>
        </div>
      </div>
      <div
        className="xl:ta-grid xl:ta-grid-cols-cartGrid xl:ta-gap-3.5"
        ref={componentRef}
      >
        <div className="ta-border ta-border-black ta-shadow-md">
          <CartDetails
            productDetails={mapCartToProductDetailsGrid(cart)}
            updateQuantity={updateUserCartItems}
            removeTire={onCartTireRemove}
            removeIncludedItem={onCartTireRemove}
            removeAddOn={onCartTireRemove}
            removeWarranty={onCartTireRemove}
          />
        </div>

        <div className="ta-border ta-border-black ta-shadow-md ta-mt-5 ta-pl-2.5 ta-pr-2.5 ta-pt-10 ta-pb-5 xl:ta-mt-0">
          <OrderTotalsCard orderTotals={mapCartTotalsWithTotalsCards(cart)}>
            <CustomIconButton
              text={<span className="ta-ml-2.5">Checkout</span>}
              classes={
                "ta-print-none ta-flex ta-items-center ta-justify-center ta-border ta-border-button ta-bg-button ta-text-white ta-w-full ta-font-bold ta-text-2xl ta-rounded-[3px] ta-p-4 ta-mt-12 xl:ta-mt-16 disabled:ta-bg-grey disabled:ta-border-grey disabled:ta-text-white"
              }
              children={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.084"
                  height="18.732"
                  viewBox="0 0 24.084 18.732"
                >
                  <path
                    id="Icon_awesome-credit-card"
                    data-name="Icon awesome-credit-card"
                    d="M0,18.975a2.008,2.008,0,0,0,2.007,2.007h20.07a2.008,2.008,0,0,0,2.007-2.007V11.616H0Zm8.028-2.843a.5.5,0,0,1,.5-.5h5.686a.5.5,0,0,1,.5.5V17.8a.5.5,0,0,1-.5.5H8.53a.5.5,0,0,1-.5-.5Zm-5.352,0a.5.5,0,0,1,.5-.5h3.01a.5.5,0,0,1,.5.5V17.8a.5.5,0,0,1-.5.5H3.178a.5.5,0,0,1-.5-.5ZM24.084,4.257V6.264H0V4.257A2.008,2.008,0,0,1,2.007,2.25h20.07A2.008,2.008,0,0,1,24.084,4.257Z"
                    transform="translate(0 -2.25)"
                    fill="currentColor"
                  />
                </svg>
              }
              inActive={inActive}
              onClick={navigateToCheckoutPage}
            />
          </OrderTotalsCard>
        </div>
      </div>
    </>
  );
};

const mapCartToProductDetailsGrid = (
  cart: Cart | null | undefined
): ProductDetails => {
  const order =
    cart && cart.CartItems
      ? cart.CartItems.map((item: CartItem): OrderDetails => {
          return {
            DisplayPrice: getDisplayPrice(item),
            ImageUrl: item.Tire?.ImageUrl,
            TireSize: item.Tire?.DisplayTireSize,
            PartNumber: item.Tire?.ManufacturerPartNumber,
            ProductName: item.Tire?.ProductName,
            ManufacturerName: item.Tire?.ManufacturerName,
            Quantity: item.Quantity,
            TireId: item.Tire?.TireId,
            IsWarranty: item.IsWarranty,
            WarrantyDescription: item.WarrantyDescription,
            WarrantyImageUrl: item.WarrantyImageUrl,
            WarrantyPrice: item.WarrantyPrice,
            IsQuoteAddOn: item.IsQuoteAddOn,
            AddOnId: item.AddOnId,
            AddOnPrice: item.AddOnPrice,
            AddOnDescription: item.AddOnDescription,
            IsAddOnPriceRequired: item.IsAddOnPriceRequired,
          };
        })
      : ([] as OrderDetails[]);
  return {
    Order: order,
    IncludedItems:
      cart && cart.IncludedItems ? cart.IncludedItems : ([] as IncludedItem[]),
  };
};
