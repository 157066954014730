import { CartPage } from "pages/cart/cartPage";
import { FinderPage } from "./pages/finder/finderPage";
import { SearchedResultPage } from "pages/searchedResult/searchedResultPage";
import { WishlistPage } from "pages/wishlist/wishlistPage";
import { MemoryRouter, Route, Routes } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { ModalWrapper } from "components/modalWrapper/modalWrapper";
import { ProductDetailsPage } from "pages/productDetails/productDetailsPage";
import { CheckoutPage } from "pages/checkout/checkoutPage";
import { ConfirmationPage } from "pages/confirmation/confirmationPage";
import { ComparePage } from "pages/compare/comparePage";
import { UserWishlistsPage } from "pages/userWishlists/userWishlistsPage";
import { SearchResultEmptyPage } from "pages/searchResultEmpty/searchResultEmptyPage";
import { CustomerContactConfirmationPage } from "pages/customerContactConfirmation/customerContactConfirmationPage";
import { PayPalForm } from "components/payPalForm/payPalForm";

function App() {
  return (
    <>
      <MemoryRouter>
        <Routes>
          <Route path={TiresAnytimeRoutes.Home} element={<FinderPage />} />
          <Route
            path={TiresAnytimeRoutes.Search}
            element={
              <ModalWrapper title="" component={<SearchedResultPage />} />
            }
          />
          <Route
            path={TiresAnytimeRoutes.EmptySearchResults}
            element={
              <ModalWrapper
                title="No Results"
                component={<SearchResultEmptyPage />}
                showCart={false}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.ContactInfoConfirmation}
            element={
              <ModalWrapper
                title="Contact Information Confirmation"
                component={<CustomerContactConfirmationPage />}
                showCart={false}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.Cart}
            element={
              <ModalWrapper
                showCart={false}
                title={"Your Cart"}
                component={<CartPage />}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.Checkout}
            element={
              <ModalWrapper
                title={"Checkout"}
                showCart={false}
                component={<CheckoutPage />}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.Wishlist}
            element={
              <ModalWrapper
                title={"Wishlist"}
                component={<WishlistPage />}
                showCart={false}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.ProductDetails}
            element={
              <ModalWrapper
                title={""}
                component={<ProductDetailsPage />}
                showSiteHeader={false}
                classes="md:ta-px-6 lg:ta-w-[1200px]"
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.Confirmation}
            element={
              <ModalWrapper
                title={"Success!"}
                component={<ConfirmationPage />}
                showCart={false}
                withoutLocation={true}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.Compare}
            element={
              <ModalWrapper
                title={"Compare Products"}
                component={<ComparePage />}
                showSiteHeader={false}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.UserWishlist}
            element={
              <ModalWrapper
                title=""
                component={<UserWishlistsPage />}
                showSiteHeader={false}
              />
            }
          />
          <Route
            path={TiresAnytimeRoutes.payPalCheckout}
            element={
              <ModalWrapper
                title=""
                component={<PayPalForm />}
                showSiteHeader={false}
              />
            }
          />
        </Routes>
      </MemoryRouter>
    </>
  );
}

export default App;
