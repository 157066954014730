import { toast, ToastOptions } from "react-hot-toast";

export const Toaster = (
  message: string,
  isSuccess = false,
  options?: ToastOptions
) => {
  if (isSuccess) {
    toast.success(message, options);
    return;
  }
  toast.error(message, options);
};
