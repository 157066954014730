import React from "react";
import { components } from "react-select";

const { Option } = components;

const GridOption = (props: any) => (
  <Option {...props}>
    <div style={{ display: "inline-block", width: "100%" }}>{props.label}</div>
  </Option>
);

export default GridOption;
