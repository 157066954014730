import { useLayoutEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };
  const debouncedUpdateSize = useDebounce(() => updateSize(), [], 50);
  useLayoutEffect(() => {
    window.addEventListener("resize", debouncedUpdateSize);
    debouncedUpdateSize();
    return () => window.removeEventListener("resize", debouncedUpdateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return size;
};
