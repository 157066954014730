import dayjs from "dayjs";
export const convertToMilitary = (hour: string) => {
  return dayjs(`1/1/1 ${hour}`).format("HH:mm");
};

export const convertToAmPM = (hour: string) => {
  return dayjs(`1/1/1 ${hour}`).format("h:mm A");
};

export const convertDateToDay = (date: string) => {
  return dayjs(date).format("dddd");
};

export const timeDiff = (time1: string, time2: string, date: string) => {
  return parseFloat(
    dayjs(`${date} ${time1}`).diff(`${date} ${time2}`, "hour", true).toFixed(2)
  );
};

export const convertDateToMMDDYYYY = (date: string) => {
  return dayjs(date).format("MM/DD/YYYY");
};
