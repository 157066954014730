import {
  AllSearchedTires,
  Filters,
  Manufacturer,
  MileageWarrantiesRange,
  Model,
  Option,
  PriceRange,
  SearchParams,
  SearchProps,
  SearchedTires,
  TireBySizeData,
  Year,
  SortBy,
  SortOptions,
} from "../types/search";
import React, { useContext, useEffect, useState } from "react";
import { isPriceInRange, isWarrantyInRange } from "../helpers/isValueInRange";
import {
  findSortByDefaultLabel,
  findSortOption,
  mapSortingOption,
} from "helpers/sortingOptions";
import { Tire } from "types/tire";
import { States } from "types/search";
import { Filter } from "types/filters";
import { Dealer } from "types/dealer";
import { get } from "../services/apiServices";
import { useAuth } from "./auth";
import { useBackendData } from "../hooks/useBackendData";
import { useDealerContext } from "./dealer";
import { isCategorieInList } from "helpers/isCategorieInList";
import { getPricing } from "helpers/pricing";

export interface ISearch {
  manufacturer: Manufacturer[] | undefined;
  years: Year[] | undefined;
  models: Model[] | undefined;
  options: Option[] | undefined;
  states: States[] | undefined;
  searchedTires: SearchedTires | undefined;
  filteredAndSortedTires: AllSearchedTires;
  filters: Filters;
  tireBySize: TireBySizeData;
  searchParamsData: SearchParams | undefined;
  comparedTires: Tire[];
  vehicles: Option[] | undefined;
  sortDefault: string;
}

export interface ISearchContext extends ISearch {
  getManufacturerByYear: (year: number) => void;
  getModels: (carManufacturer: string, carYear: number) => void;
  getOptions: (
    carManufacturer: string,
    carYear: number,
    carModel: string
  ) => void;
  resetManufacturerAndModelsAndOptions: () => void;
  searchTires: (
    searchParams: SearchParams,
    dealerId?: number
  ) => Promise<SearchedTires | undefined>;
  searchTiresByPlate: (plate: string, state: string) => Promise<void>;
  filterTires?: () => void;
  addFilterByCategorie: (type: string) => void;
  addFilterByBrand: (brandId: number) => void;
  addFilterBySpeedRating: (speedRating: string) => void;
  addFilterByMileageWarrantiesRange: (
    warrantyRange: MileageWarrantiesRange
  ) => void;
  addFilterByPriceRanges: (priceRange: PriceRange) => void;
  removeCategorie: (categorie: string) => void;
  removeBrandId: (brandId: number) => void;
  removeSpeedRating: (speedRating: string) => void;
  removePriceRange: (priceRange: PriceRange) => void;
  removeWarrantyRange: (warrantyRange: MileageWarrantiesRange) => void;
  sortingBy: (key: keyof Tire, ascending: boolean) => void;
  compareTires: (tireIds: number) => void;
  removeComparedTires: (tireIds: number) => void;
  clearComparedTires: () => void;
  getRatios: (width: string, key: keyof TireBySizeData) => void;
  getRims: (
    width: string,
    key: keyof TireBySizeData,
    aspRatio?: string
  ) => Promise<void>;
  resetRatioAndRims: (
    ratio?: keyof TireBySizeData,
    rim?: keyof TireBySizeData
  ) => void;
  clearVehicles: () => void;
  clearFilters: (filter: keyof Filters) => void;
  clearAllFilters: () => void;
}

const defaultState: ISearchContext = {
  manufacturer: undefined,
  years: undefined,
  models: undefined,
  options: undefined,
  states: undefined,
  vehicles: undefined,
  searchedTires: undefined,
  searchParamsData: undefined,
  sortDefault: "",
  filteredAndSortedTires: { TireResult: [], StrategyTires: null },
  filters: {
    categorie: [],
    brandIds: [],
    priceRanges: [],
    mileageWarrantiesRanges: [],
    speedRatings: [],
  },
  comparedTires: [],
  filterTires: () => {},
  getManufacturerByYear: () => {},
  getModels: () => {},
  getOptions: () => {},
  resetManufacturerAndModelsAndOptions: () => {},
  searchTires: async () => {
    return {} as SearchedTires | undefined;
  },
  searchTiresByPlate: async () => {},
  addFilterByCategorie: () => {},
  addFilterByBrand: () => {},
  addFilterBySpeedRating: () => {},
  addFilterByMileageWarrantiesRange: () => {},
  addFilterByPriceRanges: () => {},
  removeCategorie: () => {},
  removeBrandId: () => {},
  removeSpeedRating: () => {},
  removePriceRange: () => {},
  removeWarrantyRange: () => {},
  sortingBy: () => {},
  compareTires: () => {},
  removeComparedTires: () => {},
  clearComparedTires: () => {},
  tireBySize: {} as TireBySizeData,
  getRatios: async () => {},
  getRims: async () => {},
  resetRatioAndRims: (
    ratio?: keyof TireBySizeData,
    rim?: keyof TireBySizeData
  ) => {},
  clearVehicles: () => {},
  clearFilters: (filter: keyof Filters) => {},
  clearAllFilters: () => {},
};

const defaultContext: ISearchContext = {
  ...defaultState,
};

export const SearchContext =
  React.createContext<ISearchContext>(defaultContext);

export const SearchProvider: React.FC<SearchProps> = ({ children }) => {
  const { token, dealerGroupId } = useAuth();
  const { selectedDealerLocation, selectedDealerGroup } = useDealerContext();
  // Get all available years for vehicles
  const { data: years } = useBackendData<Year[]>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetAllYears`
  );
  // Get 50 States from api
  const { data: states } = useBackendData<States[]>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetStateOptions`
  );
  // Get sort options
  const { data: sortOptions } = useBackendData<SortOptions[]>(
    `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetTireSortOptions`
  );

  //#region State to manage search by vehicle, by tire size, by plate & filters
  const [manufacturer, setManufacturer] = useState<Manufacturer[] | undefined>(
    defaultState.manufacturer
  );
  const [models, setModels] = useState<Model[] | undefined>(
    defaultState.models
  );
  const [options, setOptions] = useState<Option[] | undefined>(
    defaultState.options
  );
  const [tireBySize, setTireBySize] = useState<TireBySizeData>(
    {} as TireBySizeData
  );
  const [searchParamsData, setSearchParamsData] = useState<
    SearchParams | undefined
  >(defaultState.searchParamsData);
  const [searchedTires, setSearchedTires] = useState<SearchedTires | undefined>(
    undefined
  );
  // search by plate returns list of vehicles, store in this state
  const [vehicles, setVehciles] = useState<Option[] | undefined>(undefined);
  const [filteredAndSortedTires, setFilteredTires] = useState<AllSearchedTires>(
    defaultState.filteredAndSortedTires
  );
  const [filters, setFilters] = useState<Filters>(defaultState.filters);
  const [comparedTires, setComparedTires] = useState<Tire[]>([]);
  // State to manage the user selection to sort. This perserves sort across filter changes
  const [sortedBy, setSortedBy] = useState<SortBy | undefined>(undefined);
  // Set default sort value
  const [sortDefault, setSortedDefault] = useState<string>("");
  //#endregion
  // set search results
  const allSearchedTires: AllSearchedTires | undefined = {
    TireResult: searchedTires?.AdditionalTires.TireResult,
    StrategyTires: searchedTires?.StrategyTires as {
      HasSecondaryWarehouse: boolean;
      TireResult: Tire[];
    } | null,
  };

  useEffect(() => {
    // If searched tires changed, we know we have a new search, reset filters
    // This will only be called when search api is called, which then renders
    // The search results page.

    // Get pricing calculated for tires
    if (searchedTires) {
      setSearchedTires(getPricing(searchedTires));
    }
    // on initial search results render we want to sort
    // results page by default sort dealer has selected or warranty desc. We will call sort function & let the sort
    // function update the state for filteredAndSortedTires with sorted list
    const sortDefaultLabel = findSortByDefaultLabel(
      selectedDealerGroup as Dealer,
      sortOptions as SortOptions[]
    );
    setSortedDefault(sortDefaultLabel as string);
    const { field, direction } = mapSortingOption(
      findSortOption(sortDefaultLabel as string) as string
    );
    sortingBy(field as keyof Tire, direction === "asc", allSearchedTires);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedTires, selectedDealerGroup]);

  useEffect(() => {
    // If filters changed, call filterTires to apply filters
    filterTires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const headers = {
    headers: { Authorization: `Bearer ${token?.access_token}` },
  };

  //#region Search by vehicle asyncronous api calls
  const getManufacturerByYear = async (year: number) => {
    const resp = await get<Manufacturer[]>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetManufacturersByYear?year=${year}`,
      headers
    );
    setManufacturer(resp?.data);
  };

  const getModels = async (carManufacturer: string, carYear: number) => {
    const resp = await get<Model[]>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetModels?carManufacturer=${carManufacturer}&carYear=${carYear}`,
      headers
    );
    setModels(resp?.data);
  };

  const getOptions = async (
    carManufacturer: string,
    carYear: number,
    carModel: string
  ) => {
    const resp = await get<Option[] | undefined>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetOptions?carManufacturer=${carManufacturer}&carYear=${carYear}&carModel=${carModel}`,
      headers
    );
    setOptions(resp?.data);
  };

  // reset search by vehicle options if user selects reset search
  const resetManufacturerAndModelsAndOptions = () => {
    setManufacturer(undefined);
    setModels(undefined);
    setOptions(undefined);
  };
  //#endregion
  //#region Search by tire size asynchronous api calls
  const getRatios = async (width: string, key: keyof TireBySizeData) => {
    const resp = await get<string[]>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetTireRatiosByWidth?width=${width}`,
      headers
    );
    // remove ratios <= 0
    let data = resp?.data;
    if (resp && resp.data) {
      data = resp.data.filter((ratio) => parseInt(ratio) > 0);
    }
    setTireBySize((prevState: TireBySizeData) => ({
      ...prevState,
      [key]: data as string[],
    }));
  };

  const getRims = async (
    width: string,
    key: keyof TireBySizeData,
    aspRatio?: string
  ) => {
    aspRatio = aspRatio ? aspRatio : "";
    const resp = await get<string[]>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/GetTireRimsByWidthAndRatio?width=${width}&aspRatio=${aspRatio}`,
      headers
    );
    setTireBySize((prevState: TireBySizeData) => ({
      ...prevState,
      [key]: resp?.data as string[],
    }));
  };
  //#endregion
  // reset ratios & rims
  const resetRatioAndRims = (
    ratio?: keyof TireBySizeData,
    rim?: keyof TireBySizeData
  ) => {
    if (ratio && rim) {
      setTireBySize((prevState: TireBySizeData) => ({
        ...prevState,
        [ratio]: undefined,
        [rim]: undefined,
      }));
      return;
    }
    setTireBySize({} as TireBySizeData);
  };

  // Search tires asynchronous call
  const searchTires = async (searchParams: SearchParams, dealerId?: number) => {
    const search = {
      DealerGroupId: dealerGroupId,
      dealerId: dealerId ? dealerId : selectedDealerLocation?.DealerId,
      ...searchParams,
    };
    const urlSearchParams = encodeURI(JSON.stringify(search)).replace(
      /\//g,
      "%2f"
    );
    const resp = await get<SearchedTires | undefined>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/SearchTires?searchParams=${urlSearchParams}`,
      headers
    );
    setSearchedTires(resp?.data);
    setSearchParamsData(searchParams);
    return resp?.data;
  };

  // Search tires by plate asynchronous call
  const searchTiresByPlate = async (plate: string, state: string) => {
    const resp = await get<Option[]>(
      `${process.env.REACT_APP_BASE_URL}/api/TireSearchingApi/SearchTiresByPlate?dealerGroupId=${dealerGroupId}&dealerId=${selectedDealerLocation?.DealerId}&plate=${plate}&state=${state}`,
      headers
    );
    setVehciles(resp?.data);
  };

  //#region helpers when user removes filters for (categorie, brand, etc)
  const removeCategorie = (categorie: string) => {
    const mutatedFilters = filters;
    if (categorie !== Filter.All) {
      mutatedFilters.categorie = filters.categorie?.filter(
        (categ: string) => categ !== categorie
      );
    } else {
      mutatedFilters.categorie = [];
    }
    setFilters({ ...mutatedFilters });
  };

  const removeBrandId = (brandId: number) => {
    const mutatedFilters = filters;
    const filteredBrandIds = filters.brandIds?.filter(
      (brandIdData: number) => brandIdData !== brandId
    );
    mutatedFilters.brandIds = filteredBrandIds;
    setFilters({ ...mutatedFilters });
  };

  const removeSpeedRating = (speedRating: string) => {
    const mutatedFilters = filters;
    const filtaredSpeedRatings = filters.speedRatings?.filter(
      (speedRatingData: string) => speedRatingData !== speedRating
    );
    mutatedFilters.speedRatings = filtaredSpeedRatings;
    setFilters({ ...mutatedFilters });
  };

  const removePriceRange = (priceRange: PriceRange) => {
    const mutatedFilter = filters;
    const filteredPriceRanges = filters.priceRanges?.filter(
      (priceRangeData: PriceRange) =>
        priceRangeData.MaxPrice !== priceRange.MaxPrice &&
        priceRangeData.MinPrice !== priceRange.MinPrice
    );
    mutatedFilter.priceRanges = filteredPriceRanges;
    setFilters({ ...mutatedFilter });
  };

  const removeWarrantyRange = (warrantyRange: MileageWarrantiesRange) => {
    const mutatedFilter = filters;
    const filteredWarrantyRanges = filters.mileageWarrantiesRanges?.filter(
      (warrantyRangeData: MileageWarrantiesRange) =>
        warrantyRangeData.MaxMiles !== warrantyRange.MaxMiles &&
        warrantyRangeData.MinMiles !== warrantyRange.MinMiles
    );
    mutatedFilter.mileageWarrantiesRanges = filteredWarrantyRanges;
    setFilters({ ...mutatedFilter });
  };

  //#endregion
  //#region helpers when user adds filters for (categorie, brand, etc)
  const addFilterByCategorie = (categorie: string) => {
    if (categorie === Filter.All) {
      removeCategorie(Filter.All);
    } else {
      const mutatedFilter = filters;
      mutatedFilter.categorie?.push(categorie);
      setFilters({ ...mutatedFilter });
    }
  };

  const addFilterByBrand = (brandId: number) => {
    const mutatedFilter = filters;
    mutatedFilter.brandIds?.push(brandId);
    setFilters({ ...mutatedFilter });
  };

  const addFilterBySpeedRating = (speedRating: string) => {
    const mutatedFilter = filters;
    mutatedFilter.speedRatings?.push(speedRating);
    setFilters({ ...mutatedFilter });
  };

  const addFilterByPriceRanges = (priceRange: PriceRange) => {
    const mutatedFilter = filters;
    mutatedFilter.priceRanges?.push(priceRange);
    setFilters({ ...mutatedFilter });
  };

  const addFilterByMileageWarrantiesRange = (
    warrantyRange: MileageWarrantiesRange
  ) => {
    const mutatedFilter = filters;
    mutatedFilter.mileageWarrantiesRanges?.push(warrantyRange);
    setFilters({ ...mutatedFilter });
  };
  //#endregion

  // Method to apply filters to search results
  const filterTires = () => {
    const {
      categorie,
      brandIds,
      priceRanges,
      mileageWarrantiesRanges,
      speedRatings,
    } = filters;
    const newFilteredTires = allSearchedTires?.TireResult?.filter(
      (tire: Tire) => {
        if (
          categorie &&
          categorie.length > 0 &&
          !isCategorieInList(tire, categorie)
        ) {
          return false;
        }
        if (
          brandIds &&
          brandIds.length > 0 &&
          !brandIds.includes(tire.BrandId)
        ) {
          return false;
        }
        if (
          speedRatings &&
          speedRatings.length > 0 &&
          !speedRatings.includes(tire.SpeedRating)
        ) {
          return false;
        }
        if (
          priceRanges &&
          priceRanges.length > 0 &&
          !isPriceInRange(priceRanges, tire)
        ) {
          return false;
        }
        if (
          mileageWarrantiesRanges &&
          mileageWarrantiesRanges.length > 0 &&
          !isWarrantyInRange(mileageWarrantiesRanges, tire)
        ) {
          return false;
        }
        return true;
      }
    );
    const mutatedFilteredAndSortedTires = filteredAndSortedTires;
    mutatedFilteredAndSortedTires.TireResult = newFilteredTires as [];
    // If page is sorted by a certain field, sort list & delegate state update to sortingBy
    // This will most likely be true when filters are removed & list is set to be sorted
    // This will perserve the sorted list to be dsiplayed whether list is filtered or not
    if (sortedBy) {
      sortingBy(
        sortedBy.field,
        sortedBy.ascending,
        mutatedFilteredAndSortedTires
      );
      return;
    }
    setFilteredTires({ ...mutatedFilteredAndSortedTires });
  };

  // Sort search results
  const sortingBy = (
    key: keyof Tire,
    ascending: boolean,
    filteredList?: AllSearchedTires
  ) => {
    let sortedTires: Tire[] | undefined;
    // If filtered list is true, we know that this function call was initiated
    // by this context. If not its inititated by user maniuplating the sort filed in search results page
    const filteredListToSort: AllSearchedTires = filteredList
      ? filteredList
      : filteredAndSortedTires;
    if (!ascending) {
      sortedTires = filteredListToSort?.TireResult?.sort(
        (tireOne: any, tireTwo: any) => (tireOne[key] > tireTwo[key] ? -1 : 1)
      );
    } else {
      sortedTires = filteredListToSort.TireResult?.sort(
        (tireOne: any, tireTwo: any) => (tireOne[key] < tireTwo[key] ? -1 : 1)
      );
    }
    const mutatedFilteredAndSortedTires = filteredList
      ? filteredList
      : filteredAndSortedTires;
    mutatedFilteredAndSortedTires.TireResult = sortedTires as [];
    setSortedBy({ field: key, ascending: ascending });
    setFilteredTires({ ...mutatedFilteredAndSortedTires });
  };

  // Set tires selected for comparison state
  const compareTires = (tireId: number) => {
    const compareTires = filteredAndSortedTires.TireResult?.find(
      (tire: Tire) => tireId === tire.TireId
    );
    setComparedTires(
      (prevState: Tire[]) => [...prevState, { ...compareTires }] as Tire[]
    );
  };

  // Remove a tire from comparison state
  const removeComparedTires = (tireId: number) => {
    const filteredComparedTires = comparedTires.filter(
      (tire: Tire) => tireId !== tire.TireId
    );
    setComparedTires([...filteredComparedTires]);
  };

  // Cleare tire comparison state
  const clearComparedTires = () => {
    setComparedTires([]);
  };

  // Clear vehicles search
  const clearVehicles = () => {
    setVehciles(undefined);
  };

  // clear filters
  const clearFilters = (filter: keyof Filters) => {
    const mutatedFilter = filters;
    mutatedFilter[filter] = [];
    setFilters({ ...mutatedFilter });
  };

  // clear all filters
  const clearAllFilters = () => {
    setFilters({
      categorie: [],
      brandIds: [],
      priceRanges: [],
      mileageWarrantiesRanges: [],
      speedRatings: [],
    });
  };

  return (
    <SearchContext.Provider
      value={{
        manufacturer,
        years,
        models,
        options,
        states,
        vehicles,
        searchedTires,
        filteredAndSortedTires,
        tireBySize,
        searchParamsData,
        comparedTires,
        filters,
        sortDefault,
        getManufacturerByYear,
        getModels,
        getOptions,
        resetManufacturerAndModelsAndOptions,
        getRatios,
        getRims,
        resetRatioAndRims,
        searchTires,
        searchTiresByPlate,
        addFilterByCategorie,
        addFilterByBrand,
        addFilterBySpeedRating,
        addFilterByMileageWarrantiesRange,
        addFilterByPriceRanges,
        removeCategorie,
        removeBrandId,
        removeSpeedRating,
        removePriceRange,
        removeWarrantyRange,
        sortingBy,
        compareTires,
        removeComparedTires,
        clearComparedTires,
        clearVehicles,
        clearFilters,
        clearAllFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
