import { useSearchContext } from "context/search";
import { useEcommerceContext } from "context/ecommerce";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "hooks/useWindowSize";
import { TiresAnytimeRoutes } from "routes";
import { Tire } from "types/tire";
import { SearchParams } from "types/search";
import { mapToSearchParamsToSearchOption } from "helpers/mapToSearchParamsToSearchOption";
import { SearchResultsListCard } from "components/searchResultsListCard/searchResultsListCard";
import { SearchResultsGridCard } from "components/searchResultsGridCard/searchResultsGridCard";

interface Props {
  load: () => void;
  unload: () => void;
  isFiltered: boolean;
  tires: Tire[];
  isGrid: boolean;
}

export const DisplaySearchResultsCards = ({
  load,
  unload,
  isFiltered,
  tires,
  isGrid,
}: Props) => {
  const { filteredAndSortedTires, searchParamsData } = useSearchContext();
  const { addToCart } = useEcommerceContext();
  const navigate = useNavigate();
  const [width] = useWindowSize();

  const addToCartData = async (tire: Tire) => {
    // Show busy indicator before calling addToCart api
    load();
    await addToCart(
      tire,
      mapToSearchParamsToSearchOption(searchParamsData as SearchParams)
    );
    unload();
    navigate(TiresAnytimeRoutes.Cart);
  };

  // Create react component for search result list, List view
  const renderListCards = (tires: Tire[], isStrategyTire: boolean) => {
    if (width > 1024) {
      return tires?.map((tire, index) => (
        <SearchResultsListCard
          key={tire.TireId.toString()}
          index={index}
          tire={tire}
          isStrategyTire={isStrategyTire}
          addToCart={addToCartData}
        />
      ));
    } else {
      return tires?.map((tire, index) => (
        <SearchResultsGridCard
          key={tire.TireId.toString()}
          tire={tire}
          isStrategyTire={isStrategyTire}
          addToCart={addToCartData}
        />
      ));
    }
  };

  // Create react component for search result list, Grid view
  const renderGridCards = (tires: Tire[], isStrategyTire: boolean) => {
    return tires?.map((tire) => (
      <SearchResultsGridCard
        key={tire.TireId.toString()}
        tire={tire}
        isStrategyTire={isStrategyTire}
        addToCart={addToCartData}
      />
    ));
  };

  // If list is not filtered show Strategy tires at top of results for grid view, otherwise don't
  const gridView = isGrid ? (
    filteredAndSortedTires.StrategyTires && !isFiltered ? (
      <>
        {renderGridCards(filteredAndSortedTires.StrategyTires.TireResult, true)}
        {renderGridCards(tires, false)}
      </>
    ) : (
      renderGridCards(tires, false)
    )
  ) : null;

  // If list is not filtered show Strategy tires at top of results for list view, otherwise don't
  const listView = !isGrid ? (
    filteredAndSortedTires.StrategyTires && !isFiltered ? (
      <>
        {renderListCards(filteredAndSortedTires.StrategyTires.TireResult, true)}
        {renderListCards(tires, false)}
      </>
    ) : (
      renderListCards(tires, false)
    )
  ) : null;

  return <>{isGrid ? gridView : listView}</>;
};
