import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "components/modal/modal";
import { TiresAnytimeRoutes } from "routes";

interface Props {
  classes?: string;
  component: React.ReactNode;
  showCart?: boolean;
  showClose?: boolean;
  showSiteHeader?: boolean;
  title: string;
  withoutLocation?: boolean;
}

export const ModalWrapper = ({
  classes,
  component,
  showCart = true,
  showClose = false,
  showSiteHeader = true,
  title,
  withoutLocation,
}: Props) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    navigate(TiresAnytimeRoutes.Home);
  };
  useEffect(() => {}, [show]);
  return (
    <Modal
      title={title}
      modalContent={component}
      show={show}
      handleClose={handleClose}
      showSiteHeader={showSiteHeader}
      showCart={showCart}
      showClose={showClose}
      classes={classes}
      withoutLocation={withoutLocation}
    />
  );
};
