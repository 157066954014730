import useSWR, { KeyedMutator } from "swr";
import { useAuth } from "../context/auth";
import { useHttpRequests } from "./useHttpRequests";
import { HttpStatus } from "helpers/httpStatus";
import { Toaster } from "components/toaster/toaster";

interface DataResponse<T> {
  data: T | undefined;
  mutate: KeyedMutator<T | undefined>;
  error: any;
}

export function useBackendData<T>(url: string | (() => any)): DataResponse<T> {
  const { token, loading } = useAuth();
  const { fetcher } = useHttpRequests();
  const { data, mutate, error } = useSWR<T | undefined>(
    token && !loading ? url : null,
    fetcher,
    {
      revalidateOnFocus: false,
      onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
        // don't retry on 404s
        if (error.status === HttpStatus.NOT_FOUND) return;

        if (retryCount >= 3) return;

        // Retry after 2 second
        setTimeout(() => revalidate({ retryCount }), 2000);
      },
      onError(err, key, config) {
        Toaster(`${err.response.statusText}\n${err.message}`, false, {
          id: err.response.statusText,
          duration: 2500,
        });
      },
    }
  );
  return { data, mutate, error };
}
