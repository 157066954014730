import { DealerLocation } from "types/dealer";
import {
  convertDateToDay,
  convertToMilitary,
  timeDiff,
} from "./timeAndDateConverter";
export const isAfterStoreHours = (
  store: DealerLocation | undefined | null,
  chosenTime: string,
  chosenDate: string
) => {
  if (!store) return false;
  if (isStoreClosed(store, chosenDate)) return true;
  const storeHours = getStoreHours(store, chosenDate);
  // min: open time, max: close time
  const { min, max } = parseStoreHours(storeHours);
  const timeDiffWithMin = timeDiff(chosenTime, min, chosenDate);
  // If open time is less than user chosen time, store is closed
  if (timeDiffWithMin < 0) {
    return true;
  }
  // If close time is less than user chosen time, store is open
  return timeDiff(chosenTime, max, chosenDate) > 0 ? true : false;
};

export const isStoreClosed = (
  store: DealerLocation | null | undefined,
  chosenDate: string
) => {
  if (!store) return false;
  const storeHours = getStoreHours(store, chosenDate);
  if (storeHours.toLocaleLowerCase() === "closed") return true;
};

// Get chosen date day, to keyof dealer location dayHours key & get hours value for that day
export const getStoreHours = (store: DealerLocation, chosenDate: string) => {
  const day = convertDateToDay(chosenDate);
  const key = `${day}Hours` as keyof DealerLocation;
  return store[key] as string;
};

// Parse store hours & return open & close hours
export const parseStoreHours = (hours: string) => {
  let min = "",
    max = "";
  if (!hours || hours.toLocaleLowerCase().includes("closed")) {
    return { min, max };
  }
  const newHours = hours.split("-");
  min = convertToMilitary(newHours[0]).trim();
  max = convertToMilitary(newHours[1]).trim();
  return { min, max };
};
