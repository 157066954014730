import { Dealer } from "types/dealer";
import { SelectOptions, SortLabels, SortOptions } from "types/search";
export const labels: SortLabels = {
  warrantyDesc: "Warranty - Descending",
  warrantyAsc: "Warranty - Ascending",
  priceLowToHigh: "Price - Low to High",
  priceHighToLow: "Price - High to Low",
  brandAtoZ: "Brand - A to Z",
  brandZtoA: "Brand - Z to A",
};

export const dealerLabelOptions: SelectOptions[] = [
  { label: labels.warrantyDesc, value: "Warranty Descending" },
  { label: labels.warrantyAsc, value: "Warranty Ascending" },
  { label: labels.priceLowToHigh, value: "Price Ascending" },
  { label: labels.priceHighToLow, value: "Price Descending" },
  { label: labels.brandAtoZ, value: "Brand Name Ascending" },
  { label: labels.brandZtoA, value: "Brand Name Descending" },
];

export const displaySortOptions: SelectOptions[] = [
  { label: labels.warrantyDesc, value: "WarrantySort:desc", data_id: 1 },
  { label: labels.warrantyAsc, value: "WarrantySort:asc", data_id: 2 },
  { label: labels.priceLowToHigh, value: "_finalPrice:asc", data_id: 3 },
  { label: labels.priceHighToLow, value: "_finalPrice:desc", data_id: 4 },
  { label: labels.brandAtoZ, value: "BrandId:asc", data_id: 5 },
  { label: labels.brandZtoA, value: "BrandId:desc", data_id: 6 },
];

// Map user sorting selection to { field: value, direction: value } object
export const mapSortingOption = (sortingOption: string) => {
  if (!sortingOption) sortingOption = displaySortOptions[0].value;
  const splitOptionValue = sortingOption.split(":");
  return {
    field: splitOptionValue[0],
    direction: splitOptionValue[1],
  };
};

// Find sort option and return value
export const findSortOption = (label: string) => {
  return displaySortOptions.find(
    (option: SelectOptions) => option.label === label
  )?.value;
};

// Find default sort value
export const findSortByDefaultVal = (
  selectedDealerGroup: Dealer,
  sortOptions: SortOptions[]
) => {
  return (
    sortOptions &&
    sortOptions.find(
      (option: SortOptions) =>
        option.Id.toString() === selectedDealerGroup.SortTireResultsBy
    )?.Description
  );
};

// find default sorty label
export const findSortByDefaultLabel = (
  selectedDealerGroup: Dealer,
  sortOptions: SortOptions[]
) => {
  if (!selectedDealerGroup || !sortOptions) return dealerLabelOptions[0].label;
  const sortDefaultVal = findSortByDefaultVal(selectedDealerGroup, sortOptions);
  return dealerLabelOptions.find(
    (option: SelectOptions) => option.value === sortDefaultVal
  )?.label;
};
