import { warrantyFormatter } from "helpers/warrantyFormatter";
import { Tire } from "types/tire";

interface Props {
  tire: Tire;
}
export const StaggeredFitmentInfo = ({ tire }: Props) => {
  return (
    <>
      <div className="ta-mb-2">
        Front Size: {tire.DisplayTireSize}
        <div>Rear Size: {tire.RearDisplayTireSize}</div>
      </div>
      <div className="ta-mb-2">
        Front Mileage Warranty:{" "}
        <span className="ta-font-bold">
          {warrantyFormatter(tire.Warranty)} Miles
        </span>
        {tire.HasRearTire && tire.RearWarranty && (
          <div>
            Rear Mileage Warranty:{" "}
            <span className="ta-font-bold">
              {warrantyFormatter(tire.RearWarranty)} Miles
            </span>
          </div>
        )}
      </div>
    </>
  );
};
