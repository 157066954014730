import { useState } from "react";
import { WishlistSignupForm } from "components/wishlistForm/wishlistSignupForm";
import { WishlistSignIn } from "components/wishlistSignIn/wishlistSignIn";
import { WishlistCustomer } from "types/wishlist";
import { useEcommerceContext } from "context/ecommerce";
import { useNavigate } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";

export const WishlistSignupPage = () => {
  const { createWishlist } = useEcommerceContext();
  // state to manage whether user is existing customer or new for different form display
  const [signIn, setSignIn] = useState<boolean>(false);
  const { load, loading, unload } = useBusyIndicator();
  const navigate = useNavigate();
  // Submit user form & navigate to wishlist page
  const submitFormToCreateWishlist = async (customer: WishlistCustomer) => {
    load();
    await createWishlist(customer);
    unload();
    navigate(TiresAnytimeRoutes.Wishlist);
  };
  return (
    <div className="ta-max-w-[400px] ta-mx-auto">
      {signIn ? (
        <WishlistSignIn
          submitFormForWishlistCreation={submitFormToCreateWishlist}
          signIn={signIn}
        />
      ) : (
        <WishlistSignupForm
          submitFormForWishlistCreation={submitFormToCreateWishlist}
          signIn={signIn}
        />
      )}
      <div className="ta-grid ta-grid-cols-wishListInputGrid ta-mt-4">
        <div className="ta-col-start-2">
          <button
            type="button"
            onClick={() => setSignIn(!signIn)}
            className="ta-border ta-border-2 ta-rounded-[5px] ta-p-1.5 ta-w-full ta-bg-white ta-text-button ta-text-base ta-text-2xl ta-font-bold hover:ta-bg-button hover:ta-text-white focus:ta-bg-button focus:ta-text-white"
          >
            {signIn ? "New User" : "Existing Customer?"}
          </button>
        </div>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};
