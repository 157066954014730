import { useEffect, useState } from "react";
import { SearchTabFooter } from "components/searchTabFooter/searchTabFooter";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { SearchByLicensePlateCard } from "components/searchByLicensePlateCard/searchByLicensePlateCard";
import { SearchByLicensePlateResultsCard } from "components/searchByLicensePlateResultsCard/searchByLicensePlateResultsCard";
import { SearchParams, States } from "types/search";
import { useDealerContext } from "context/dealer";
import { TiresAnytimeRoutes } from "routes";
import { useDebounce } from "hooks/useDebounce";
import { isSearchResultsEmpty } from "helpers/isSearchResultsEmpty";
export interface Props {
  load: () => void;
  unload: () => void;
}

export interface State {
  plate: string;
  state: string;
}

export const SearchByLicensePlateTabContent = ({ load, unload }: Props) => {
  const { searchTiresByPlate, searchTires, clearVehicles, states, vehicles } =
    useSearchContext();
  const { selectedDealerLocation } = useDealerContext();

  const [inActive, setInActive] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useState<undefined | SearchParams>(
    undefined
  );
  // State to manage plate options
  const [options, setOptions] = useState<State>({
    plate: "",
    state: "",
  } as State);
  const [isResetSearch, setIsResetSearch] = useState<boolean>(false);
  const navigate = useNavigate();
  const debouncedSearchByPlate = useDebounce(
    (plate: string, state: string) => searchTiresByPlate(plate, state),
    [],
    1000
  );

  useEffect(() => {
    // If all required fields are selected make search plate
    if (options.plate && options.state) {
      debouncedSearchByPlate(options.plate, options.state);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    // If all required fields are selected make find tires button active
    if (searchParams && options.plate && options.state) {
      setInActive(false);
      return;
    }
    setInActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const findTires = async () => {
    load();
    const searchResults = await searchTires(
      searchParams as SearchParams,
      selectedDealerLocation?.DealerId as number
    );
    unload();
    clearVehicles();
    if (isSearchResultsEmpty(searchResults)) {
      navigate(TiresAnytimeRoutes.EmptySearchResults, {
        state: {
          isTireBySize: false,
          isByLicensePlate: true,
          isByVehicle: true,
        },
      });
    } else {
      navigate(TiresAnytimeRoutes.Search);
    }
  };

  // reset search fields
  const resetSearch = () => {
    setIsResetSearch(true);
    setOptions({ state: "", plate: "" });
  };
  return (
    <div className="ta-px-4 ta-py-3.5 md:ta-px-8">
      <SearchByLicensePlateCard
        stateOpt={options}
        setOptions={setOptions}
        states={states as States[]}
        isResetSearch={isResetSearch}
        setIsResetSearch={setIsResetSearch}
      />
      {options.plate && options.state && vehicles && vehicles.length > 0 ? (
        <SearchByLicensePlateResultsCard
          vehicles={vehicles}
          setSearchParams={setSearchParams}
        />
      ) : (
        options.plate &&
        options.state &&
        vehicles &&
        vehicles.length === 0 && <div>No Search Results</div>
      )}
      <SearchTabFooter
        inActive={inActive}
        findTires={findTires}
        resetSearch={resetSearch}
      />
    </div>
  );
};
