import { useNavigate } from "react-router-dom";
import { TiresAnytimeRoutes } from "routes";
import { BackButton } from "components/backButton/backButton";
import { backToFinder } from "constants/index";

export const CustomerContactConfirmationPage = () => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(TiresAnytimeRoutes.Home);
  };
  return (
    <div>
      <BackButton text={backToFinder} navigation={TiresAnytimeRoutes.Home} />
      <div>
        <p>
          Thank you for submitting your request. A repesentative will be in
          contact soon to answer your questions.
        </p>
      </div>
      <div>
        <button type="button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};
