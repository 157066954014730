import { OrderTotalCards } from "types/cart";
import { Details, WishlistDetail } from "types/wishlist";
import { calculateWishlistDiscounts } from "./pricing";

// Map wishlist totals to OrderTotalsCard component
export const mapWishlistTotalsWithTotalsCards = (
  wishlistDetail: WishlistDetail | null | undefined
): OrderTotalCards => {
  const mutatedWishlistDetail = filterWishlistDetailsToTiesOnly(wishlistDetail);
  return {
    SubTotal: mutatedWishlistDetail ? mutatedWishlistDetail.SubTotal : 0,
    Discounts:
      mutatedWishlistDetail && mutatedWishlistDetail?.Details
        ? calculateWishlistDiscounts(mutatedWishlistDetail.Details)
        : 0,
    Tax: mutatedWishlistDetail ? mutatedWishlistDetail.TaxAmt : 0,
  };
};

export const filterWishlistDetailsToTiesOnly = (
  wishlistDetail: WishlistDetail | null | undefined
) => {
  if (!wishlistDetail) return undefined;
  const mutatedWishlistDetail = { ...wishlistDetail, Details: [] as Details[] };
  mutatedWishlistDetail.Details = wishlistDetail.Details.filter(
    (detail) => !detail.IsQuoteAddOn && !detail.IsWarranty
  );
  return mutatedWishlistDetail;
};
