import { SearchParams, SearchOptions } from "types/search";
// Map user search entries to search params for addToCart api call
export const mapToSearchParamsToSearchOption = (
  option: SearchParams
): SearchOptions => {
  if (option.Make) {
    return {
      SearchByVehicle: true,
      VehicleMake: option.Make,
      VehicleModel: option.Model!,
      VehicleOption: option.Option!,
      VehicleYear: option.Year!,
      VehicleBody: option.Body!,
    };
  }
  return {
    SearchBySize: true,
    TireAspectRatio: option.AspRatio.toString(),
    TireRimSize: option.Rim.toString(),
    TireWidth: option.Width.toString(),
  };
};
