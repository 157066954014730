export const TiresAnytimeRoutes = {
  Home: "/",
  Search: "/search",
  EmptySearchResults: "/search/empty",
  ContactInfoConfirmation: "/search/empty/confirmation",
  Cart: "/cart",
  Wishlist: "/wishlist",
  Checkout: "/checkout",
  Location: "/location",
  ProductDetails: "/productdetails",
  WishlistSignUp: "/wishlist/singup",
  WishlistSignIn: "/wishlist/signin",
  Confirmation: "/confirmation",
  Compare: "/compare",
  UserWishlist: "/user/wishlist",
  payPalCheckout: "/checkout/payPal",
  Back: -1,
};
