import { SearchedTires } from "types/search";

export const isSearchResultsEmpty = (
  searchResults: SearchedTires | undefined
) => {
  if (!searchResults) return true;
  return (
    searchResults.AdditionalTires &&
    searchResults.AdditionalTires.TireResult &&
    searchResults.AdditionalTires.TireResult.length === 0
  );
};
