import { Pattern } from "types/wishlist";
export const themeColors = {
  default: {
    primary: "default-primary",
    secondary: "default-secondary",
    tertiary: "default-tertiary",
    quaternary: "default-quaternary",
  },
  darkgrey: {
    primary: "darkgrey-primary",
    secondary: "darkgrey-secondary",
    tertiary: "darkgrey-tertiary",
    quaternary: "darkgrey-quaternary",
  },
  blue: {
    primary: "blue-primary",
    secondary: "blue-secondary",
    tertiary: "blue-tertiary",
    quaternary: "blue-quaternary",
  },
  lightgrey: {
    primary: "lightgrey-primary",
    secondary: "lightgrey-secondary",
    tertiary: "lightgrey-tertiary",
    quaternary: "lightgrey-quaternary",
  },
  red: {
    primary: "red-primary",
    secondary: "red-secondary",
    tertiary: "red-tertiary",
    quaternary: "red-quaternary",
  },
};

// constants for back button
export const backToSearchResultsText = "Back to Search Results";
export const backToFinder = "Back to Finder";
export const back = "Back";

// styling border constant
export const redBorder = "red-border";

export const patterns: Pattern = {
  email:
    "^[_a-zA-Z0-9]+(\\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,4})$",
};
