import React from "react";
import { ProductDetails } from "components/productDetails/productDetails";
import { useEcommerceContext } from "context/ecommerce";
import { useLocation } from "react-router-dom";

export const ProductDetailsPage = () => {
  const { addToCart } = useEcommerceContext();
  const location = useLocation();

  return (
    <>
      <ProductDetails addToCart={addToCart} tire={location.state} />
    </>
  );
};
