import { ChangeEvent, useEffect, useState } from "react";
import { DealerLocation } from "types/dealer";
import { redBorder } from "constants/index";
import { CustomIcon } from "./customIcon";
import { getStoreHours, parseStoreHours } from "helpers/storeAppointments";
interface Props {
  value?: string;
  name: string;
  errorText: string;
  placeholder: string;
  dealerLocation: DealerLocation | null | undefined;
  onChange: any;
  date: string;
  label?: string;
  id?: string;
  required?: boolean;
  isActive: boolean;
}

const CustomTimePicker: React.FC<Props> = ({
  name,
  errorText,
  onChange,
  placeholder,
  dealerLocation,
  date,
  required,
  label,
  id,
  isActive = true,
}) => {
  const [value, setValue] = useState<any>(placeholder);

  let hours!: string;
  if (dealerLocation) {
    hours = getStoreHours(dealerLocation, date);
  }
  // // min: store open time, max: store close time.
  // // If user selects outside min & max range the store is closed we show error
  const { min, max } = parseStoreHours(hours);

  function createSyntheticChangeEvent() {
    if (value) {
      const inputElement = document.createElement("input");
      inputElement.type = "time";
      inputElement.name = name;
      inputElement.value = value;

      const syntheticEvent = new CustomEvent("change", {
        bubbles: true,
        cancelable: true,
        detail: { target: inputElement },
      }) as unknown as ChangeEvent<HTMLInputElement>;

      Object.defineProperty(syntheticEvent, "target", {
        get: () => inputElement,
      });

      onChange(syntheticEvent);
    }
  }

  useEffect(() => {
    if (value) {
      createSyntheticChangeEvent();
    }
  }, [value]);

  const allTime = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      allTime.push(time);
    }
  }
  function filterTimeRange(time: string[], min: string, max: string) {
    return time.filter((time) => time >= min && time <= max);
  }
  const times: string[] = filterTimeRange(allTime, min, max);

  const handleTimeChange = (event: any) => {
    const selectedTime = event.target.value;
    setValue(selectedTime);
  };

  return (
    <div className="ta-form-group ta-w-full">
      {label && (
        <label
          htmlFor={id}
          className="ta-text-sm ta-mb-1 ta-block ta-font-bold ta-text-black"
        >
          {label}
          {required && <span className="ta-text-red">*</span>}
        </label>
      )}
      <div
        className={`ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-h-[33px] ta-p-2 ta-w-full ta-flex ta-items-center ${
          required && !value ? redBorder : ""
        }`}
      >
        {required && !value && (
          <span className="ta-sr-only" role="alert">
            {errorText}
          </span>
        )}
        <select
          className="ta-appearance-none ta-w-full ta-text-[0.67rem] ta-bg-white"
          value={value}
          disabled={!isActive}
          onChange={handleTimeChange}
          placeholder={placeholder}
          aria-label={label}
          aria-required={required}
          aria-invalid={required && !value}
          id={id}
        >
          <option value="">{placeholder}</option>
          {times.map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </select>
        <CustomIcon />
      </div>
    </div>
  );
};
export default CustomTimePicker;
