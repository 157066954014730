import { DealerLocation } from "types/dealer";
import { LocationCard } from "components/locationCard/locationCard";
import { useDealerContext } from "context/dealer";
import { BackButton } from "components/backButton/backButton";
import { backToSearchResultsText } from "constants/index";
import { TiresAnytimeRoutes } from "routes";

interface Props {
  hideBackButton?: boolean;
  onClose: () => void; // Handler to close dialog
  search?: () => void;
}

export const LocationSelectionPage = ({
  hideBackButton = false,
  search,
  onClose,
}: Props) => {
  const { selectedDealerLocation, dealerLocations } = useDealerContext();
  // Create a react component for each location to display as list
  const locationsCards = dealerLocations?.map(
    (dealerLocation: DealerLocation) => (
      <LocationCard
        key={dealerLocation.DealerId}
        dealerLocation={dealerLocation}
        search={search}
        onClose={onClose}
      />
    )
  );
  return (
    <>
      <div className="ta-mb-4 sm:ta-flex sm:ta-flex-wrap-nowrap sm:ta-items-center sm:ta-justify-center sm:ta-w-full">
        {!hideBackButton ? (
          <BackButton
            text={backToSearchResultsText}
            navigation={TiresAnytimeRoutes.Search}
          />
        ) : null}
        <h2 className="ta-font-bold ta-text-2xl !ta-tracking-normal !ta-bg-transparent ta-text-black sm:ta-m-auto">
          {selectedDealerLocation?.Name?.split("-")[0]} Locations
        </h2>
      </div>
      {locationsCards}
    </>
  );
};
