export const CustomIcon = (props: any) => {
  return (
    <div className="ta-relative ta-right-[12px]">
      <svg
        className="ta-absolute ta-top-[-5px] ta-left-[1px]  ta-w-3 ta-h-3"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
      >
        <path
          id="Icon_awesome-clock"
          data-name="Icon awesome-clock"
          d="M6.563.563a6,6,0,1,0,6,6A6,6,0,0,0,6.563.563Zm1.381,8.47L5.81,7.482a.292.292,0,0,1-.119-.235V3.175a.291.291,0,0,1,.29-.29H7.143a.291.291,0,0,1,.29.29V6.507L8.97,7.625a.29.29,0,0,1,.063.406L8.35,8.97A.292.292,0,0,1,7.944,9.033Z"
          transform="translate(-0.563 -0.563)"
          fill="#707070"
        />
      </svg>
    </div>
  );
};
