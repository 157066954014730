import { useEcommerceContext } from "context/ecommerce";
import { useEffect, useRef } from "react";
import { Item } from "types/checkout";
import { TiresAnytimeRoutes } from "routes";
import { useNavigate } from "react-router-dom";

export const PayPalForm = () => {
  const { payPalOrderInfo } = useEcommerceContext();
  const ref = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  let mounted = false;
  const submitForm = () => {
    if (!ref.current || !payPalOrderInfo) return;
    ref.current.setAttribute("action", payPalOrderInfo.PaypalUrl);
    ref.current.setAttribute("method", "post");
    ref.current.setAttribute("target", "_blank");
    ref.current.submit();
  };

  const close = () => {
    navigate(TiresAnytimeRoutes.Home);
  };

  useEffect(() => {
    if (ref.current && !mounted) {
      const submitBtn = document.getElementById(
        "btnPaypalSubmit"
      ) as HTMLButtonElement;
      submitBtn.click();
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mounted = true;
    };
  }, []);

  return (
    <>
      <form ref={ref} id="frmPaypal">
        <div className="row">
          <div className="col-xs-12">
            <span>
              You will now be redirected to PayPal™ to complete your order
              payment.
            </span>
            <br />
            <br />
            <span>
              If you are not automatically redirected in 30 seconds, click the
              "Proceed to PayPal" button.
            </span>
            <br />
            <br />
          </div>
          <div className="col-xs-12">
            <input type="hidden" name="cmd" value="_cart" />
            <input type="hidden" name="upload" value="1" />

            <input
              type="hidden"
              id="business"
              name="business"
              value={payPalOrderInfo?.Business}
            />
            <input
              type="hidden"
              id="notify_url"
              name="notify_url"
              value={payPalOrderInfo?.NotifyUrl}
            />
            <input
              type="hidden"
              id="bn"
              name="bn"
              value="TiresAnytime_WPS_ShoppingCart_US"
            />
            <input type="hidden" id="no_note" name="no_note" value="1" />
            <input
              type="hidden"
              id="no_shipping"
              name="no_shipping"
              value="1"
            />
            <input type="hidden" id="rm" name="rm" value="1" />
            <input
              type="hidden"
              id="custom"
              name="custom"
              value={payPalOrderInfo?.OrderId || payPalOrderInfo?.DealerGroupId}
            />
            <input
              type="hidden"
              id="return"
              name="return"
              value={payPalOrderInfo?.ReturnUrl}
            />
            <input
              type="hidden"
              id="first_name"
              name="first_name"
              value={payPalOrderInfo?.CustomerFirstName}
            />
            <input
              type="hidden"
              id="last_name"
              name="last_name"
              value={payPalOrderInfo?.CustomerLastName}
            />
            <input
              type="hidden"
              id="address1"
              name="address1"
              value={payPalOrderInfo?.CustomerAddress1}
            />
            {payPalOrderInfo && payPalOrderInfo.CustomerAddress2 && (
              <input
                type="hidden"
                id="address2"
                name="address2"
                value={payPalOrderInfo.CustomerAddress2}
              />
            )}
            <input
              type="hidden"
              id="city"
              name="city"
              value={payPalOrderInfo?.CustomerCity}
            />
            <input
              type="hidden"
              id="state"
              name="state"
              value={payPalOrderInfo?.CustomerState}
            />
            <input
              type="hidden"
              id="zip"
              name="zip"
              value={payPalOrderInfo?.CustomerPostalCode}
            />
            <input
              type="hidden"
              id="email"
              name="email"
              value={payPalOrderInfo?.CustomerEmail}
            />
            {payPalOrderInfo && payPalOrderInfo.PhoneA.length > 0 && (
              <input
                type="hidden"
                id="night_phone_a"
                name="night_phone_a"
                value={payPalOrderInfo.PhoneA}
              />
            )}
            {payPalOrderInfo && payPalOrderInfo.PhoneB.length > 0 && (
              <input
                type="hidden"
                id="night_phone_b"
                name="night_phone_b"
                value={payPalOrderInfo.PhoneB}
              />
            )}
            {payPalOrderInfo && payPalOrderInfo.PhoneC.length > 0 && (
              <input
                type="hidden"
                id="night_phone_c"
                name="night_phone_c"
                value={payPalOrderInfo.PhoneC}
              />
            )}
            {payPalOrderInfo && payPalOrderInfo.Appointment1.length > 0 && (
              <input
                type="hidden"
                id="appt_1"
                name="appt_1"
                value={payPalOrderInfo?.Appointment1}
              />
            )}
            {payPalOrderInfo && payPalOrderInfo.Appointment2.length > 0 && (
              <input
                type="hidden"
                id="appt_2"
                name="appt_2"
                value={payPalOrderInfo?.Appointment2}
              />
            )}
            <div>
              {payPalOrderInfo &&
                payPalOrderInfo.Items.length > 0 &&
                payPalOrderInfo.Items.map((item: Item, index: number) => (
                  <div key={index}>
                    <input
                      type="hidden"
                      id={`amount_${index + 1}`}
                      name={`amount_${index + 1}`}
                      value={item.Amount}
                    />
                    <input
                      type="hidden"
                      id={`item_name_${index + 1}`}
                      name={`item_name_${index + 1}`}
                      value={item.Name}
                    />
                    <input
                      type="hidden"
                      id={`item_number_${index + 1}`}
                      name={`item_number_${index + 1}`}
                      value={item.Number ? item.Number : ""}
                    />
                    <input
                      type="hidden"
                      id={`quantity_${index + 1}`}
                      name={`quantity_${index + 1}`}
                      value={item.Quantity}
                    />
                  </div>
                ))}
            </div>
            <input
              type="hidden"
              id="tax_cart"
              name="tax_cart"
              value={payPalOrderInfo?.TaxAmount}
            />
            <input
              type="hidden"
              id="invoice"
              name="invoice"
              value={payPalOrderInfo?.Invoice ? payPalOrderInfo.Invoice : ""}
            />
          </div>
          <div className="col-xs-12">
            <button type="button" id="btnPaypalSubmit" onClick={submitForm}>
              Proceed to Paypal
            </button>
          </div>
          <div className="col-xs-12">
            <span>
              Once payment is complete, click "Close" to close the window.
            </span>
            <br />
            <br />
            <button type="button" id="btnPaypalClose" onClick={close}>
              Close
            </button>
            <br />
            <br />
          </div>
        </div>
      </form>
    </>
  );
};
