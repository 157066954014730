import { DealerLocation } from "types/dealer";
import { MapIt } from "../mapIt/mapIt";
import { useDealerContext } from "../../context/dealer";
import { useSearchContext } from "context/search";
import { useBusyIndicator } from "hooks/useBusyIndicator";
import { BusyIndicator } from "components/busyIndicator/busyIndicator";
interface Props {
  dealerLocation: DealerLocation;
  onClose: () => void;
  search?: (dealerId?: number) => void;
}
export const LocationCard = ({ dealerLocation, search, onClose }: Props) => {
  const {
    switchDealerLocation,
    selectedDealerLocation,
    dealerStyling,
    updateForceDealerSelection,
  } = useDealerContext();
  const { searchParamsData, searchTires } = useSearchContext();
  const { loading, load } = useBusyIndicator();
  const IsSelected: boolean =
    selectedDealerLocation?.DealerId === dealerLocation?.DealerId
      ? true
      : false;
  const activeButtonClasses = IsSelected
    ? "ta-bg-button ta-text-white ta-px-10 sm:ta-w-auto sm:ta-px-4 xl:ta-px-10"
    : "ta-bg-white ta-text-button ta-px-7 sm:ta-px-2 xl:ta-px-7";

  const onClick = async () => {
    // When user selects store location button to be their location
    switchDealerLocation(dealerLocation?.DealerId as number);
    if (dealerStyling?.ForceDealerSelect) {
      // This is only true on initial app load from Finder page
      updateForceDealerSelection();
    }

    if (search) {
      // This is only set from finder page when user performs their first search
      // Call search function passed as prop & close Dialog
      // Extremely important here to pass selected dealerId. Above switchDealerLocation mutates
      // the dealer context state & request for state update is queued. Here we perform search before state is updated
      search(dealerLocation?.DealerId as number);
    } else if (searchParamsData) {
      // This is most likely true when user changes their location while they are
      // in search page or anywhere other than finder page. Show busy spinner, update user search & close dialog
      load();
      // Extremely important here to pass selected dealerId. Above switchDealerLocation mutates
      // the dealer context state & request for state update is queued. Here we perform search before state is updated
      await searchTires(searchParamsData, dealerLocation?.DealerId as number);
      onClose();
    } else {
      onClose();
    }
  };
  return (
    <div className="ta-bg-white ta-shadow-md ta-border ta-border-grey ta-rounded-[3px] ta-mb-2 ta-p-4 sm:ta-px-3.5 sm:ta-grid sm:ta-grid-cols-locationCardGrid sm:ta-gap-[20px] sm:ta-items-center xl:ta-grid-cols-3 xl:ta-pr-10 xl:ta-gap-[88px]">
      <div className="ta-mb-3 lg:ta-mb-0">
        <h3 className="ta-font-bold ta-text-black ta-text-lg ta-mb-3">
          {dealerLocation.Name}
        </h3>
        <address className="ta-mb-4 ta-not-italic">
          <div>{dealerLocation.Address1}</div>
          <div>{dealerLocation.Address2}</div>
          <div>
            {dealerLocation.City}, {dealerLocation.StateAbbr}{" "}
            {dealerLocation.PostalCode}
          </div>
          <div>{dealerLocation.Phone}</div>
        </address>
        <MapIt url={dealerLocation?.MapUrl} text={"Map It"} />
      </div>
      <div className="ta-mb-3 lg:ta-mb-0 sm:ta-self-start sm:ta-text-base">
        <div>Monday: {dealerLocation.MondayHours}</div>
        <div>Tuesday: {dealerLocation.TuesdayHours}</div>
        <div>Wednesday: {dealerLocation.WednesdayHours}</div>
        <div>Thursday: {dealerLocation.ThursdayHours}</div>
        <div>Friday: {dealerLocation.FridayHours}</div>
        <div>Saturday: {dealerLocation.SaturdayHours}</div>
        <div>Sunday: {dealerLocation.SundayHours}</div>
      </div>
      <div>
        <button
          type="button"
          className={`ta-flex ta-items-center ta-justify-center ta-w-full ta-text-lg ta-border ta-border-button ta-rounded-[3px] ta-font-bold ta-pt-1.5 ta-pb-1.5 hover:ta-underline sm:ta-w-auto ${activeButtonClasses}`}
          onClick={onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.998"
            height="20"
            viewBox="0 0 24.998 20"
            className="ta-mr-2"
          >
            <path
              id="Icon_awesome-store-alt"
              data-name="Icon awesome-store-alt"
              d="M12.5,15H5V8.75H2.5v10A1.249,1.249,0,0,0,3.752,20h10A1.249,1.249,0,0,0,15,18.75v-10H12.5ZM24.791,5.555l-3.332-5A1.257,1.257,0,0,0,20.416,0H4.588A1.245,1.245,0,0,0,3.548.555l-3.332,5A1.25,1.25,0,0,0,1.256,7.5h22.5A1.252,1.252,0,0,0,24.791,5.555ZM20,19.375a.627.627,0,0,0,.625.625h1.25a.627.627,0,0,0,.625-.625V8.75H20Z"
              transform="translate(-0.004)"
              fill="currentColor"
            />
          </svg>
          {IsSelected ? `My Store ` : `Select Store `}
        </button>
      </div>
      <BusyIndicator loading={loading} />
    </div>
  );
};
