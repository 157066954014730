import { ChangeEvent, useEffect } from "react";
import { redBorder } from "constants/index";
import useFocus from "hooks/useFocus";
interface Props {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  formatter?: () => string;
  label?: string;
  value: string;
  autocomplete?: string;
  errorText: string;
  type: string;
  id?: string;
  classes?: string;
  placeholder?: string;
  required?: boolean;
  showFocus?: boolean;
}
export const Input = ({
  name,
  pattern,
  onChange,
  label,
  value,
  autocomplete,
  errorText,
  type,
  id,
  classes,
  placeholder,
  required,
  showFocus,
}: Props) => {
  const [inputRef, setFocus] = useFocus();
  useEffect(() => {
    if (showFocus) {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFocus]);
  return (
    <div className={`ta-form-group ${classes}`}>
      {label && (
        <label
          htmlFor={id}
          className="ta-text-sm ta-mb-1 ta-block ta-font-bold ta-text-black"
        >
          {label}
          {required && <span className="ta-text-red">*</span>}
        </label>
      )}
      <input
        id={id}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        pattern={pattern}
        autoComplete={autocomplete}
        placeholder={placeholder}
        required={required}
        ref={inputRef}
        className={`ta-border ta-border-[#8F8F8F] ta-rounded-[3px] ta-h-[33px] ta-p-2 ta-w-full ${
          !value && required ? `${redBorder}` : ""
        }`}
      />
      <div
        className="ta-col-start-2 ta-text-left ta-text-sm"
        style={{ color: "darkred" }}
        aria-live="polite"
        aria-describedby={id}
        role="status"
      >
        {errorText}
      </div>
    </div>
  );
};
